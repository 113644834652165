import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetGamePoster } from "../ScrollableListView";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Translation } from "react-i18next";

export function oranHesapla(ilkW, ilkH, yeniW) {
  var oran = ilkH / ilkW;
  var yeniH = oran * yeniW;
  return yeniH;
}

const TimelessTechGameBox = (props) => {
  const { item, index, provider } = props;
  debugger
  const containerRef = useRef(null);
  const [H, setH] = useState(null);

  const resizeBox = () => {
    if (containerRef && containerRef.current) {
      let newW = containerRef.current?.clientWidth;
      const prgmaticName =
        document.querySelector(".timelessTechName")?.offsetHeight;
      let firstW = 0;
      let firstH = 0;

      switch (item.vendor) {
        case "iconix":
          firstH = 300;
          firstW = 300;
          break;

        default:
          break;
      }

      if (firstW) {
        const newH = oranHesapla(firstW, firstH, newW);
        containerRef.current.style.height = `${newH + prgmaticName}px`;
        setH(`${newH + prgmaticName}px`);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", resizeBox);
    window.addEventListener("resize", resizeBox);
  }, []);

  return (
    <Translation>
      {(t) => (
        <>
          <div
            ref={containerRef}
            className="GameBox h-[143px] md:h-[220px]  bg-primary-600 md:bg-primary-600 group/item cursor-pointer md:border md:border-primary-500"

            onLoad={() => resizeBox()}
            style={{
              height: H ? H : undefined,
            }}
            key={item.id + item.platform + item.subtype + item.title}
          >
            <div className="games-img">
              <AsyncImage
                className="h-full w-full rounded-t-[2.5vw] md:rounded-t-md"
                src={item?.details?.thumbnails?.["300x300"]}
                loader={
                  <div className="skeleton w-full h-full bg-primary-700">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                error={
                  <div className="bg-light-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                style={{
                  objectFit: "fill",
                }}
                alt={`
        ${item.type}
        
        [${item.gameName}]`}
              />
            </div>

            <div className="game-hover group-hover/item:before:opacity-80"></div>
            <div className="game-btns   flex-col px-2">
              <Link
                to={`/timelesstech/${provider}/${item.id}`}
                className="flex flex-row m-1  justify-start md:justify-center items-center"
              >
                <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                {t("BUTTON_PLAY")}
              </Link>
              {item.fun_mode == "1" && (
                <Link
                  to={`/timelesstech-demo/${provider}/${item.id}`}
                  className="flex flex-row m-1  justify-start md:justify-center items-center"
                >
                  <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                  {t("BUTTON_DEMOPLAY")}
                </Link>
              )}
            </div>
            <span className="timelessTechName name text-white after:bg-primary-500 group-hover/item:after:bg-primary-500 group-hover/item:after:opacity-[0.9] group-hover/item:text-white">
              {item.title}
            </span>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelessTechGameBox);
