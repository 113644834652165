import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import { Translation } from "react-i18next";
import { BsPersonPlusFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi2";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { BiTransfer } from "react-icons/bi";

import "swiper/css";
import "swiper/css/pagination";
import MenuItem from "components/Partner/MenuItem";
import Users from "components/SuperAdmin/Users";
import Financial from "components/SuperAdmin/Financial";
import CreatePartner from "components/Admin/CreatePartner";
import InOuts from "components/SuperAdmin/InOuts";

const Admin = (props) => {
  return (
    <Translation>
      {(t) => (
        <div className="p-0 m-0  md:p-3 md:m-3  bg-secondary-400/30 rounded-md">
          <Swiper
            arrow
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {[
              {
                icon: <BsPersonPlusFill className="text-[32px]" />,
                title: t("MENU_ITEM_CREATE_PARTNER"),
                path: "/create",
              },
              {
                icon: <HiUserGroup className="text-[32px]" />,
                title: t("MENU_ITEM_USERS"),
                path: "/users",
              },
              {
                icon: <LiaMoneyBillWaveAltSolid className="text-[32px]" />,
                title: t("MENU_ITEM_FINANCIAL"),
                path: "/financial",
              },
              {
                icon: <BiTransfer className="text-[32px]" />,
                title: t("MENU_ITEM_FINANCIAL_CASH"),
                path: "/in-out",
              },
            ].map((item, key) => (
              <SwiperSlide className="w-36 md:w-32" key={key}>
                <MenuItem
                  icon={item.icon}
                  title={item.title}
                  path={item.path}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="p-4 bg-secondary-700 mt-4 rounded-md">
            <Routes>
              <Route path="*" element={<Navigate to="/create" />} />
              <Route index path="/create" element={<CreatePartner />} />
              <Route path="/users" element={<Users />} />
              <Route path="/financial" element={<Financial />} />
              <Route path="/in-out" element={<InOuts />} />
            </Routes>
          </div>
        </div>
      )}
    </Translation>
  );
};
const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
