import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Notification, useToaster } from "rsuite";
import { Tag, IconButton } from "rsuite";
import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";
import Table from "rc-table";

const columns = [
  {
    title: "UserID",
    dataIndex: "UserID",
    className: "a",
    key: "UserID",
    width: 80,
  },
  {
    id: "UserName",
    title: "UserName",
    dataIndex: "UserName",
    className: "b",
    key: "UserName",
    width: 200,
  },

  {
    title: "Operations",
    dataIndex: "",
    className: "text-right",
    key: "d",
    render() {
      return <a href="#">Operations</a>;
    },
  },
];
const RowTable = (rowData) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const getListData = async () => {
    setLoading(true);
    await PartnerService.READ_GAMERS()
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);

        debugger;
        if (err) {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        } else {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        }
      });
  };
  useEffect(() => {
    getListData();
  }, []);

  return (
    <div className="p-2 bg-secondary-500 rounded-xl">
      <div className="pb-2 px-2 border-b border-secondary-400/30">
        <label className="bold">Detail</label>
      </div>
      <Table
        className="table table-zebra table-sm "
        columns={columns}
        rowClassName={(record, i) => `row-${i}`}
        data={
          data
            ? [
                ...data.map((item) => ({
                  ...item,
                  key: item.UserID,
                })),
              ]
            : []
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RowTable);
