import { authenticationAction } from "_actions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Container from "./Container";
import Navbar from "components/Navbar";
import FocusBlurHandler from "components/FocusBlurHandler";
import Emitter from "_actions/emitter";
import LoginModal from "components/Modals/LoginModal";
import { UserService } from "_services";
import MobileNavbar from "components/MobileNavbar";
import Blas975 from "components/Gamer/Blas975";
import socketServcies from "_services/GlobalSocket";
import { OAuth2Service } from "_services";
import UpdatePassword from "components/Modals/UpdatePassword";
//import { checkTokenExpires } from "_services";
import toast from "react-hot-toast";
import i18n from "i18n";
import { Loader } from "rsuite";
 
function GetDiffTimes(times) {
  let CurrentTime = new Date();
  let endTime = new Date(parseFloat(times));
  let difference = endTime.getTime() - CurrentTime.getTime(); // This will give difference in milliseconds
  let resultInMinutes = Math.round(difference / 60000);
  return resultInMinutes;
}
const Dashboard = (props) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isUpdatePass, setIsUpdatePass] = useState(false);
  const [myPermissions, setMyPermissions] = useState(false);
  const [loading, setLoading] = useState(true);
 
  let ischeckTokenMultiLogin = true;
  const checkTokenMultiLogin = (status) => {
    let refresh_token = localStorage.getItem("refresh_token");
    OAuth2Service.REFRESH_TOKEN(refresh_token)
      .then((data) => {})
      .catch((err) => {
        Emitter.emit("LOGOUT", true);
        if (status && props.loggedIn && ischeckTokenMultiLogin) {
          ischeckTokenMultiLogin = false;
          toast(i18n.t("ALERT_LOGIN_SOMEBODY_OBSERVE"), {
            style: {
              borderRadius: "10px",
              color: "#B8860B",
              background: "#F9EF91",
            },
          });
        }
      });
  };



  const checkCustomTokenExpires = () => {
    let refresh_token = localStorage.getItem("refresh_token");
    let expires_in = localStorage.getItem("expires_in");
    let diff = GetDiffTimes(expires_in);
    console.log("expires_in", expires_in);
    if (diff < 15) {
      OAuth2Service.REFRESH_TOKEN(refresh_token)
        .then((data) => {
          debugger;

          console.log("token updated", data);
        })
        .catch((err) => {
          Emitter.emit("LOGOUT", true);
        });
    } else {
    }
  };

  const GET_AVAIABLE_SETTING = async () => {
    await UserService.GET_AVAIABLE_SETTING()
      .then((res) => {
        props.UpdatePermissions(res);
        setMyPermissions(true);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
       
      })
      .catch((err) => {
        debugger;
      });
  };

  const handleIsLogin = (data) => {
    setIsLogin(data);
  };
  const checkToken = (status) => {
    const { LoginSuccess, LoginFailure } = props;
    UserService.USER_DETAIL()
      .then((res) => {
        LoginSuccess(res);
        GET_AVAIABLE_SETTING();
      })
      .catch((err) => {
        LoginFailure();

        //props.logout();
      });
  };

  useEffect(() => {
    checkToken();
    socketServcies.initializeSocket();
  }, []);

  useEffect(() => {
    Emitter.on("LOGIN_MODAL", (newValue) => checkCustomTokenExpires());
    return () => {
      Emitter.off("LOGIN_MODAL");
    };
  }, []);

  useEffect(() => {
    Emitter.on("UPDATE_PASS", (newValue) => setIsUpdatePass(newValue));
    return () => {
      Emitter.off("UPDATE_PASS");
    };
  }, []);

  useEffect(() => {
    Emitter.on("LOGOUT", (newValue) => props.logout());
    return () => {
      Emitter.off("LOGOUT");
    };
  }, []);

  useEffect(() => {
    Emitter.on("new_balance", (newValue) => props.updateWallet(newValue));
    return () => {
      Emitter.off("new_balance");
    };
  }, []);

  useEffect(() => {
    Emitter.on("CHECK_TOKEN", (status) => checkTokenMultiLogin(status));
    return () => {
      Emitter.off("CHECK_TOKEN");
    };
  }, []);

  return (
    <>
      <>
        <Navbar />
        <MobileNavbar />
        {myPermissions && <Container />}

        {(!myPermissions || loading) && (
          <Loader className="z-[999]" backdrop center />
        )}

        <FocusBlurHandler />
      </>

      <LoginModal isActive={isLogin} />
      <UpdatePassword isActive={isUpdatePass} />
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
    updateWallet: (data) => dispatch(authenticationAction.UpdateWallet(data)),
    UpdatePermissions: (data) =>
      dispatch(authenticationAction.UpdatePermissions(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
