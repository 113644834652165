import React, { useState } from "react";
import { GiPayMoney } from "react-icons/gi";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { Modal, Button } from "rsuite";
import { InputNumber, Nav } from "rsuite";

import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";
import { RootService } from "_services/root.service";
import { Notification, useToaster } from "rsuite";
import Table from "rc-table";
import moment from "moment";
 
export const UserDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [nav, setNav] = React.useState("info");
  const [histories, setHistories] = React.useState([]);
  const [historyPage, setHistoryPage] = React.useState(1);

  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const handleGetHistory = async () => {
    await RootService.GET_USER_HISTORY(props.data.User._id)
      .then((res) => {
        setLoading(false);
        setHistories(res.dynamicValue);
      })
      .catch((err) => {
        setLoading(false);
        debugger;
        if (err) {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        } else {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        }
      });
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div onClick={() => setOpen(true)} className="btn p-0">
            <div className="text-left">
              <span className="block">{props.data.User.UserName}</span>
              <div
                className={
                  `badge badge-outline badge-xs py-1  border-gray-400/30 ` +
                  (props.data.User.UserType.TypeID == 1
                    ? "bg-master-500/40"
                    : props.data.User.UserType.TypeID == 2
                    ? "bg-superadmin-500/40"
                    : props.data.User.UserType.TypeID == 3
                    ? "bg-admin-500/40"
                    : props.data.User.UserType.TypeID == 4
                    ? "bg-partner-500/40"
                    : "bg-gamer-500/40")
                }
              >
                {props.data.User.UserType.Value}
              </div>
              <span className="block italic text-secondary-300">
                {"#" + props.data.User.UserID}
              </span>
            </div>
          </div>
          {open && (
            <Modal
              backdropClassName={"bg-secondary-500"}
              className="tableModal"
              backdrop={false}
              size={
                nav == "info" || props.data.User.UserType.TypeID !== 5
                  ? "xs"
                  : "sm"
              }
              open={open}
              onClose={() => {
                setOpen(false);
                setNav("info");
              }}
            >
              <Modal.Header>
                <Modal.Title className="flex">
                  {props.data.User.UserName}
                  <span className="ml-2 block italic text-secondary-300 text-sm">
                    {"( #" + props.data.User.UserID + " )"}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {props.data.User.UserType.TypeID == 5 && (
                    <Nav appearance="subtle" justified defaultActiveKey={nav}>
                      <Nav.Item onClick={() => setNav("info")} eventKey="info">
                        {t("NAV_TITLE_INFO")}
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setNav("history");
                          handleGetHistory();
                        }}
                        eventKey="history"
                      >
                        {t("NAV_TITLE_HISTORY")}
                      </Nav.Item>
                    </Nav>
                  )}

                  <br />
                  {nav == "info" || props.data.User.UserType.TypeID !== 5 ? (
                    <div className="overflow-x-auto">
                      <table className="table table-zebra bg-secondary-500 rounded-sm">
                        <tbody>
                          <tr>
                            <th>{t("TXT_STATUS")}</th>
                            <td>:</td>
                            <td>
                              <div
                                className={`badge  badge-xs ${
                                  props.data.User.IsActive
                                    ? "badge-success"
                                    : "badge-error"
                                }`}
                              ></div>
                            </td>
                          </tr>
                          <tr>
                            <th>{t("TXT_USER_TYPE")}</th>
                            <td>:</td>
                            <td>{props.data.User.UserType.Value}</td>
                          </tr>

                          <tr>
                            <th>{t("LABEL_FIRSTNAME")}</th>
                            <td>:</td>
                            <td>{props.data.User.FirstName}</td>
                          </tr>
                          <tr>
                            <th>{t("LABEL_LASTNAME")}</th>
                            <td>:</td>
                            <td>{props.data.User.LastName}</td>
                          </tr>
                          <tr>
                            <th>{t("LABEL_EMAIL")}</th>
                            <td>:</td>
                            <td>
                              <a
                                className="btn btn-link btn-sm text-left p-0"
                                href={`mailto:${props.data.User.Email}`}
                              >
                                {props.data.User.Email}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>{t("LABEL_BALANCE")}</th>
                            <td>:</td>
                            <td>
                              {props.data.UserBalance.toLocaleString("tr-TR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>{t("LABEL_CURRENCY")}</th>
                            <td>:</td>
                            <td>{props.data.User.RelCurrency.CurrencyLong}</td>
                          </tr>
                          <tr>
                            <th>{t("LABEL_MANAGER")}</th>
                            <td>:</td>
                            <td>{props.data.User.RelConnectedUser.UserName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Table
                      columns={[
                        {
                          title: "RecordTime",
                          dataIndex: "RecordTime",
                          key: "RecordTime",
                          className: "text-left",
                          width: 100,
                          render: (value) => {
                            return (
                              <div className="flex flex-col mb-1">
                                <span className="mb-0">
                                  {moment(new Date(value)).format("L")}
                                </span>
                                <span className="font-light">
                                  {moment(new Date(value)).format("LT")}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Type",
                          dataIndex: ["RelFinancialType", "Value"],
                          key: "Game",
                          className: "text-center",
                          width: 100,
                        },
                        {
                          title: "Game",
                          dataIndex: "Game",
                          key: "Game",
                          className: "text-center",
                          render: (value) => {
                            if (value) {
                              const _texts = value.split("_");

                              return (
                                <div className="flex flex-col mb-2 bg-secondary-400/30 rounded-md">
                                  <span>{_texts[0]}</span>
                                  <span className="font-light">
                                    {_texts[1]}
                                  </span>
                                </div>
                              );
                            } else {
                              return <span>-</span>;
                            }
                          },
                        },
                        {
                          title: "Amount",
                          dataIndex: "Amount",
                          key: "Amount",
                          className: "text-right",
                          render: (value) => {
                            return (
                              <span
                                className={`  ${
                                  value > 0 ? "text-success" : "text-error"
                                }`}
                              >
                                {value.toLocaleString("tr-TR", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              </span>
                            );
                          },
                        },
                      ]}
                      data={histories}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setNav("info");
                  }}
                  appearance="subtle"
                >
                  {t("BUTTON_CANCEL")}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
