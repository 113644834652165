import React, { useState } from "react";
import { connect } from "react-redux";

const FilterSearch = (props) => {
  const [searchShow, setSearchShow] = useState(true);
  const { FilterSearchValue, setFilterSearchValue } = props;

  return (
    <div className="">
      {true ? (
        <div className="flex flex-row  h-[46px] px-1">
          <div className="flex-1 flex items-center">
            <label className="input input-sm  focus:outline-primary-500 input-bordered flex items-center gap-2 flex-1  bg-secondary-400/10 rounded-md pr-[3px] h-[36px] outline-primary-500">
              <input
              
                value={FilterSearchValue}
                onChange={(e) => {
                  if (setFilterSearchValue)
                    setFilterSearchValue(e.target.value);
                }}
                type="text"
                className="grow h-[36px] "
                placeholder={props.placeholder ? props.placeholder : "Search"}
              />

              <div className="p-1 bg-primary-500 rounded-md">
                <svg
                  onClick={() => {}}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 opacity-70"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </label>
          </div>
          {/*
            <div
            onClick={() => setSearchShow(false)}
            className="btn btn-ghost btn-square"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          */}
        </div>
      ) : (
        <div
          onClick={() => {
            setSearchShow(true);
            if (setFilterSearchValue) setFilterSearchValue("");
          }}
          className="btn btn-ghost btn-square"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSearch);
