import { UserService } from "_services";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { SelectPicker, DateRangePicker, Stack, Accordion } from "rsuite";

import { Loader } from "rsuite";
import FilterSearch from "./FilterSearch";

import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { getCurrency } from "components/Navbar/NavUserBox";
import { BiTransferAlt } from "react-icons/bi";

const Coupons = (props) => {
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const [page, setPage] = React.useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const [FilterSearchValue, setFilterSearchValue] = useState("");
  const [DateValue, setDateValue] = useState([
    new Date(moment().startOf("day").add(1, "seconds")),
    new Date(moment().endOf("day").subtract(1, "seconds")),
  ]);
  const [TypeValue, setTypeValue] = useState(null);
  const [Begin, setBegin] = useState(
    new Date(moment().startOf("day").add(1, "seconds"))
  );
  const [End, setEnd] = useState(
    new Date(moment().endOf("day").subtract(1, "seconds"))
  );

  const [updatedTime, setUpdatedTime] = useState(new Date().toJSON());
  const containerRef = useRef(null);

  const [visibleData, setVisibleData] = useState([]);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    GetCoupons();
  }, []);
  useEffect(() => {
    GetCoupons();
  }, [props.user]);

  const GetCoupons = async (page = 1, search, type, begin, end) => {
    if (!loading) {
      setLoading(true);
      await UserService.READ_TICKETS(page, search, type, begin, end)
        .then((res) => {
          adjustHeight();

          setLoading(false);
          debugger;
          setCoupons(res);
          setUpdatedTime(new Date().toJSON());
          const data = [...coupons];
          const _v = data.slice(0, 36);
          setVisibleData(_v);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  const GetCouponsMore = async () => {
    if (canLoadMore) {
      await UserService.READ_TICKETS(page + 1)
        .then((res) => {
          setLoading(false);
          if (res.length > 0) {
            setPage(page + 1);
            setCoupons([...coupons, ...res]);
          } else {
            setCanLoadMore(false);
          }
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  const adjustHeight = () => {
    debugger;
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;
      const myaccountMenu =
        document.querySelector(".myaccountMenu")?.offsetHeight;
      debugger;
      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1) -
          myaccountMenu;

        debugger;
        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {
      debugger;
    }
  };

  const handleScroll = () => {
    const data = [...coupons];
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight) {
      setVisibleData((prevData) => [
        ...prevData,
        ...data.slice(prevData.length, prevData.length + 20),
      ]);
      setUpdatedTime(new Date().toJSON());

      if (!loading && data.length > 0 && data.length == visibleData.length) {
        GetCouponsMore();
      }
    }

    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };
  useEffect(() => {
    const data = [...coupons];
    const _v = data.slice(0, 36);
    setVisibleData(_v);
    debugger;
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className="h-full p-2">
          <div>
            <h1 className="text-lg px-2 py-2">{t("TITLE_MYBETS")}</h1>
          </div>
          <div
            ref={containerRef}
            onScroll={handleScroll}
            style={{ overflowY: "scroll", paddingBottom: "180px" }}
          >
            <div className="pb-2">
              <Stack spacing={6} className="p-1">
                <Stack.Item grow={1}>
                  <DateRangePicker
                    className={"block"}
                    value={DateValue}
                    ranges={predefinedRanges}
                    showOneCalendar
                    placeholder={t("TITLE_BEGIN_END_DATES")}
                    //  defaultValue={[new Date(),new Date()]}
                    onShortcutClick={(shortcut, event) => {
                      // console.log(shortcut);
                    }}
                    onChange={(value) => {
                      if (value && value.length > 1) {
                        const begin = value[0];
                        const end = value[1];
                        setDateValue(value);
                        // setTypeValue(null);
                        setBegin(begin);
                        setEnd(end);
                        setFilterSearchValue("");

                        GetCoupons(
                          1,
                          null,
                          TypeValue == "all" ? null : TypeValue,
                          begin.toJSON(),
                          end.toJSON()
                        );
                        //getListData(begin.toJSON(), end.toJSON());
                      } else {
                        setBegin(null);
                        setEnd(null);
                        setDateValue(null);
                      }
                    }}
                  />
                </Stack.Item>
                <Stack.Item grow={1}>
                  <SelectPicker
                    className="block"
                    size="md"
                    value={TypeValue}
                    placeholder={t("TITLE_TICKET_STATUS")}
                    data={[
                      {
                        label: t("TICKET_STATUS_ALL"),
                        value: "all",
                      },
                      {
                        label: t("TICKET_STATUS_W"),
                        value: "W",
                      },
                      {
                        label: t("TICKET_STATUS_L"),
                        value: "L",
                      },
                      {
                        label: t("TICKET_STATUS_N"),
                        value: "N",
                      },
                    ]}
                    onChange={(value) => {
                      setTypeValue(value);
                      GetCoupons(
                        1,
                        null,
                        value == "all" ? null : value,
                        new Date(Begin).toJSON(),
                        new Date(End).toJSON()
                      );
                      // setFilterSearchValue(null);
                      // setDateValue(null);
                    }}
                  />
                </Stack.Item>
              </Stack>
              <FilterSearch
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={(value) => {
                  setFilterSearchValue(value);
                  if (value.trim().length > 1) {
                    GetCoupons(
                      1,
                      value,
                      TypeValue == "all" ? null : TypeValue,
                      Begin,
                      End
                    );
                    //setDateValue(null);
                    //setTypeValue(null);
                  }
                }}
                placeholder={t("PLACEHOLDER_TICKET")}
              />
            </div>
            {!([...coupons].length > 0) && (
              <div className="flex flex-col justify-center items-center p-2">
                <span className="italic text-secondary-400">
                  {t("PLACEHOLDER_NO_DATA_TICKET")}
                </span>
              </div>
            )}

            {coupons.length > 0 && (
              <Accordion className="accordionMain">
                {coupons.map((item, key) => {
                  debugger;
                  return (
                    <Accordion.Panel
                      key={key + "acc"}
                      className="border accordionPanel relative border-[#262a30]  bg-[#32363e] rounded-md mb-2"
                      classPrefix={
                        "   border-1 border-secondary-300/20  rounded-md "
                      }
                      header={
                        <div
                          key={key}
                          className={
                            "w-full p-2 mr-6 " +
                            " text-[2.3133333333vw] md:text-sm font-medium "
                          }
                        >
                          <div className="leading-3 flex justify-end pt-1 tracking-tighter absolute w-full right-[30px]">
                            <span
                              className={
                                "text-white py-[2px] px-1 rounded-sm mr-2 mt-[-2px] uppercase " +
                                (item.TicketData.eventstype == "Live"
                                  ? " bg-red-600 "
                                  : "border border-secondary-300 ")
                              }
                            >
                              {item.TicketData.eventstype}
                            </span>
                            {item.TicketData?.ticketbody.length} /{" "}
                            {
                              item.TicketData?.ticketbody.filter(
                                (item) => item.outcome.status !== "-"
                              ).length
                            }
                          </div>
                          <div className="">
                            <div>{""}</div>
                            <div className="flex flex-row items-center pb-2">
                              <p className="text-white/30 text-xs text-left m-0 mr-[2.1333333333vw] md:mr-2 font-bold tracking-tighter	">
                                {moment(
                                  new Date(item.TicketData.addedTime)
                                ).format("DD/MM - HH:mm:ss")}
                              </p>
                              <p className="m-0 text-[3.4237333333vw] md:text-[20px] font-bold leading-5 tracking-tighter	">
                                {"#" + item.TicketID}
                              </p>
                              <p className="text-[2.6vw] md:text-sm"></p>
                            </div>
                          </div>
                          <div className="">
                            <table class="table-auto w-full leading-[22px]	text-start text-white font-medium tableHeader">
                              <tbody>
                                <tr>
                                  <td className="leading-3  w-[65px]">
                                    {t("COUPON_AMOUNT")}
                                  </td>
                                  <td className={"w-[16px] text-center"}>:</td>
                                  <td>
                                    {item.TicketData?.ticketbet.toLocaleString(
                                      "tr-TR",
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                  </td>

                                  <td className="leading-3  w-[65px]">
                                    {t("COUPON_TOTAL_RATE")}
                                  </td>
                                  <td className={"w-[16px] text-center"}>:</td>
                                  <td>
                                    {item.ticketodds.toLocaleString("tr-TR", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="leading-3  w-[65px]">
                                    {t("COUPON_NOTE")}
                                  </td>
                                  <td className={"w-[16px] text-center"}>:</td>
                                  <td colSpan={4}>-</td>
                                </tr>
                                <tr>
                                  <td className="leading-3  w-[65px]">
                                    {t("COUPON_WINNINGS")}
                                  </td>
                                  <td className={"w-[16px] text-center"}>:</td>
                                  <td>
                                    {item.TicketData?.maxwin.toLocaleString(
                                      "tr-TR",
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    ) +
                                      " " +
                                      getCurrency(
                                        item.TicketData?.ticketcurrency
                                      )}
                                  </td>

                                  <td className="leading-3  w-[65px]">
                                    {t("COUPON_BONUS")}
                                  </td>
                                  <td className={"w-[16px] text-center"}>:</td>
                                  <td>-</td>
                                </tr>
                                {item.TicketData.cashout ? (
                                  <tr>
                                    <td className="leading-3  w-[65px]">
                                      {t("COUPON_TICKET_WIN")}
                                    </td>
                                    <td className={"w-[16px] text-center"}>
                                      :
                                    </td>
                                    <td colSpan={4}>
                                      {`${
                                        item.TicketData.ticketwin.toLocaleString(
                                          "tr-TR",
                                          {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          }
                                        ) +
                                        " " +
                                        getCurrency(
                                          item.TicketData?.ticketcurrency
                                        )
                                      } (${t("COUPON_CASHOUT")})`}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="leading-3 flex justify-end py-0 mr-[-16px]">
                            <div>
                              {item.TicketData.cashout ? (
                                <div className="flex flex-row text-center justify-center items-center">
                                  <BiTransferAlt className="w-6 h-6 text-cyan-500	" />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="">
                              {item.TicketStatus == "L" && (
                                <div className="ml-1 relative overflow-hidden  ticketStatusIcons">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6 text-red-500 absolute"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              )}
                              {item.TicketStatus == "W" && (
                                <div className="ml-1 relative overflow-hidden  ticketStatusIcons">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6 text-green-500 ml-1 absolute"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <div
                        style={{ visibility: "visible" }}
                        className="collapse-content text-white  p-2 rounded-md  bg-[#2e3038]"
                      >
                        <div className="flex flex-col ">
                          {item.TicketData?.ticketbody.map((i, k) => (
                            <div
                              className="p-1 pt-4 pb-2 flex flex-col bg-[#393b42] rounded-md px-2 my-1"
                              key={k + "ticketbody"}
                            >
                              <div className="flex flex-row">
                                <div
                                  style={{ flex: "1.8" }}
                                  className="p-2 flex-1 text-sm w-[40%] flex flex-col justify-center items-center text-center couponteam relative"
                                >
                                  <span
                                    className={
                                      "dashboard-sport-icon " + i.event.sport
                                    }
                                  ></span>
                                  {i.event.name.split(" v ").length > 1 &&
                                  i.event.name.split(" v ")[1].trim().length >
                                    1 ? (
                                    <>
                                      <span className="couponV couponVfirst">
                                        {i.event.name.split(" v ")[0]}
                                      </span>

                                      <span className="couponteamdivider"></span>

                                      <span className="couponV">
                                        {i.event.name.split(" v ")[1]}
                                      </span>
                                    </>
                                  ) : i.event.name.split(" — ").length > 1 &&
                                    i.event.name.split(" — ")[1].trim().length >
                                      1 ? (
                                    <>
                                      <span className="couponV couponVfirst">
                                        {i.event.name.split(" — ")[0]}
                                      </span>

                                      <span className="couponteamdivider"></span>

                                      <span className="couponV">
                                        {i.event.name.split(" — ")[1]}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="couponVV  ">
                                        {i.event.name.split(" v ")}
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{ flex: "2" }}
                                  className="flex-2 w-[60%] flex"
                                >
                                  <table className="table table-xs md:table:sm couponTable">
                                    <tbody>
                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("TABLE_DATE")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        <td>
                                          {moment(
                                            new Date(i.event.date)
                                          ).format("DD/MM HH:mm:ss")}
                                        </td>
                                      </tr>
                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("COUPON_TYPE")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        <td>
                                          {i.outcome.market
                                            ? i.outcome.market
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("COUPON_SELECTION")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        <td className="text-orange-500 font-bold ">
                                          {i.outcome.name
                                            ? i.outcome.name
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("COUPON_RATE")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        <td>
                                          {" "}
                                          {i.outcome.odd ? i.outcome.odd : "-"}
                                        </td>
                                      </tr>
                                      {i.outcome.cashout ? (
                                        <tr
                                          className={"border-secondary-300/0"}
                                        >
                                          <td className="leading-3  w-[60px] text-wrap">
                                            {t("COUPON_CASHOUT_RATE")}
                                          </td>
                                          <td className="leading-3  w-[20px] text-center">
                                            :
                                          </td>
                                          <td className="text-cyan-500	font-bold">
                                            {i.outcome.cashoutodd
                                              ? i.outcome.cashoutodd
                                              : "-"}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}

                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("COUPON_TIME")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        <td>{i.outcome.time + "'"}</td>
                                      </tr>

                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("COUPON_SCORE")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        <td>
                                          {/*
i.event.result
                                            ? JSON.parse(i.event.result).home +
                                              "-" +
                                              JSON.parse(i.event.result).away
                                            :
*/}
                                          {i.outcome.score ? (
                                            <span className="mr-2">
                                              {i.outcome.score}
                                            </span>
                                          ) : i.event.result &&
                                            i.event.result ? (
                                            ""
                                          ) : (
                                            "-"
                                          )}
                                          <span className="font-bold">
                                          
                                            {`${
                                              i.event.result
                                                ? " (FT: " +
                                                  JSON.parse(i.event.result)
                                                    .home +
                                                  ":" +
                                                  JSON.parse(i.event.result)
                                                    .away +
                                                  ")"
                                                : ""
                                            }`}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr className={"border-secondary-300/0"}>
                                        <td className="leading-3  w-[60px] text-wrap">
                                          {t("COUPON_STATUS")}
                                        </td>
                                        <td className="leading-3  w-[20px] text-center">
                                          :
                                        </td>
                                        {i.outcome.status == "W" ? (
                                          <td className="leading-3  uppercase font-bold text-green-500 couponitem">
                                            {t("BET_STATUS_W")}
                                          </td>
                                        ) : i.outcome.status == "L" ? (
                                          <td className="leading-3  uppercase font-bold text-red-500 couponitem">
                                            {t("BET_STATUS_L")}
                                          </td>
                                        ) : i.outcome.status == "HW" ? (
                                          <td className="leading-3  uppercase font-bold text-teal-500">
                                            {t("BET_STATUS_HW")}
                                          </td>
                                        ) : i.outcome.status == "HL" ? (
                                          <td className="leading-3  uppercase font-bold text-orange-500">
                                            {t("BET_STATUS_HL")}
                                          </td>
                                        ) : i.outcome.status == "R" ? (
                                          <td className="leading-3  uppercase font-bold text-yellow-500">
                                            {t("BET_STATUS_R")}
                                          </td>
                                        ) : (
                                          <td className="leading-3  uppercase font-bold text-yellow-500 ">
                                            {t("BET_STATUS_P")}
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Accordion.Panel>
                  );
                })}
              </Accordion>
            )}
          </div>

          {loading && <Loader backdrop center />}
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
  };
};
const mapDispatchToProps = {};

/*
.filter((x) =>
                  x.TicketID.toLocaleLowerCase().includes(
                    FilterSearchValue?.toLocaleLowerCase()
                  )
                )
*/

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);

export const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(new Date("01/01/2020").getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: "default",
  },
];
