import React from "react";
import { connect } from "react-redux";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import DragDealer from "./DragDealer";
import { AiOutlineUser } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";
import { HiOutlineTicket } from "react-icons/hi";
import { Link } from "react-router-dom";

const AccountMenu = (props) => {
  const dragState = React.useRef(new DragDealer());
   const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragState.current.dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const { acitveMenu, setActiveMenu } = props;

  const handleItemClick = (itemId) => () => {
    if (dragState.current.dragging) {
      return false;
    }
    setActiveMenu(acitveMenu !== itemId ? itemId : "");
  };

  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }
  return (
    <div onMouseLeave={dragState.current.dragStop}>
      <ScrollMenu
        // LeftArrow={LeftArrow}
        // RightArrow={RightArrow}
        onWheel={onWheel}
        onMouseDown={() => dragState.current.dragStart}
        onMouseUp={() => dragState.current.dragStop}
        onMouseMove={handleDrag}
      >
        {[
          ...new Array(
            { id: "profile" },
            { id: "coupons" },
            { id: "histories" },
            { id: "transfers" }
          ),
        ]
          .filter((x) => {
            if (x.id == "coupons") {
              if (props.permissions?.SPORTS_ACCESS?.Value) return true;
              else return false;
            } else return true;
          })
          .map(({ id }) => (
            <Card
              title={id}
              itemId={id} // NOTE: itemId is required for track items
              key={id}
              onClick={handleItemClick(id)}
              selected={id === acitveMenu}
            />
          ))}
      </ScrollMenu>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions, permissionsAll } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
    permissionsAll,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);

const Card = ({ itemId, selected, onClick, title }) => {
  const visibility = React.useContext(VisibilityContext);
  const isVisible = visibility.useIsVisible(itemId, true);

  return (
    <Link
      to={"/my-account/" + itemId}
      onClick={() => onClick()}
      style={{
        userSelect: "none",
      }}
      tabIndex={0}
      className={
        "btn btn-lg btn-square text-[28px] p-0 text-white flex justify-center items-center mx-2 " +
        (selected ? "btn-primary" : "btn-secondary")
      }
    >
      {itemId == "profile" ? (
        <AiOutlineUser />
      ) : itemId == "coupons" ? (
        <HiOutlineTicket />
      ) : itemId == "histories" ? (
        <BsClockHistory />
      ) : itemId == "transfers" ? (
        <svg
          width="26"
          height="26"
          viewBox="0 0 55 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.2 19.3158H22.4M52.9941 25.5C52.9941 25.3085 53 24.1823 52.9941 24.1014C52.9039 22.8626 51.8086 21.8761 50.4334 21.7947C50.3437 21.7895 50.2376 21.7895 50.0249 21.7895H43.3885C38.8386 21.7895 35.15 25.1119 35.15 29.2105C35.15 33.3092 38.8386 36.6316 43.3885 36.6316H50.0249C50.2376 36.6316 50.3437 36.6316 50.4334 36.6264C51.8086 36.545 52.9039 35.5585 52.9941 34.3197C53 34.2388 53 33.1125 53 32.9211M22.4 49H30.05C39.6666 49 44.4751 49 47.4624 46.1018C49.5233 44.1028 50.1626 41.2631 50.3608 36.6316M50.3608 21.7895C50.1626 17.158 49.5233 14.3183 47.4624 12.3192C44.4751 9.42106 39.6666 9.42106 30.05 9.42106H22.4C12.7833 9.42106 7.97503 9.42106 4.9875 12.3192C2 15.2173 2 19.8818 2 29.2105C2 38.5393 2 43.2039 4.9875 46.1018C6.65311 47.7176 8.88472 48.4325 12.2 48.7489M12.2 9.42106L21.7255 3.29406C24.4079 1.56865 28.0421 1.56865 30.7245 3.29406L40.25 9.42106M45.35 29.2105C45.35 30.5767 44.2083 31.6842 42.8 31.6842C41.3917 31.6842 40.25 30.5767 40.25 29.2105C40.25 27.8444 41.3917 26.7368 42.8 26.7368C44.2083 26.7368 45.35 27.8444 45.35 29.2105Z"
            stroke="#fff"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      ) : (
        ""
      )}
    </Link>
  );
};

const LeftArrow = () => {
  const visibility = React.useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      Left
    </Arrow>
  );
};

const RightArrow = () => {
  const visibility = React.useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => visibility.scrollNext()}>
      Right
    </Arrow>
  );
};

const Arrow = ({ children, disabled, onClick }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
      }}
    >
      {children}
    </button>
  );
};
