import { UserEndPoints, OAuth2_MakeRequestAndGetResponse } from "./index";
import { HTTPOperationType } from "./httpOperations";
import { AdminEndPoints, GetBaseURL } from "./_serviceSettings";
import Emitter from "_actions/emitter";

export const AdminService = {
  CREATE_PARTNER,
  READ_PARTNERS,
};

async function CREATE_PARTNER(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + AdminEndPoints.CREATE_PARTNER,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
async function READ_PARTNERS(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + AdminEndPoints.READ_PARTNERS,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
