import { UpdateLang } from "_actions";
import { logout } from "_actions";
import i18n from "i18n";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

export const LangBox = (props) => {
  const { currentLang } = props;

  return (
    <Translation>
      {(t) => (
        <div className="border  p-1 border-secondary-800 bg-secondary-800 rounded-3xl">
          <div className="dropdown dropdown-bottom text-white w-full">
            <div
              tabIndex={0}
              role="button"
              style={{
                background: "transparent",
              }}
              className="btn btn-ghost  btn-block btn-xs flex flex-col items-start "
            >
              <div className="fade-in flex items-center">
                <div className="rounded-xl relative w-[18px] h-[18px] overflow-hidden">
                  <img
                    style={{
                      position: "absolute",
                      width: " 100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={require(`../assets/img/flags/${currentLang}.svg`)}
                  />
                </div>
                <span className="ml-2 ">{t(currentLang)}</span>{" "}
              </div>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] p-2 shadow-2xl bg-base-300 rounded-box w-52"
            >
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "en" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("en");
                    i18n.changeLanguage("en");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("en")}
                </div>
              </li>
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "de" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("de");
                    i18n.changeLanguage("de");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("de")}
                </div>
              </li>
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "nl" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("nl");
                    i18n.changeLanguage("nl");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("nl")}
                </div>
              </li>
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "it" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("it");
                    i18n.changeLanguage("it");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("it")}
                </div>
              </li>
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "fr" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("fr");
                    i18n.changeLanguage("fr");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("fr")}
                </div>
              </li>
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "ar" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("ar");
                    i18n.changeLanguage("ar");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("ar")}
                </div>
              </li>
             
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "kh" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("kh");
                    i18n.changeLanguage("kh");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("kh")}
                </div>
              </li>
              <li>
                <div
                  className={
                    "fade-in theme-controller btn btn-sm btn-block btn-ghost justify-start " +
                    (currentLang == "tr" ? "btn-active" : "")
                  }
                  onClick={() => {
                    props.UpdateLang("tr");
                    i18n.changeLanguage("tr");
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {t("tr")}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    UpdateLang: (data) => dispatch(UpdateLang(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LangBox);
