import { combineReducers } from "redux";

//import { SocketReducer } from './socket.reducer';

import { authentication } from "./authentication.reducer";
import { game } from "./game.reducer";

const rootReducer = combineReducers({
  authentication,
  game
});

export default rootReducer;
