import React, { Component } from "react";
import {
  Alert,
  Modal,
  Form,
  ButtonToolbar,
  IconButton,
  Notification,
  useToaster,
  Schema,
  Icon,
} from "rsuite";

import { Translation } from "react-i18next";
import i18n from "i18n";

import { OAuth2Service, UserService } from "_services";
import { authenticationAction } from "_actions";

import { connect } from "react-redux";
import Emitter from "_actions/emitter";

export const LoginModal = (props) => {
  const toaster = useToaster();
  const model = Schema.Model({
    username: Schema.Types.StringType().isRequired(i18n.t("MSG_NULL_USERNAME")),
    password: Schema.Types.StringType().isRequired(i18n.t("MSG_NULL_PASSWORD")),
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);

  const checkToken = async () => {
    const { LoginSuccess, LoginFailure } = props;
    await UserService.USER_DETAIL()
      .then((res) => {
        LoginSuccess(res);
        Emitter.emit("LOGIN_MODAL", false);
      })
      .catch((err) => {
        LoginFailure();
      });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!formRef.current.check()) {
      console.error("Form Error");
      setLoading(false);
      return;
    } else {
      await OAuth2Service.LOGIN(formValue.username, formValue.password, false)
        .then((res) => {
          toaster.push(message(i18n.t("MESSAGE_LOGIN_SUCCUESS"), "success"), {
            duration: 2000,
          });
          checkToken();
          setLoading(false);
        })
        .catch((err) => {
          debugger;
          if (err) {
            toaster.push(message(i18n.t("MESSAGE_LOGIN_FAILD"), "error"), {
              duration: 2000,
            });
          } else {
            toaster.push(
              message(i18n.t("ERROR_RESPONSE_ERROR_UNDEFINED"), "error"),
              { duration: 2000 }
            );
          }
          setLoading(false);
        });
    }
  };

  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const handleClose = () => {
    Emitter.emit("LOGIN_MODAL", false);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
           // onClose={handleClose}
            className="custom-modal"
            backdrop={false}
            open={props.isActive}
            size="xs"
          >
            <Modal.Body
              className="bg-secondary-500"
              style={{
                paddingTop: "1em",
                marginTop: 0,
                paddingBottom: 0,
              }}
            >
              <div className="flex justify-center flex-col items-center  shadow-xl ">
                <div>
                  <div className="avatar ">
                    <div className="w-36 ">
                      <img
                        style={{ objectFit: "contain" }}
                        src={require("../../assets/img/textlogo2.png")}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full sm:max-w-md mt-2 px-6 py-4 secondary-950 overflow-hidden sm:rounded-lg">
                  <Form
                    ref={formRef}
                    fluid
                    model={model}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                  >
                    <Form.Group>
                      <Form.ControlLabel className="label-text">
                        {t("TXT_USERNAME")}
                      </Form.ControlLabel>
                      <Form.Control
                        onKeyDown={_handleKeyDown}
                        className="input input-bordered w-full max-w outline-primary-500"
                        name="username"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.ControlLabel className="label-text">
                        {t("TXT_PASSWORD")}
                      </Form.ControlLabel>
                      <Form.Control
                        onKeyDown={_handleKeyDown}
                        className="input input-bordered w-full max-w outline-primary-500"
                        name="password"
                        type="password"
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group>
                      <ButtonToolbar>
                        <div
                          className="btn btn-primary btn-md btn-block text-white"
                          onClick={handleSubmit}
                        >
                          {t("BUTTON_SIGNIN")}
                        </div>
                        {/*
                     <Button appearance="link">Forgot password?</Button>
                    */}
                      </ButtonToolbar>
                    </Form.Group>
                  </Form>
                </div>
                <span className="my-2 font-light text-secondary-900/20">{t("VERSION")}</span>

              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
