import React from "react";
import UserBox from "./UserBox";
import LangBox from "../LangBox";
import LogoBox from "./LogoBox";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import DigitalWatch from "components/DigitalWatch";
import { logout } from "_actions";
import { UpdateLang } from "_actions";
import { connect } from "react-redux";

export const Navbar = (props) => {
  const { currentLang } = props;

  return (
    <Translation>
      {(t) => (
        <div className="base-nav mobile-hide bg-secondary-500  drop-shadow-2xl  text-base-content sticky top-0 z-30 flex w-full justify-center bg-opacity-90 backdrop-blur transition-shadow duration-100 [transform:translate3d(0,0,0)] shadow-xl bg-contain bgpattern">
          <nav className="navbar w-full">
            <div className="flex flex-1 md:gap-1 lg:gap-2">
              <LogoBox />
            </div>
            <div className="flex-0">
              <LangBox />
              <div className="mr-2"></div>

              <UserBox />
              <div className="mr-2"></div>
              <DigitalWatch />
            </div>
          </nav>
          <div className="flex justify-center items-center bg-gradient-to-r from-primary-600 via-primary-500 to-primary-600 h-12 w-full ">
            {props.permissions?.SPORTS_ACCESS?.Value && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                className=" btn btn-primary btn-outline  border-0"
                to={"/sports"}
              >
                {t("TITLE_SPORTS")}
              </Link>
            )}

            {props.permissions?.CASINO_ACCESS?.Value && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                className=" btn btn-primary btn-outline  border-0"
                to={"/casino"}
              >
                {t("TITLE_CASINO")}
              </Link>
            )}

            {props.permissions?.LIVECASINO_ACCESS?.Value && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                className=" btn btn-primary btn-outline  border-0"
                to={"/live-casino"}
              >
                {t("TITLE_LIVECASINO")}
              </Link>
            )}
            {props.permissions?.GAMES_ACCESS?.Value && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                className=" btn btn-primary btn-outline  border-0"
                to={"/games"}
              >
                {t("TITLE_GAMES")}
              </Link>
            )}
            {props.permissions?.VIRTUAL_ACCESS?.Value && (
              <Link
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
                className=" btn btn-primary btn-outline  border-0"
                to={"/virtual"}
              >
                {t("TITLE_VIRTUAL")}
              </Link>
            )}
            {props.permissions?.TVGAMES_ACCESS?.Value && (
              <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              className=" btn btn-primary btn-outline  border-0"
                to={"/tv-games"}
              >
                {t("TITLE_TVGAMES")}
              </Link>
            )}
            <Link
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
              className=" btn  btn-primary btn-outline  border-0"
              to={"/my-account/profile/"}
            >
              {t("TITLE_MYACCOUNT")}
            </Link>

            <div
              style={{
                textDecoration: "none",
                background: "transparent",
              }}
              className=" btn  btn-ghost text-red-600 btn-outline  border-0"
              onClick={() => {
                props.logout();
              }}
            >
              {t("LOGOUT")}
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    UpdateLang: (data) => dispatch(UpdateLang(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
