import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import i18n from "i18n";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "_services";
import Emitter from "_actions/emitter";
import { useNavigate } from "react-router-dom";

const Pragmatic = (props) => {
  const { user, loggedIn, sports, games, UpdateGames, demo } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const [uKey, setUKey] = useState(uuidv4());
  const navigate = useNavigate();

  useEffect(() => {
    Emitter.on("ACTIVE_TAB", (newValue) => {
      const _uKey = uKey;
      if (
        uKey &&
        newValue &&
        newValue.uniqToken &&
        newValue.uniqToken != _uKey
      ) {
        navigate("/");
      }
      debugger;
    });
    return () => {
      Emitter.off("ACTIVE_TAB");
    };
  }, []);

  const setActiveGame = () => {
    const _k = uKey;
    debugger;
    UserService.UPDATE_ACTIVEGAME("Pragmatic" + "-" + params.GameId, _k)
      .then((res) => {
        setLoading(false);
        debugger;
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function GetParams() {
    let params = useParams();
    return params;
  }
  let params = GetParams();

  const handleGetData = () => {
    if (params && params.GameId) {
      if (!demo) {
        if (props.games["pragmatic-casino"]) {
          const _data = props.games["pragmatic-casino"].find(
            (x) => x.gameID == params.GameId
          );
          if (_data) {
            getGameDetail(params.GameId, _data);
          }
        }
      } else {
        const token =
          "28c40f6742f4615a678ba56be2ea379684b67d2a41331c3870f343e282cd341f55c636097778641bb3a488e371de337b8967";
        const symbol = params.GameId; //vs20sugarrush
        const language = params.currentLang;
        const currency = user?.Currency == "IQD" ? "TND" : user?.Currency;
        debugger;
        // const _url = `https://demogamesfree.pragmaticplay.net/gs2c/playGame.do?key=token%3D28c40f6742f4615a678ba56be2ea379684b67d2a41331c3870f343e282cd341f55c636097778641bb3a488e371de337b8967%60%7C%60symbol%3D${symbol}%60%7C%60language%3DEN%60%7C%60currency%3DEUR%60%7C%60lobbyUrl%3Djs%253A%252F%252Fwindow.history.back%2528%2529%253B&ppkv=2&stylename=maxmagic_fortuna2000&isGameUrlApiCalled=true`;
        const _url = `https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=${symbol}&lang=${language}&cur=${currency}&lobbyUrl=js%3A%2F%2Fwindow.history.back%28%29%3B&stylename=maxmagic_fortuna2000&isGameUrlApiCalled=true`;
        // console.log(_url);
        setData(_url);
      }
    }
  };

  useEffect(() => {
    debugger;
    if (params && params.GameId) {
      setActiveGame();
    }
  }, []);

  useEffect(() => {
    if (games) {
      const aa = games;
      debugger;
      handleGetData();
    }
  }, [games]);

  let startget = false;

  const getGameDetail = (GameId, data) => {
    if (!startget) {
      startget = true;
      const isMobile = width <= 768;
      let PlayCategory = "SLOT";

      const pragmaticGAMES = ["1302", "1320"];
      const livegames = props.games["pragmatic-casino"].filter(
        (x) => x.gameTypeID == "lg"
      );
      const virtualgames = props.games["pragmatic-casino"].filter(
        (x) => x.gameTypeID == "rgs-vsb"
      );

      if (pragmaticGAMES.find((x) => x == GameId)) {
        PlayCategory = "GAMES";
      } else if (livegames.find((x) => x.gameID == GameId)) {
        PlayCategory = "LIVE_CASINO";
      } else if (virtualgames.find((x) => x.gameID == GameId)) {
        PlayCategory = "VIRTUAL";
      } else {
      }
      debugger;
      setLoading(true);
      GameService.GET_GAME_DETAIL(
        GameId,
        isMobile ? "MOBILE" : "WEB",
        i18n.t("LANG"),
        PlayCategory
      )
        .then((res) => {
          setLoading(false);
          setData(res.gameURL);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };
  /*
  const adjustHeight = () => {
    const windowHeight = window.innerHeight;

    try {
      document.querySelector(".gameiframe").style.height = `${windowHeight}px`;
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);
*/
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_CASINO")}</title>
            <style type="text/css">
              {`
      .base-nav {
        display: none !important;
        
      }
      .base-nav1 {
        display: none !important;
        
      }
      body, html , #root{
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
    .appdiv{
      height: 100% !important;
    }
      iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
      
      
    `}
            </style>
          </Helmet>
          {data && <iframe src={data} allowfullscreen></iframe>}
        </>
      )}
    </Translation>
  );
};
// height: "calc(100vh - 72px)",
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pragmatic);
