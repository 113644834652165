import React from "react";
import { connect } from "react-redux";


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginLayout from "./LoginLayout";
import { authenticationAction } from "_actions";
export const Login = (props) => {
  return (
    <div className="w-screen min-h-screen ">
      <LoginLayout />
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
