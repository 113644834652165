import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView from "../ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ServiceURLs } from "_services";
import i18n from "i18n";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "_services";
import Emitter from "_actions/emitter";
import { useNavigate } from 'react-router-dom';

const TimelessTech = (props) => {
  const { user, loggedIn, sports, games, UpdateGames, demo } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [uKey, setUKey] = useState(uuidv4());
  const navigate = useNavigate();

  useEffect(() => {
    Emitter.on("ACTIVE_TAB", (newValue) => {
      const _uKey = uKey;
      if (
        uKey &&
        newValue &&
        newValue.uniqToken &&
        newValue.uniqToken != _uKey
      ) { navigate('/');

      }
      debugger;
    });
    return () => {
      Emitter.off("ACTIVE_TAB");
    };
  }, []);

  const setActiveGame =  () => {
    const _k = uKey;
    debugger;
    UserService.UPDATE_ACTIVEGAME(
      params.ProviderId + "-" + params.GameId,
      _k
    )
      .then((res) => {
        setLoading(false);
        debugger;
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };


  function GetParams() {
    let params = useParams();
    return params;
  }
  let params = GetParams();

  useEffect(() => {
    if (
      !loading &&
      params &&
      params.ProviderId &&
      params.GameId &&
      props.games
    ) {
      debugger
      const g = props.games;
      if (g && g.hasOwnProperty(params.ProviderId)) {
        const _data = g[params.ProviderId].find((x) => x.id == params.GameId);
        if (!demo) {
          if (_data) {
            getGameDetail(_data.id, i18n.t("LANG"));
          } else {
            alert("Oyun bulunamadı !");
          }
        } else {
          if (_data) {
            getGameDemoDetail(_data.id, i18n.t("LANG"));
          } else {
            alert("Oyun bulunamadı !");
          }
        }
      }
    }

    if (params && params.ProviderId && params.GameId) {
      setActiveGame();
    }
  }, []);

  useEffect(() => {
    if (
      !loading &&
      params &&
      params.ProviderId &&
      params.GameId &&
      props.games
    ) {
      const g = props.games;
      if (g && g.hasOwnProperty(params.ProviderId)) {
        const _data = g[params.ProviderId].find((x) => x.id == params.GameId);
        if (!demo) {
          if (_data) {
            getGameDetail(_data.id, i18n.t("LANG"));
          } else {
            alert("Oyun bulunamadı !");
          }
        } else {
          if (_data) {
            getGameDemoDetail(_data.id, i18n.t("LANG"));
          } else {
            alert("Oyun bulunamadı !");
          }
        }
      }
    }
  }, [games]);

  let startget = false;

  const getGameDetail = (id) => {
    if (!startget) {
      startget = true;
      setLoading(true);
      GameService.GET_TIMELESSTECH_GAME(
        id,
        props.currentLang,
        params.ProviderId,
        isMobile && isTablet,
        "real"
      )
        .then((res) => {
          setLoading(false);
          debugger;
          try {
            setData(res.dynamicValue.GameUrl);
            //  openInNewTab(res.url);
            startget = false;
          } catch (error) {
            toast.error(i18n.t("ERROR"));
          }
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  const getGameDemoDetail = (id) => {
    debugger
    if (!startget) {
      startget = true;
      setLoading(true);
      GameService.GET_TIMELESSTECH_GAME(
        id,
        props.currentLang,
        params.ProviderId,
        isMobile && isTablet,
        "fun"
      )
        .then((res) => {
          setLoading(false);
          debugger;
          setData(res.dynamicValue.GameUrl);
          // openInNewTab(res.url);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };
  /*
  const adjustHeight = () => {
    const windowHeight = window.innerHeight;
     try {
      document.querySelector(".gameiframe").style.height = `${windowHeight}px`;
    } catch (error) {
      
    }
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);
*/

  function openInNewTab(url) {
    var newWindow = window.open(url);
    newWindow.focus();
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_CASINO")}</title>
            <style type="text/css">
              {`
      .base-nav {
        display: none !important;
        
      }
      .base-nav1 {
        display: none !important;
        
      }
      body, html , #root{
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
    .appdiv{
      height: 100% !important;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
      
      
    `}
            </style>
          </Helmet>
          {data && <iframe src={data} allowfullscreen />}
        </>
      )}
    </Translation>
  );
};
// height: "calc(100vh - 72px)",
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimelessTech);
