import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter ,Routes,
  Route,
  Navigate,} from "react-router-dom";
import { CustomProvider } from "rsuite";
import { IntlProvider } from "react-intl";

import { Provider } from "react-redux";
import { store } from "./_helpers";

import App from "App";

import enUS from "rsuite/locales/en_US";

import "./i18n";
import "./assets/css/rsTheme.css";
import "./index.css";
//import "assets/css/rsTheme.css";

import format from "date-fns/format";

import en from "date-fns/locale/tr";
import de from "date-fns/locale/de";

import { SnackbarProvider, closeSnackbar, useSnackbar } from "notistack";
import { IoMdClose } from "react-icons/io";
import { IconButton } from "rsuite";
import Blas975 from "components/Gamer/Blas975";

const formats = {
  en: en,
  de: de,
};

function formatDate(data, formatStr) {
  return format(data, formatStr, {
    locale: localStorage.getItem("lang")
      ? formats[localStorage.getItem("lang")]
      : formats["en"],
  });
}
const action = (snackbarId) => (
  <>
    {" "}
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      {" "}
      <IoMdClose style={{ color: "white" }} fontSize="small" />{" "}
    </IconButton>{" "}
  </>
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <IntlProvider locale="en">
        <CustomProvider theme="dark" locale={enUS}>
          <SnackbarProvider
            action={action}
            hideIconVariant
            persist
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <App />
          </SnackbarProvider>
        </CustomProvider>
      </IntlProvider>
     
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);
