import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AccountMenu from "./Menu/AccountMenu";
import Coupons from "./Coupons";
import Histories from "./Histories";
import { useParams } from "react-router-dom";
import Profile from "./Profile";
import AccountHistories from "./AccountHistories";
import AltenarCoupons from "./AltenarCoupons";

const MyAccount = (props) => {
  const [acitveMenu, setActiveMenu] = useState(null);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  useEffect(() => {
    setActiveMenu(params.option);
  }, [params]);

  return (
    <div className="">
      <div className="myaccountMenu pt-8">
        <AccountMenu acitveMenu={acitveMenu} setActiveMenu={setActiveMenu} />
      </div>
      <div>
        {acitveMenu == "profile" ? (
          <Profile />
        ) : acitveMenu == "coupons" ? (
          <AltenarCoupons />
        ) : acitveMenu == "histories" ? (
          <Histories />
        ) : acitveMenu == "transfers" ? (
          <AccountHistories />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
