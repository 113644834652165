import { UpdateLang } from "_actions";
import { logout } from "_actions";
import DigitalWatch from "components/DigitalWatch";
import i18n from "i18n";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Drawer, Button, Placeholder } from "rsuite";
import LangBox from "./LangBox";
import WalletBox from "./WalletBox";

export const MobileDrawer = (props) => {
  const { currentLang } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Translation>
      {(t) => (
        <>
          <div className=" drawer z-[11]">
            <input
              id="mobile-menu-drawer"
              type="checkbox"
              className="drawer-toggle"
              checked={open}
              onChange={() => setOpen(!open)}
            />
            <div className="drawer-content">
              {/* Page content here */}
              <label
                htmlFor="mobile-menu-drawer"
                className="btn btn-ghost btn-sm btn-square"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </label>
            </div>
            {open && (
              <div className="drawer-side">
                <label
                  htmlFor="mobile-menu-drawer"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>

                <div className="p-4 w-full h-[100vh] min-h-[100vh]  *:text-base-content flex flex-col bg-light-500 justify-evenly">
                  <div className="h-[100px] flex justify-left relative">
                    <div className="mt-4">
                      <DigitalWatch textStyle={"text-xl"} />
                    </div>
                    <div
                      onClick={() => setOpen(false)}
                      className="btn btn-square btn-secondary btn-md absolute right-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="flex-1 ">
                    <LangBox />
                    <WalletBox />

                    <ul className="menu flex mt-4">
                      {props.permissions?.SPORTS_ACCESS?.Value && (
                        <Link
                          onClick={() => setOpen(false)}
                          className="btn btn-ghost text-left text-2xl	 mb-2 text-white flex p-0 hamburger-menu-item"
                          to="/sports"
                        >
                          {t("TITLE_SPORTS")}
                        </Link>
                      )}
                      {props.permissions?.CASINO_ACCESS?.Value && (
                        <Link
                          onClick={() => setOpen(false)}
                          className="btn btn-ghost text-left text-2xl	 mb-2 text-white flex p-0 hamburger-menu-item"
                          to="/casino"
                        >
                          {t("TITLE_CASINO")}
                        </Link>
                      )}

                      {props.permissions?.LIVECASINO_ACCESS?.Value && (
                        <Link
                          onClick={() => setOpen(false)}
                          className="btn btn-ghost text-left text-2xl mb-2 text-white flex p-0 hamburger-menu-item"
                          to="/live-casino"
                        >
                          {t("TITLE_LIVECASINO")}
                        </Link>
                      )}
                      {props.permissions?.GAMES_ACCESS?.Value && (
                        <Link
                          onClick={() => setOpen(false)}
                          className="btn btn-ghost text-left text-2xl mb-2 text-white flex p-0 hamburger-menu-item"
                          to="/games"
                        >
                          {t("TITLE_GAMES")}
                        </Link>
                      )}

                      {props.permissions?.VIRTUAL_ACCESS?.Value && (
                        <Link
                          onClick={() => setOpen(false)}
                          className="btn btn-ghost text-left text-2xl mb-2 text-white flex p-0 hamburger-menu-item"
                          to="/virtual"
                        >
                          {t("TITLE_VIRTUAL")}
                        </Link>
                      )}

                      <div
                        onClick={() => {
                          setOpen(false);
                          props.logout();
                        }}
                        className="btn btn-ghost text-left mb-2 text-2xl  text-red-500 flex p-0 hamburger-menu-item"
                      >
                        {t("LOGOUT")}
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    UpdateLang: (data) => dispatch(UpdateLang(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileDrawer);
