import React, { Component } from "react";
import {
  Alert,
  Modal,
  Form,
  ButtonToolbar,
  IconButton,
  useToaster,
  Schema,
  Icon,
  InputGroup,
} from "rsuite";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

import toast from "react-hot-toast";

import { Translation } from "react-i18next";
import i18n from "i18n";

import { OAuth2Service, UserService } from "_services";
import { authenticationAction } from "_actions";

import { connect } from "react-redux";
import Emitter from "_actions/emitter";

const UpdatePassword = (props) => {
  const model = Schema.Model({
    password: Schema.Types.StringType().isRequired(i18n.t("MSG_NULL_PASSWORD")),
    rePassword: Schema.Types.StringType()
      .isRequired(i18n.t("MSG_NULL_PASSWORD"))
      .addRule((value, data) => {
        if (value !== data.password) {
          return false;
        }
        return true;
      }, i18n.t("MSG_NOT_SAME_PASS")),
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    rePassword: "",
    password: "",
  });

  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };
  const [loading, setLoading] = React.useState(false);

  const checkToken = async () => {
    const { LoginSuccess, LoginFailure } = props;
    await UserService.USER_DETAIL()
      .then((res) => {
        LoginSuccess(res);
        Emitter.emit("UPDATE_PASS", false);
      })
      .catch((err) => {
        LoginFailure();
      });
  };

  const handleLogin = () => {
    setLoading(true);
    OAuth2Service.LOGIN(props.user.RelUser.UserName, formValue.password, false)
      .then((res) => {
        checkToken();
        setLoading(false);
      })
      .catch((err) => {
        debugger;
        props.logout();
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!formRef.current.check()) {
      console.error("Form Error");
      setLoading(false);
      return;
    } else if (formValue.rePassword != formValue.password) {
      toast.error(i18n.t("MSG_NOT_SAME_PASS"));
    } else if (formValue.password.length < 6) {
      toast.error(i18n.t("ERR_PASSWORD_MIN_6"));
    } else {
      debugger;
      UserService.UPDATE_PASSWORD(formValue.password)
        .then((res) => {
          toast.success(i18n.t("SUCCESS_UPDATE_PASSWORD"));
          handleLogin();
        })
        .catch((err) => {
          debugger;
          if (err) {
            toast.error(err.failText);
          } else {
            toast.error(i18n.t("ERR_PASSWORD"));
          }
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    Emitter.emit("LOGIN_MODAL", false);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            // onClose={handleClose}
            backdrop="static"
            role="alertdialog"
            className="custom-modal"
            open={props.isActive}
            size="xs"
          >
            <Modal.Body
              className="bg-light-500 rounded-lg "
              style={{
                paddingTop: "1em",
                marginTop: 0,
                paddingBottom: 0,
                maxHeight: "80vh",
              }}
            >
              <div className="flex justify-center flex-col items-center  shadow-xl ">
                <div>
                  <div className=" my-6">
                    <div className="w-40 ">
                      <img
                        style={{ objectFit: "contain" }}
                        src={require("../../assets/img/textlogo2.png")}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-2">
                  <p className="text-lg font-light text-center">
                    {t("LABEL_CREATE_NEW_PASS", {
                      key: props.user?.RelUser?.UserName,
                    })}
                  </p>
                </div>
                <div className="w-full sm:max-w-md mt-2 px-2 py-4 secondary-950 overflow-hidden sm:rounded-lg">
                  <Form
                    ref={formRef}
                    fluid
                    model={model}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                  >
                    <Form.Group>
                      <Form.ControlLabel className="label-text">
                        {t("TXT_NEWPASSWORD")}
                      </Form.ControlLabel>
                      <InputGroup inside className="flex items-center">
                        <Form.Control
                          onKeyDown={_handleKeyDown}
                          className="input input-bordered w-full max-w outline-primary-500"
                          name="password"
                          type={visible ? "text" : "password"}
                          autoComplete="off"
                        />
                        <InputGroup.Button onClick={handleChange}>
                          {visible ? <EyeIcon /> : <EyeSlashIcon />}
                        </InputGroup.Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.ControlLabel className="label-text">
                        {t("TXT_REPASSWORD")}
                      </Form.ControlLabel>
                      <InputGroup inside className="flex items-center">
                        <Form.Control
                          onKeyDown={_handleKeyDown}
                          className="input input-bordered w-full max-w outline-primary-500"
                          name="rePassword"
                          type={visible ? "text" : "password"}
                          autoComplete="off"
                        />
                        <InputGroup.Button onClick={handleChange}>
                          {visible ? <EyeIcon /> : <EyeSlashIcon />}
                        </InputGroup.Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <ButtonToolbar>
                        <div
                          className="btn btn-primary btn-md btn-block text-white mb-2"
                          onClick={handleSubmit}
                        >
                          {t("BUTTON_UPDATE")}
                        </div>
                        {/*
                     <Button appearance="link">Forgot password?</Button>
                    */}
                      </ButtonToolbar>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
