import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";

const HistoryCard = (props) => {
  const { item, index } = props;
  return (
    <Translation>
      {(t) => (
        <div
          key={index}
          className=" bg-secondary-600 rounded-md mb-2 pb-4 flex flex-col justify-between p-2"
        >
          <div className=" flex flex-row justify-between items-start">
            <div>
              <p className="m-0 text-lg">{item?.RelCategory?.Name}</p>
              <p className="m-0 text-white/90  font-medium">
                {item.RelFinancialType.Value}
              </p>
            </div>
            <p className="text-white/30 text-xs text-right m-0 font-light">
            {moment(new Date(item.RecordTime)).format("ll HH:mm:ss")}
            </p>
          </div>
          <div></div>
          <span
            className={
              " text-right " +
              (item.Amount > 0
                ? "text-success"
                : item.Amount < 0
                ? "text-error"
                : "")
            }
          >
            {`${
              item.Amount
                ? item.Amount.toLocaleString("tr-TR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                : "0,00"
            } ${item.RelCurrency ? item.RelCurrency.Currency : ""}`}
          </span>
          <span className={" text-right text-[12px]"}>
            {`${
              item.CurrentBalance
                ? item.CurrentBalance.toLocaleString("tr-TR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                : "0,00"
            } ${item.RelCurrency ? item.RelCurrency.Currency : ""}`}
          </span>

          {item.Game && item.Game.includes("-")&&(
            <>
              <span className="text-white/40 text-xs">
                {item.Game.split("-")[0]}
              </span>
              <span className="text-white/40 text-xs">
                {item.Game.split("-")[1]}
              </span>
            </>
          )}
          {item.Game && item.Game.includes("_")&&(
            <>
              <span className="text-white/40 text-xs">
                {item.Game.split("_")[0]}
              </span>
              <span className="text-white/40 text-xs">
                {item.Game.split("_")[1]}
              </span>
            </>
          )}

          {item.TicketId && (
            <span className="text-white/40 text-xs">
              {t("LABEL_TICKET_NO") + " : #" + item.TicketId}
            </span>
          )}

          {/*
     <p className="font-thin text-[9px] text-secondary-400">{index}</p>
    */}
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCard);
