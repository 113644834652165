import CreateGamer from "components/Partner/CreateGamer";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Translation } from "react-i18next";

import "swiper/css";
import "swiper/css/pagination";

import Pick777 from "components/Gamer/Pick777";
import Casino from "components/Gamer/Casino";
import LiveCasino from "components/Gamer/LiveCasino";
import Games from "components/Gamer/Games";
import Virtual from "components/Gamer/Virtual";
import Pragmatic from "components/Gamer/Pragmatic";
import SmartSoft from "components/Gamer/SmartSoft";
import { gameAction } from "_actions";
import { GameService } from "_services";
import Blas975 from "components/Gamer/Blas975";
import Live from "components/Gamer/Live";
import MyAccount from "components/Gamer/MyAccount";
import WorldMatch from "components/Gamer/WorldMatch";
import Detroit from "components/Gamer/Detroit";
import VirtualPragmatic from "components/Gamer/Virtual/VirtualPragmatic";
import TimelessTech from "components/Gamer/TimelessTech";
import Altenar from "components/Gamer/Altenar";
import i18n from "i18n";
import TVGames from "components/Gamer/TVGames";
const Gamer = (props) => {
  const { UpdateGames, UpdateLiveGame, UpdateSport } = props;
  const [startPage, setStartPage] = useState("/sports");
  const [isAltenarReady, setisAltenarReady] = useState(false);

  const getGames = () => {
    GameService.GET_GAMES()
      .then((res) => {
        const newData = res.map((x) => {
          let y = x;
          if (x.middleware == "blas975") {
            y.CacheData = x.CacheData.reverse();
            return y;
          } else {
            return x;
          }
        });
        UpdateGames(res);
      })
      .catch((err) => {
        debugger;
      });
  };
  const getLiveGames = () => {
    GameService.GET_LIVE_GAMES()
      .then((res) => {
        UpdateLiveGame(res);
        //UpdateGames(res);
      })
      .catch((err) => {});
  };

  const getAltenarToken = () => {
    const lng = localStorage.getItem("lang");
    GameService.GET_ALTENARSPORTS_TOKEN()
      .then((res) => {
        UpdateSport(res.token);
        debugger;
        window.altenarWSDK.init({
          token: res.token,
          integration: "maxwin2000", //maxwin2000
          culture:  i18n.t("altenarC"),
          // themeName: "dark",
        });
        setisAltenarReady(true);
      })
      .catch((err) => {
        debugger;
      });
  };

  useEffect(() => {
    getGames();
    getLiveGames();
    if (window.altenarWSDK) {
      getAltenarToken();
    }
  }, []);

  useEffect(() => {
    if (props.permissions) {
      if (
        props.permissions?.SPORTS_ACCESS &&
        props.permissions?.SPORTS_ACCESS?.Value
      ) {
        if (startPage != "/sports") setStartPage("/sports");
      } else if (
        props.permissions?.CASINO_ACCESS &&
        props.permissions?.CASINO_ACCESS?.Value
      ) {
        if (startPage != "/casino") setStartPage("/casino");
      } else if (
        props.permissions?.LIVECASINO_ACCESS &&
        props.permissions?.LIVECASINO_ACCESS?.Value
      ) {
        setStartPage("/live-casino");
      } else if (
        props.permissions?.GAMES_ACCESS &&
        props.permissions?.GAMES_ACCESS?.Value
      ) {
        setStartPage("/games");
      } else if (
        props.permissions?.VIRTUAL_ACCESS &&
        props.permissions?.VIRTUAL_ACCESS?.Value
      ) {
        setStartPage("/virtual");
      } else if (
        props.permissions?.TVGAMES_ACCESS &&
        props.permissions?.TVGAMES_ACCESS?.Value
      ) {
        setStartPage("/my-account/");
      } else {
        setStartPage("/my-account/");
      }
    }
  }, []);

  return (
    <Translation>
      {(t) => (
        <>
          <Routes key={startPage}>
            <Route path="*" element={<Navigate to={startPage} />} />

            {props.permissions?.SPORTS_ACCESS?.Value && (
              <Route
                index
                path="/sports"
                element={isAltenarReady ? <Altenar /> : <></>}
              />
            )}

            {props.permissions?.CASINO_ACCESS?.Value && (
              <>
                <Route path="/casino" element={<Casino />} />
                <Route path="/casino/:ProviderId" element={<Casino />} />
              </>
            )}

            {props.permissions?.LIVECASINO_ACCESS?.Value && (
              <>
                {props?.permissions?.PRAGMATIC_LIVECASINO_ACCESS?.Value ? (
                  <Route
                    path="/live-casino"
                    element={<Navigate to="/live-casino/537" />}
                  />
                ) : (
                  <Route path="/live-casino/" element={<LiveCasino />} />
                )}

                <Route
                  path="/live-casino/:ProviderId"
                  element={<LiveCasino />}
                />
              </>
            )}

            {props.permissions?.GAMES_ACCESS?.Value && (
              <>
                <Route path="/games" element={<Games />} />
                <Route path="/games/:ProviderId" element={<Casino />} />
              </>
            )}
            {props.permissions?.TVGAMES_ACCESS?.Value && (
              <>
                <Route path="/tv-games" element={<TVGames />} />
              </>
            )}

            {props.permissions?.VIRTUAL_ACCESS?.Value && (
              <>
                <Route path="/virtual" element={<Virtual />} />
                <Route
                  path="/virtual-pragmatic"
                  element={<VirtualPragmatic />}
                />
                <Route path="/virtual/:ProviderId" element={<Casino />} />
              </>
            )}

            <Route path="/pragmatic/:GameId" element={<Pragmatic />} />

            <Route
              path="/WorldMatch/:ProviderId/:GameId"
              element={<WorldMatch demo={false} />}
            />
            <Route
              path="/WorldMatch-demo/:ProviderId/:GameId"
              element={<WorldMatch demo={true} />}
            />

            <Route path="/live/:GameId" element={<Live demo={false} />} />

            <Route
              path="/pragmatic-demo/:GameId"
              element={<Pragmatic demo={true} />}
            />
            <Route
              path="/smartsoft/:GameId"
              element={<SmartSoft demo={false} />}
            />
            <Route
              path="/smartsoft-demo/:GameId"
              element={<SmartSoft demo={true} />}
            />
            <Route
              path="/blas975/:ProviderId/:GameId"
              element={<Blas975 demo={false} />}
            />
            <Route
              path="/blas975-demo/:ProviderId/:GameId"
              element={<Blas975 demo={true} />}
            />
            <Route
              path="/detroit/:ProviderId/:GameId"
              element={<Detroit demo={false} />}
            />
            <Route
              path="/detroit-demo/:ProviderId/:GameId"
              element={<Detroit demo={true} />}
            />

            <Route
              path="/timelesstech/:ProviderId/:GameId"
              element={<TimelessTech demo={false} />}
            />
            <Route
              path="/timelesstech-demo/:ProviderId/:GameId"
              element={<TimelessTech demo={true} />}
            />

            <Route
              path="/my-account/"
              element={<Navigate to="/my-account/profile" />}
            />

            <Route
              path="/my-account/:option"
              element={isAltenarReady ? <MyAccount /> : <></>}
            />
          </Routes>
        </>
      )}
    </Translation>
  );
};
const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn, permissions, permissionsAll } = authentication;

  return {
    user,
    loggedIn,
    permissions,
    permissionsAll,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
    UpdateLiveGame: (data) => dispatch(gameAction.UpdateLiveGame(data)),
    UpdateLiveGameProviders: (data) =>
      dispatch(gameAction.UpdateLiveGameProviders(data)),
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Gamer);
