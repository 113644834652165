import React from "react";
import { connect } from "react-redux";
import { Form, Input, ButtonToolbar, Button } from "rsuite";
import { Translation } from "react-i18next";
import toast from "react-hot-toast";
import { UserEndPoints } from "_services";
import { logout } from "_actions";
import { UpdateLang } from "_actions";
import { UserService } from "_services";
const Profile = (props) => {
  const [formValue, setFormValue] = React.useState({
    password: "",
  });
  return (
    <Translation>
      {(t) => (
        <div className="h-full p-2">
          <div>
            <h1 className="text-lg px-2 py-2 bg-secondary-400 rounded-t-lg">
              {t("TITLE_MYACCOUNT")}
            </h1>
            <div
              style={{
                overflow: "auto",
                height: `calc(100vh - 252px)`,
              }}
              className="p-4 md:p-4 bg-light-500 rounded-b-lg"
            >
              <Form
                className="max-w-[360px]"
                //  layout="inline"
                onChange={setFormValue}
                formValue={formValue}
              >
                <Form.Group className="w-full" controlId="password">
                  <Form.ControlLabel>{t("TXT_PASSWORD")}</Form.ControlLabel>
                  <div className="flex flex-row relative justify-between">
                    <Form.Control
                      name="Password"
                      type="password"
                      placeholder={t("TXT_PASSWORD")}
                      autoComplete="off"
                      style={{ width: "100%" }}
                      classPrefix=" w-[100%] mr-4"
                      className="w-full"
                    />
                    <Button
                      appearance="primary"
                      className="bg-primary-500"
                      disabled={!formValue.Password}
                      //className="btn btn-primary text-white btn-md"
                      onClick={() => {
                        if (formValue.Password) {
                          if (formValue.Password.trim().length < 6) {
                            toast.error(t("ERR_PASSWORD_MIN_6"));
                          } else {
                            UserService.UPDATE_PASSWORD(formValue.Password)
                              .then((res) => {
                                toast.success(t("SUCCESS_UPDATE_PASSWORD"));
                               // props.logout();
                              })
                              .catch((err) => {
                                debugger;
                                if (err) {
                                  toast.error(err.failText);
                                } else {
                                  toast.error(t("ERR_PASSWORD"));
                                }
                              });
                          }
                        }
                      }}
                    >
                      {t("BUTTON_UPDATE")}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
              <div className="divider"></div>
              <Form
                readOnly
                className="max-w-[360px]"
                formValue={{ ...props.user, ...props.user.RelUser }}
              >
                <Form.Group>
                  <Form.ControlLabel>{t("TXT_USERNAME")}</Form.ControlLabel>
                  <Form.Control
                    onChange={(v) => {
                      debugger;
                    }}
                    name="UserName"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>{t("TXT_ID")}</Form.ControlLabel>
                  <Form.Control maxLength={256} name="UserID" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>{t("TXT_NAME")}</Form.ControlLabel>
                  <Form.Control maxLength={256} name="FirstName" />
                </Form.Group>

                <Form.Group>
                  <Form.ControlLabel>{t("TXT_CURRENCY")}</Form.ControlLabel>
                  <Form.Control maxLength={256} name="Currency" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>{t("TXT_BALANCE")}</Form.ControlLabel>
                  <Form.Control maxLength={256} name="Balance" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>{t("TXT_BONUS")}</Form.ControlLabel>
                  <Form.Control maxLength={256} name="Bonus" />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    UpdateLang: (data) => dispatch(UpdateLang(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
