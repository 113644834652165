import React from "react";
import { connect } from "react-redux";
import Table from "rc-table";
import ChildUserTable from "./ChildUserTable";
import ChildFinansTable from "./ChildFinansTable";
import { Panel, Placeholder, Row, Col } from "rsuite";
import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi2";
import { HiDocumentReport, HiOutlineDocumentReport } from "react-icons/hi";
import { IoGameController, IoGameControllerOutline } from "react-icons/io5";
import ChildFinansReportTable from "./ChildFinansReportTable";
import UserDetail from "components/Modals/UserDetail";

const columns = [
  {
    title: "User",
    dataIndex: "InOut", //["RelUser", "UserID"],
    className: "text-xs ",
    key: "UserID",
    width: 160,
    render: (value) => <UserDetail data={value} />,

  },

  {
    title: "In - Out",
    dataIndex: "InOut",
    className: "text-xs table-cell sm:hidden",
    key: "InOut",
    width: 200,
    render: (value) => (
      <div className={`p-2 rounded-md text-[10px]`}>
        <div>
          <span>
            {`In : ${value.In.toLocaleString("tr-TR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`}
          </span>
        </div>

        <div>
          <span>
            {`Out : ${value.Out.toLocaleString("tr-TR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`}
          </span>
        </div>
      </div>
    ),
  },

  {
    title: "In",
    dataIndex: "In",
    className: "text-xs  hidden sm:table-cell",
    key: "In",
    width: 80,
    render: (value) => (
      <div className={`p-2 rounded-md `}>
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
  {
    title: "Out",
    dataIndex: "Out",
    className: "text-xs hidden sm:table-cell",
    key: "Out",
    width: 80,
    render: (value) => (
      <div className={`p-2 rounded-md `}>
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },

  {
    title: "GGR",
    dataIndex: "GGR",
    className: "text-xs text-right ",
    key: "GGR",
    width: 80,
    render: (value) => (
      <div
        className={`p-2 rounded-md text-[10px] sm:text-[12px] md:text-xs ${
          value > 0
            ? "bg-green-700"
            : value == "0"
            ? "bg-transparent"
            : "bg-red-700"
        }`}
      >
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
];

export const FinansTable = ({ data, result }) => {
  const onExpand = (expanded, record) => {
    // eslint-disable-next-line no-console
    //console.log("onExpand", expanded, record);
  };
  function CustomExpandIcon(props) {
    // debugger;

    return (
      <div
      className={ (props.record.User.UserType.TypeID == 1
        ? "bg-master-500  text-master-50"
        : props.record.User.UserType.TypeID == 2
        ? "bg-superadmin-500 text-superadmin-50"
        : props.record.User.UserType.TypeID == 3
        ? "bg-admin-500 text-admin-900"
        : props.record.User.UserType.TypeID == 4
        ? "bg-partner-500 text-partner-900"
        : "bg-gamer-500  text-gamer-800")+ ` btn   ${
        props.expanded ? "" : "btn-outline"
      } btn-sm btn-square` }
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        // eslint-disable-next-line react/no-danger
      >
        {props.expanded ? (
          props.record?.Reports?.length > 0 ? (
            <HiOutlineDocumentReport />
          ) : props.record.hasOwnProperty("Detail") ? (
            <IoGameControllerOutline />
          ) : (
            <HiOutlineUserGroup />
          )
        ) : props.record?.Reports?.length > 0 ? (
          <HiDocumentReport />
        ) : props.record.hasOwnProperty("Detail") ? (
          <IoGameController />
        ) : (
          <HiUserGroup />
        )}
      </div>
    );
  }
  function countAllChildren(node) {
    let totalCount = 0;

    if (node.ChildDetails && node.ChildDetails.length > 0) {
      node.ChildDetails.forEach((childNode) => {
        totalCount += 1 + countAllChildren(childNode); // Toplam, kendisi dahil alt düğümlerinin sayısını hesaplar
      });
    }

    return totalCount;
  }

  const converToMoney = (value) => {
    return value;
  };
  return (
    <div>
      {result && (
        <div className=" mb-2 ">
          <div className="stats stats-horizontal shadow bg-secondary-500 rounded-md">
            <div className="stat px-4 py-2 sm:px-6 sm:py-4 ">
              <div className="stat-title">In</div>
              <div className="stat-desc">{converToMoney(result.In)}</div>
            </div>

            <div className="stat px-4 py-2 sm:px-6 sm:py-4 ">
              <div className="stat-title">Out</div>
              <div className="stat-desc">{converToMoney(result.Out)}</div>
            </div>

            <div
              className={`stat px-4 py-2 sm:px-6 sm:py-4 ${
                result.GGR > 0
                  ? "bg-green-700/10"
                  : result.GGR == "0"
                  ? "bg-transparent"
                  : "bg-red-700/10"
              }`}
            >
              <div className="stat-title">GGR</div>
              <div
                className={`stat-desc ${
                  result.GGR > 0
                    ? "text-green-700"
                    : result.GGR == "0"
                    ? "bg-transparent"
                    : "text-red-700"
                }`}
              >
                {converToMoney(result.GGR)}
              </div>
            </div>
          </div>
        </div>
      )}

      <Table
        columns={columns}
        rowClassName={(record, i) =>
          `${
            record.ChildDetails.length > 0 ? "" : "RemoveSubRow"
          } border-b border-secondary-400/20 row-${i}`
        }
        expandable={{
          // expandRowByClick: true,
          expandedRowRender: (record) => {
            if (record.ChildDetails.length > 0) {
              return (
                <div className="py-1 bg-secondary-800 rounded-sm m-0">
                  <ChildFinansTable
                    childs={record.ChildDetails}
                    rowData={record}
                  />
                </div>
              );
            } else if (record.Reports.length > 0) {
              return (
                <ChildFinansReportTable
                  childs={record.Reports}
                  rowData={record}
                />
              );
            } else {
              return undefined;
            }
          },
          onExpand,
          expandIcon: CustomExpandIcon,
        }}
        data={
          data
            ? [
                ...data.map((item) => ({
                  ...item,
                  key: item.User._id,
                  Name: item.User, //`${item.User.UserName} (#${item.User.UserID})`,
                  InOut: item,
                })),
              ]
            : []
        }
        className="table table-sm w-full bg-secondary-500 px-1 rounded-md"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FinansTable);
