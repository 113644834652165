import { gameAction } from "_actions";
import { GameService } from "_services";
import { UserService } from "_services";
import i18n from "i18n";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

import { Loader } from "rsuite";

const AltenarCoupons = (props) => {
  const { currentLang, UpdateSport } = props;
  const [loading, setLoading] = useState(true);
  const [isError, setErrorStatus] = useState(false);
  const [updatedLang, setUpdateLang] = useState(currentLang);

  const containerRef = useRef(null);

  useEffect(() => {
    //  if (window.altenarWSDK)

    getAltenarToken();
    return () => {
      if (window.ASB) {
        window.ASB.remove();
      }
      if (window.WBS) {
        window.WBS.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (window.altenarWSDK && updatedLang != currentLang) {
      setUpdateLang(currentLang);
      window.altenarWSDK.set({
        culture: i18n.t("altenarC"),
      });
    }
  }, [currentLang]);

  const getAltenarToken = () => {
    setLoading(true);
    GameService.GET_ALTENARSPORTS_TOKEN()
      .then((res) => {
        setErrorStatus(false);
        UpdateSport(res.token);
        setTimeout(() => {
          initAltenar(res.token);
        }, 300);
        handleLoad();
      })
      .catch((err) => {
        debugger;
        setErrorStatus(true);
        setLoading(false);
      });
  };

  const handleLoad = async () => {
    await setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const initAltenar = (token) => {
    //debugger;
    // const _altenarWSDK = window.altenarWSDK;
    if (window.altenarWSDK) {
      if (token != props.sports) {
        window.altenarWSDK.set({
          token: token,
          // culture: i18n.t("altenarC"),
        });
      }

      window.WBS = window.altenarWSDK.addWidget({
        widget: "WBetHistory",
        props: {
          showStatusFilter: true,
        },
        tokens: {
          BetBox: {
            paddingHorizontal: 20,
          },
        },
        container: document.getElementById("container"),
      });
    }
    setLoading(false);
    adjustHeight();
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);

  const prepareCoupons = () => {
    window.WBS = window.altenarWSDK.addWidget({
      widget: "WBetHistory",
      props: {
        showStatusFilter: true,
      },
      container: document.getElementById("container"),
    });
    setLoading(false);
  };

  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;
      const myaccountMenu =
        document.querySelector(".myaccountMenu")?.offsetHeight;
      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1) -
          myaccountMenu -
          60;

        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {
      debugger;
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{"Bets"}</title>
          </Helmet>
          <div className="h-full p-2">
            <div>
              <h1 className="text-lg px-2 py-2">{t("TITLE_MYBETS")}</h1>
            </div>
            <div ref={containerRef} style={{ overflowY: "scroll" }}>
              <div id="container"></div>
            </div>

            {loading && <Loader backdrop center />}
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};
/*
.filter((x) =>
                  x.TicketID.toLocaleLowerCase().includes(
                    FilterSearchValue?.toLocaleLowerCase()
                  )
                )
*/

export default connect(mapStateToProps, mapDispatchToProps)(AltenarCoupons);
