import i18n from "i18n";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Notification, useToaster } from "rsuite";
import { Formik, Form, Field } from "formik";
import ForikField from "components/ForikField";
import ForikFieldCheckBox from "components/ForikFieldCheckBox";
import { AdminService } from "_services/admin.service";

const CreatePartner = (props) => {
  const [loading, setLoading] = React.useState(false);
  const toaster = useToaster();
  const message = (msg, type) => {
    return (
      <Notification type={type} header={i18n.t(type)} closable>
        <p>{msg}</p>
      </Notification>
    );
  };
  const SignupSchema = Yup.object().shape({
    User: Yup.array(
      Yup.object({
        UserName: Yup.string().required(),
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        Email: Yup.string().email(i18n.t("ERR_INVALID_EMAIL")).required(),
      })
    ).required("Required"),
    Setting: Yup.array(
      Yup.object({
        UserCreateLimit: Yup.number().required(),
      })
    ).required("Required"),
  });

  const handleSubmit = async (formValue, actions) => {
    setLoading(true);
    actions.setSubmitting(true);
    await AdminService.CREATE_PARTNER(formValue)
      .then((res) => {
        actions.resetForm();

        toaster.push(
          message(i18n.t("MESSAGE_CREATE_PARTNER_SUCCESS"), "success"),
          {
            duration: 2000,
          }
        );
        actions.setSubmitting(false);
      })
      .catch((err) => {
        debugger;
        if (err) {
          toaster.push(message(i18n.t("MESSAGE_CREATE_PARTNER_FAILD"), "error"), {
            duration: 2000,
          });
        } else {
          toaster.push(message(i18n.t("MESSAGE_CREATE_PARTNER_FAILD"), "error"), {
            duration: 2000,
          });
        }
        actions.setSubmitting(false);
      });
  };

  return (
    <Translation>
      {(t) => (
        <div>
          <div className="border-b py-3 border-primary-500 mb-5 shadow-xl">
            <label className="text-lg text-white">
              {t("TITLE_CREATE_PARTNER")}
            </label>
          </div>
          <Formik
            initialValues={{
              Setting: [
                {
                  UserCreateLimit: 0,
                },
              ],
              User: [
                {
                  UserName: "",
                  FirstName: "",
                  LastName: "",
                  Email: "",
                  Password: "",
                  RePassword: "",
                },
              ],
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, actions) => {
              console.log(values);
              debugger;
              if (values["User"][0]["UserName"].length < 2) {
                toaster.push(message(i18n.t("ERR_REQUIRED_FORM"), "error"), {
                  duration: 2000,
                });
              } else {
                handleSubmit(values, actions);
              }
            }}
          >
            {({ errors, touched, validateField, validateForm }) => (
              <Form className="flex flex-col ">
                <div className="grid  grid-rows-6 sm:grid-rows-4 md:grid-rows-3 lg:grid-rows-2 2xl:grid-rows-1 grid-flow-col gap-4">
                  <ForikField
                    title={t("LABEL_USERNAME")}
                    required={true}
                    formikField={
                      <Field
                        className={"input hover:outline-primary-500"}
                        name="User[0].UserName"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_FIRSTNAME")}
                    required={true}
                    formikField={
                      <Field
                        className={"input hover:outline-primary-500"}
                        name="User[0].FirstName"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_LASTNAME")}
                    required={true}
                    formikField={
                      <Field
                        className={"input hover:outline-primary-500"}
                        name="User[0].LastName"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_EMAIL")}
                    required={true}
                    formikField={
                      <Field
                        type="email"
                        className={"input hover:outline-primary-500"}
                        name="User[0].Email"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_PASSWORD")}
                    formikField={
                      <Field
                        type="password"
                        className={"input hover:outline-primary-500"}
                        name="User[0].Password"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_REPASSWORD")}
                    formikField={
                      <Field
                        type="password"
                        className={"input hover:outline-primary-500"}
                        name="User[0].RePassword"
                      />
                    }
                  />
                </div>
                <div className="grid   lg:grid-rows-1 md:grid-rows-2 sm:grid-rows-3 grid-rows-4   grid-flow-col gap-4 mt-6">
                  <ForikField
                    title={t("LABEL_USERCREATELIMIT")}
                    required={true}
                    formikField={
                      <Field
                        type="number"
                        className={"input hover:outline-primary-500"}
                        name="Setting[0].UserCreateLimit"
                      />
                    }
                  />
                </div>
                <button
                  className="btn btn-primary text-white mt-10"
                  type="submit"
                  //</Form>onClick={() => {
                  /* const result = await validateForm();
                    const errors = Object.values(result);
                    if (errors.length > 0) {
                      console.log(result);
                    } else {
                      console.log("başarılı");
                    }
                    */
                  //  }}
                >
                  {t("BUTTON_CREATE")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePartner);
