import React, { useEffect } from "react";
import { connect } from "react-redux";

import { authenticationAction } from "_actions";
import Emitter from "_actions/emitter";
import { OAuth2Service } from "_services";

// User has switched back to the tab

function GetDiffTimes(times) {
  let CurrentTime = new Date();
  let endTime = new Date(parseFloat(times));
  let difference = endTime.getTime() - CurrentTime.getTime(); // This will give difference in milliseconds
  let resultInMinutes = Math.round(difference / 60000);
  return resultInMinutes;
}
// User has switched away from the tab (AKA tab is hidden)
const onBlur = () => {
  //console.log("Tab is blurred");
};
export const checkCustomTokenExpires = async () => {
  let refresh_token = localStorage.getItem("refresh_token");
  let expires_in = localStorage.getItem("expires_in");
  let diff = GetDiffTimes(expires_in);
   
  if (diff < 15) {
    await OAuth2Service.REFRESH_TOKEN(refresh_token)
      .then((data) => {
        debugger;

        console.log("token updated");
      })
      .catch((err) => {
        window.location.reload(true);
      });
  } else {
  }
};
const onFocus = () => {
  checkCustomTokenExpires();
};
const WindowFocusHandler = () => {
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return <></>;
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WindowFocusHandler);
