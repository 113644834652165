import { UserEndPoints, OAuth2_MakeRequestAndGetResponse } from "./index";
import { HTTPOperationType } from "./httpOperations";
import { GetBaseURL, SuperAdminEndPoints } from "./_serviceSettings";
import Emitter from "_actions/emitter";

export const SuperAdminService = {
  CREATE_ADMIN,
};

async function CREATE_ADMIN(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + SuperAdminEndPoints.CREATE_ADMIN,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
