import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Notification, useToaster } from "rsuite";
import Table from "rc-table";

import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";

import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi2";
import RowTable from "components/Tables/RowTable";
import { RootService } from "_services/root.service";
import UserTable from "components/Tables/UserTable";
import FinansTable from "components/Tables/FinansCash/FinansTable";

const columns = [
  {
    title: "UserID",
    dataIndex: "UserID",
    className: "",
    key: "UserID",
    width: 80,
  },
  {
    id: "UserName",
    title: "UserName",
    dataIndex: "UserName",
    className: "b",
    key: "UserName",
    width: 200,
  },

  {
    title: "Operations",
    dataIndex: "",
    className: "text-right",
    key: "d",
    render() {
      return <a href="#">Operations</a>;
    },
  },
];

export const InOuts = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);
  const [result, setResult] = useState({});

  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  useEffect(() => {
    getListData();
  }, []);

  const getListData = async () => {
    setLoading(true);
    await RootService.GET_FINANCIAL_CASH()
      .then((res) => {
        debugger
        setLoading(false);
        setData(res.dynamicValue.Result.ChildDetails);
        setResult(res.Result);
      })
      .catch((err) => {
        setLoading(false);

        if (err) {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        } else {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        }
      });
  };

  const onExpand = (expanded, record) => {
    // eslint-disable-next-line no-console
    //console.log("onExpand", expanded, record);
  };

  function CustomExpandIcon(props) {
    return (
      <div
        className={`btn btn-primary text-white ${
          props.expanded ? "" : "btn-outline"
        } btn-sm btn-square`}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        // eslint-disable-next-line react/no-danger
      >
        {props.expanded ? <HiOutlineUserGroup /> : <HiUserGroup />}
      </div>
    );
  }

  return (
    <Translation>
      {(t) => (
        <div>
          <div className="border-b py-3 border-primary-500 mb-5 shadow-xl">
            <label className="text-lg text-white">
              {t("MENU_ITEM_FINANCIAL")}
            </label>
          </div>
          <div>
            <FinansTable data={data} result={result} />
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InOuts);
