import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function SwiteMobileItem({ item, key, className, data,provider }) {
  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const getPath = () => {
    return "/casino/" + data.value;
  };

  const toPath = data ? getPath() : "/casino";

  return data ? (
    <Link
      to={toPath}
      key={key}
      className={
        " game-provider-bg bg-primary-500 w-full  shadow-xl relative p-[.08rem]  " +
        className
      }
    >
      <div
        className={`h-full w-full  ${
          provider
            ? provider == data.value
              ? "bg-secondary-600/50"
              : "bg-secondary-600"
            : data.value == "pragmatic-casino"
            ? "bg-secondary-600/50"
            : "bg-secondary-600"
        } rounded-lg p-1`}
      >
        <div
          className="h-full w-full"
          style={{
            backgroundImage: `url(${item})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </Link>
  ) : (
    <div
      key={key}
      className={
        " game-provider-bg bg-primary-500 w-full  shadow-xl relative p-[.08rem]  " +
        className
      }
    >
      <div className="h-full w-full  bg-secondary-600 rounded-lg p-1">
        <div
          className="h-full w-full"
          style={{
            backgroundImage: `url(${item})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
}
