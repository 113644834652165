import moment from "moment";
import React, { useState, useEffect } from "react";

const DigitalWatch = ({ onCheckboxChange, className, textStyle }) => {
  const [currentTime, setCurrentTime] = useState();
  const [checkboxValue, setCheckboxValue] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCheckboxChange = () => {
    setCheckboxValue((prevValue) => !prevValue);
    // onCheckboxChange(!checkboxValue); // Bu satır ile checkbox değeri dışa aktarılabilir.
  };

  return (
    <div className={"mx-1  " + " " + className}>
      <span className={"text-xs text-secondary-300 font-semibold"+ " " + textStyle}>
        {" "}
        {moment().format("DD/MM ")}
      </span>
      <span
        className={"text-xs text-secondary-300 font-semibold" + " " + textStyle}
      >
        {currentTime?.toLocaleTimeString()}
      </span>
      <input
        className="hidden"
        type="checkbox"
        id="myCheckbox"
        checked={checkboxValue}
        onChange={handleCheckboxChange}
      />
      <label className="hidden" htmlFor="myCheckbox">
        {" "}
      </label>
    </div>
  );
};

export default DigitalWatch;
