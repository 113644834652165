import { gameAction } from "_actions";
import React, { useEffect, useRef, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProvidersSwiper from "../LiveCasino/ProvidersSwiper";
import PageBanner from "../PageBanner";
import ProvidersMobileSwiper from "../LiveCasino/ProvidersMobileSwiper";
import { MdOutlineCasino } from "react-icons/md";
import { PiCoinsBold } from "react-icons/pi";

import PragmaticGameBox from "../GamerBoxes/PragmaticGameBox";
import SmartSoftGameBox from "../GamerBoxes/SmartSoftGameBox";
import BLAS975GameBox from "../GamerBoxes/BLAS975GameBox";
import { useParams } from "react-router-dom";
import WMGameBox from "../GamerBoxes/WMGameBox";
import DetroitBox from "../GamerBoxes/DetroitBox";
import { isBrowser, isMobile, isTablet, isAndroid } from "react-device-detect";
import TimelessTechGameBox from "../GamerBoxes/TimelessTechGameBox";
import LiveGameBox from "../GamerBoxes/LiveGameBox";
import TimelessTechGameBoxLive from "../GamerBoxes/TimelessTechGameBoxLive";
import CategoryType from "./CategoryType";
import { Loader } from "rsuite";

const ScrollableListView = (props) => {
  const [updatedListKey, setupdatedListKey] = useState(new Date().toJSON());
  const {
    user,
    loggedIn,
    sports,
    games,
    UpdateSport,
    //   data,
    pb,
    provider,
    setProvider,
    FilterSearchValue,
    setFilterSearchValue,
    isDirectLobby,
    title,
    loading,
  } = props;

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(true);

  const [visibleData, setVisibleData] = useState([]);
  const containerRef = useRef(null);

  const [fabActive, setFabActive] = useState(false);

  const [acitveMenu, setActiveMenu] = useState("hot");
  const [data, setData] = useState([...props.data]);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop > 300 && !fabActive) {
      setFabActive(true);
      // debugger;
    }
    if (scrollTop < 300 && fabActive) {
      setFabActive(false);
      //    debugger;
    }

    if (scrollTop + clientHeight >= scrollHeight) {
      setVisibleData((prevData) => [
        ...prevData,
        ...data?.slice(prevData.length, prevData.length + 40),
      ]); // Load next 10 items, adjust as needed
    }

    if (scrollTop > lastScrollTop) {
      setIsNavVisible(false);
    } else {
      setIsNavVisible(true);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };
  useEffect(() => {
    const _v = data ? data.slice(0, 40) : [];
    setVisibleData(_v); // Initial rendering, show first 10 items
  }, [data]);

  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1);
        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);

  useEffect(() => {
    setupdatedListKey(new Date().toJSON());
  }, [props?.permissions, games]);

  return (
    <Translation>
      {(t) => (
        <>
          <div
            style={{ overflow: "scroll" }}
            ref={containerRef}
            onScroll={handleScroll}
            className="pb-[20px] bg-secondary-500 overflow-y-scroll"
            onLoad={adjustHeight}
            key={updatedListKey}
          >
            <PageBanner
              title={title}
              subTitle={t("SUBTITLE_BANNER_CASINO")}
              bg={pb}
            />
            {isMobile || isTablet || isAndroid ? (
              <section className=" py-2 ">
                <ProvidersMobileSwiper
                  provider={provider}
                  _data={[
                    {
                      name: "live/537.png",
                      value: "537",
                    },
                    {
                      name: "live/668.png",
                      value: "668",
                    },
                    {
                      name: "live/480.png",
                      value: "791",
                    },
                    {
                      name: "live/19.png",
                      value: "75",
                    },
                    ...[
                      ...GameProviders.filter((x) => {
                        if (
                          x.value == "668" &&
                          !props?.permissions
                            ?.DETROIT_ABSOLUTE_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "791" &&
                          !props?.permissions
                            ?.DETROIT_EVOLUTION_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "75" &&
                          !props?.permissions?.DETROIT_EZUGI_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WorldMatch" &&
                          !props?.permissions?.WM?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WorldMatch" &&
                          !props?.permissions?.WM?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "pragmatic-casino" &&
                          !props?.permissions?.PRAGMATIC_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "amatic" &&
                          !props?.permissions?.AMATIC_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "novomatic" &&
                          !props?.permissions?.NOVOMATIC_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "bomba" &&
                          !props?.permissions?.BOMBA_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "egt" &&
                          !props?.permissions?.EGT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "netent" &&
                          !props?.permissions?.NETENT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "yggdrasil" &&
                          !props?.permissions?.YGGDRASIL_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "playngo" &&
                          !props?.permissions?.PLAYNGO_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "aristocrat" &&
                          !props?.permissions?.ARISTOCRAT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "redtiger" &&
                          !props?.permissions?.REDTIGER_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "playtech" &&
                          !props?.permissions?.PLAYTECH_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "1X2_GAMING" &&
                          !props?.permissions?.X12_GAMING_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "EVOPLAY" &&
                          !props?.permissions?.EVOPLAY_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "IGT" &&
                          !props?.permissions?.IGT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "PLAYSON" &&
                          !props?.permissions?.PLAYSON_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "BOOONGO" &&
                          !props?.permissions?.BOOONGO_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WAZDAN" &&
                          !props?.permissions?.WAZDAN_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "RUBYPLAY" &&
                          !props?.permissions?.RUBYPLAY_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "MICROGAMING" &&
                          !props?.permissions?.MICROGAMING_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "BETSOFT" &&
                          !props?.permissions?.BETSOFT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "OCTAVIAN" &&
                          !props?.permissions?.OCTAVIAN_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "SCIENTIFIC_GAMES" &&
                          !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "Evolution-fundist" &&
                          !props?.permissions?.WM_EVOLUTION_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "Ezugi-fundist" &&
                          !props?.permissions?.WM_EZUGI_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "Atmosfera-fundist" &&
                          !props?.permissions?.WM_ATMOSFERA_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "VivoGaming-fundist" &&
                          !props?.permissions?.WM_VIVO_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WMCasino-fundist" &&
                          !props?.permissions?.WM_WMCASINO_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "XProGaming-fundist" &&
                          !props?.permissions?.WM_XPROGAMING_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "LuckyStreak-fundist" &&
                          !props?.permissions?.WM_LUCKYSTREAK_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "AESexyBaccarat-fundist" &&
                          !props?.permissions?.WM_AESEXY_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "LiveGames-fundist" &&
                          !props?.permissions?.WM_LIVEGAMES_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else return true;
                      }),
                    ].filter((x) => games && games.hasOwnProperty(x.value)),
                  ].filter((x) => {
                    if (
                      x.value == "537" &&
                      !props?.permissions?.PRAGMATIC_LIVECASINO_ACCESS?.Value
                    ) {
                      return false;
                    } else if (
                      x.value == "668" &&
                      !props?.permissions?.DETROIT_ABSOLUTE_LIVECASINO_ACCESS
                        ?.Value
                    ) {
                      return false;
                    } else if (
                      x.value == "791" &&
                      !props?.permissions?.DETROIT_EVOLUTION_LIVECASINO_ACCESS
                        ?.Value
                    ) {
                      return false;
                    } else if (
                      x.value == "75" &&
                      !props?.permissions?.DETROIT_EZUGI_LIVECASINO_ACCESS
                        ?.Value
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  })}
                />
              </section>
            ) : (
              <section
                className="py-2 mobile-hide"
                // key={updatedListKey}
              >
                <ProvidersSwiper
                  provider={provider}
                  _data={[
                    {
                      name: "live/537.png",
                      value: "537",
                    },
                    {
                      name: "live/668.png",
                      value: "668",
                    },
                    {
                      name: "live/480.png",
                      value: "791",
                    },
                    {
                      name: "live/19.png",
                      value: "75",
                    },
                    ...[
                      ...GameProviders.filter((x) => {
                        if (
                          x.value == "668" &&
                          !props?.permissions
                            ?.DETROIT_ABSOLUTE_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "791" &&
                          !props?.permissions
                            ?.DETROIT_EVOLUTION_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "75" &&
                          !props?.permissions?.DETROIT_EZUGI_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WorldMatch" &&
                          !props?.permissions?.WM?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WorldMatch" &&
                          !props?.permissions?.WM?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "pragmatic-casino" &&
                          !props?.permissions?.PRAGMATIC_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "amatic" &&
                          !props?.permissions?.AMATIC_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "novomatic" &&
                          !props?.permissions?.NOVOMATIC_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "bomba" &&
                          !props?.permissions?.BOMBA_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "egt" &&
                          !props?.permissions?.EGT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "netent" &&
                          !props?.permissions?.NETENT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "yggdrasil" &&
                          !props?.permissions?.YGGDRASIL_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "playngo" &&
                          !props?.permissions?.PLAYNGO_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "aristocrat" &&
                          !props?.permissions?.ARISTOCRAT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "redtiger" &&
                          !props?.permissions?.REDTIGER_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "playtech" &&
                          !props?.permissions?.PLAYTECH_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "1X2_GAMING" &&
                          !props?.permissions?.X12_GAMING_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "EVOPLAY" &&
                          !props?.permissions?.EVOPLAY_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "IGT" &&
                          !props?.permissions?.IGT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "PLAYSON" &&
                          !props?.permissions?.PLAYSON_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "BOOONGO" &&
                          !props?.permissions?.BOOONGO_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WAZDAN" &&
                          !props?.permissions?.WAZDAN_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "RUBYPLAY" &&
                          !props?.permissions?.RUBYPLAY_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "MICROGAMING" &&
                          !props?.permissions?.MICROGAMING_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "BETSOFT" &&
                          !props?.permissions?.BETSOFT_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "OCTAVIAN" &&
                          !props?.permissions?.OCTAVIAN_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "SCIENTIFIC_GAMES" &&
                          !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "Evolution-fundist" &&
                          !props?.permissions?.WM_EVOLUTION_CASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "Ezugi-fundist" &&
                          !props?.permissions?.WM_EZUGI_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "Atmosfera-fundist" &&
                          !props?.permissions?.WM_ATMOSFERA_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "VivoGaming-fundist" &&
                          !props?.permissions?.WM_VIVO_LIVECASINO_ACCESS?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "WMCasino-fundist" &&
                          !props?.permissions?.WM_WMCASINO_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "XProGaming-fundist" &&
                          !props?.permissions?.WM_XPROGAMING_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "LuckyStreak-fundist" &&
                          !props?.permissions?.WM_LUCKYSTREAK_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "AESexyBaccarat-fundist" &&
                          !props?.permissions?.WM_AESEXY_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else if (
                          x.value == "LiveGames-fundist" &&
                          !props?.permissions?.WM_LIVEGAMES_LIVECASINO_ACCESS
                            ?.Value
                        ) {
                          return false;
                        } else return true;
                      }),
                    ].filter((x) => games && games.hasOwnProperty(x.value)),
                  ].filter((x) => {
                    //debugger;
                    if (
                      x.value == "537" &&
                      !props?.permissions?.PRAGMATIC_LIVECASINO_ACCESS?.Value
                    ) {
                      return false;
                    } else if (
                      x.value == "668" &&
                      !props?.permissions?.DETROIT_ABSOLUTE_LIVECASINO_ACCESS
                        ?.Value
                    ) {
                      return false;
                    } else if (
                      x.value == "791" &&
                      !props?.permissions?.DETROIT_EVOLUTION_LIVECASINO_ACCESS
                        ?.Value
                    ) {
                      return false;
                    } else if (
                      x.value == "75" &&
                      !props?.permissions?.DETROIT_EZUGI_LIVECASINO_ACCESS
                        ?.Value
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  })}
                />
              </section>
            )}

            {/*
            <section className="mb-1">
              <FilterTypes
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
              />
            </section>
            */}

            {!isDirectLobby && params.ProviderId == "537" && (
              <section className="pb-4">
                <CategoryType
                  acitveMenu={acitveMenu}
                  setActiveMenu={(value) => {
                    if (value == "new") {
                      const new_data = props.data.sort(function (a, b) {
                        return b.gameIdNumeric - a.gameIdNumeric;
                      });
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    } else if (value == "hot") {
                      setData(props.data);
                      const _v = props.data.slice(0, 36);
                      setVisibleData(_v);
                    } else {
                      const new_data = props.data.filter((x) =>
                        x.gameName.toLowerCase().includes(value.toLowerCase())
                      );
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    }
                    setActiveMenu(value);
                  }}
                />
              </section>
            )}

            {!isDirectLobby && params?.ProviderId?.includes("-fundist") && (
              <section className="pb-4">
                <CategoryType
                  acitveMenu={acitveMenu}
                  setActiveMenu={(value) => {
                    if (value == "new") {
                      const new_data = props.data.sort(function (a, b) {
                        return b.gameIdNumeric - a.gameIdNumeric;
                      });
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    } else if (value == "hot") {
                      setData(props.data);
                      const _v = props.data.slice(0, 36);
                      setVisibleData(_v);
                    } else {
                      debugger;
                      const new_data = props.data.filter((x) =>
                        x?.Trans?.en
                          ?.toLowerCase()
                          ?.includes(value.toLowerCase())
                      );
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    }
                    setActiveMenu(value);
                  }}
                />
              </section>
            )}

            {isDirectLobby && (
              <section className="pb-4">
                <CategoryType
                  acitveMenu={acitveMenu}
                  setActiveMenu={(value) => {
                    if (value == "new") {
                      const new_data = props.data.sort(function (a, b) {
                        return b.gameIdNumeric - a.gameIdNumeric;
                      });
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    } else if (value == "hot") {
                      setData(props.data);
                      const _v = props.data.slice(0, 36);
                      setVisibleData(_v);
                    } else {
                      const new_data = props.data.filter((x) =>
                        x.name.toLowerCase().includes(value.toLowerCase())
                      );
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    }
                    setActiveMenu(value);
                  }}
                />
              </section>
            )}

            <div className="px-1 grid grid-cols-3   sm:grid-cols-4  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7  2xl:grid-cols-8 gap-2 md:gap-4">
              {visibleData &&
                visibleData.map((item, index) => {
                  const _params = params;
                  switch (
                    provider
                      ? provider
                      : _params
                      ? _params.ProviderId
                      : provider
                  ) {
                    case "537":
                      return (
                        <PragmaticGameBox
                          key={item.gameIdNumeric + "-" + index}
                          isLive={true}
                          index={index}
                          item={item}
                        />
                      );
                    case "smartsoft":
                      return (
                        <SmartSoftGameBox
                          key={index}
                          index={index}
                          item={item}
                        />
                      );
                    case "WorldMatch":
                      return (
                        <WMGameBox
                          key={index}
                          isLive={false}
                          index={index}
                          item={item}
                        />
                      );
                    case "amatic":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "novomatic":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "bomba":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "egt":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "netent":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "yggdrasil":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "playngo":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "merkur":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "aristocrat":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "redtiger":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "playtech":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                      break;
                    case "iconix":
                      return (
                        <TimelessTechGameBoxLive
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                      break;

                    default:
                      if (
                        (provider
                          ? provider
                          : _params
                          ? _params.ProviderId
                          : ""
                        ).includes("timeless")
                      ) {
                        return (
                          <TimelessTechGameBox
                            key={index}
                            index={index}
                            item={item}
                            provider={
                              provider
                                ? provider
                                : _params
                                ? _params.ProviderId
                                : ""
                            }
                          />
                        );
                      } else if (
                        (provider
                          ? provider
                          : _params
                          ? _params.ProviderId
                          : ""
                        ).includes("-fundist")
                      ) {
                        return (
                          <WMGameBox
                            key={index}
                            isLive={true}
                            index={index}
                            item={item}
                            provider={
                              provider
                                ? provider
                                : _params
                                ? _params.ProviderId
                                : ""
                            }
                          />
                        );
                      } else {
                        return (
                          <LiveGameBox
                            index={index}
                            item={item}
                            provider={
                              provider
                                ? provider
                                : _params
                                ? _params.ProviderId
                                : ""
                            }
                          />
                        );
                      }

                      break;
                  }
                })}
              {loading && <Loader backdrop />}
            </div>
          </div>
          {fabActive && (
            <button
              className="btn btn-primary text-white btn-circle fixed right-5 bottom-20 shadow-xl fade-in"
              onClick={() => {
                const scl = containerRef.current;
                scl.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                />
              </svg>
            </button>
          )}

          <div className=" mobile-show">
            <div
              style={{ visibility: isNavVisible ? "visible" : "hidden" }}
              className="btm-nav  border-t border-primary2-500 bg-gradient-to-r from-primary2-700 via-primary2-600 to-primary2-700 drop-shadow-2xl shadow-2xl"
            >
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/sports"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_SPORTS")}
                    </span>
                  </div>
                </Link>
              )}
              <Link
                to={"/casino"}
                className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <MdOutlineCasino className="w-5 h-5" />
                  <span className="btm-nav-label text-xs">
                    {t("TITLE_CASINO")}
                  </span>
                </div>
              </Link>
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/my-account/coupons"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_MYBETS")}
                    </span>
                  </div>
                </Link>
              )}

              {props.permissions?.LIVECASINO_ACCESS?.Value && (
                <Link
                  to={"/live-casino"}
                  className="text-white max-w-[5rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <PiCoinsBold className="w-5 h-5" />
                    <span className="btm-nav-label text-xs">
                      {t("TITLE_LIVECASINO")}
                    </span>
                  </div>
                </Link>
              )}

              <Link
                to={"/my-account/profile/"}
                className="text-white max-w-[5rem] bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>

                  <span className="btm-nav-label text-xs">
                    {t("TITLE_MYACCOUNT")}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrollableListView);

export const GetGamePoster = (cat, data) => {
  switch (cat) {
    case "pragmatic-casino":
      return `https://common-static.ppgames.net/game_pic/square/200/${data.gameID}.png`;
    case "smartsoft":
      return `https://maxbetone.s3.eu-central-1.amazonaws.com/images/smartsoft/${data.GameName}.png`.toLowerCase();
    default:
      break;
  }
};

export const GameProviders = [
  
 
];



/*
{
    name: "evolution_gaming.png",
    value: "Evolution-fundist",
  },
   {
    name: "ezugi.png",
    value: "Ezugi-fundist",
  },
  {
    name: "Atmosfera.png",
    value: "Atmosfera-fundist",
  },
  {
    name: "timeless/VivoGaming_Gold_Landscape.png",
    value: "VivoGaming-fundist",
  },

  {
    name: "fundist/wm_logo-440x155.png",
    value: "WMCasino-fundist",
  },

  {
    name: "fundist/XPG-transparent.png",
    value: "XProGaming-fundist",
  },
  {
    name: "fundist/lucky-streak.png",
    value: "LuckyStreak-fundist",
  },
  {
    name: "aesexy.png",
    value: "AESexyBaccarat-fundist",
  },

  {
    name: "LiveGames_logo_white.png",
    value: "LiveGames-fundist",
  },

*/

/*
WM

  {
    name: "evolution_gaming.png",
    value: "Evolution-fundist",
  },

*/

/*
Timeless

{
    name: "timeless/VivoGaming_Gold_Landscape.png",
    value: "vivo-timelesstech",
  },
  { name: "timeless/Ezugi_Logo_white.png", value: "ezugi-timelesstech" },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionNHS-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionVHS-timelesstech",
  },
  {
    name: "timeless/ImaginLive_logo_bw.png",
    value: "imagine live-timelesstech",
  },
  { name: "timeless/PopOk_Vector_02.png", value: "popok-live-timelesstech" },
  { name: "timeless/tv_bet_final_logo_w.png", value: "tvbet-timelesstech" },
  {
    name: "timeless/onetouch_white_live.png",
    value: "onetouch-live-timelesstech",
  },

  {
    name: "timeless/SkyWindGroup_Logo2021.png",
    value: "skywind-live-timelesstech",
  },
  {
    name: "timeless/SkyWindGroup_Logo2021.png",
    value: "skywind-bj-timelesstech",
  },
  { name: "timeless/Absolute_logo_w_2.png", value: "absolute-timelesstech" },
  { name: "timeless/7mojos_logo.png", value: "7-mojos-timelesstech" },
  { name: "timeless/Religa_White.png", value: "religa-timelesstech" },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionM-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolution-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionHS-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionRNG-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionX-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionBJ-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionZ-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionY-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionLS-timelesstech",
  },
  {
    name: "timeless/evolution_logo_online_white_1000x194.png",
    value: "evolutionXLS-timelesstech",
  },
  { name: "timeless/Ezugi_Logo_white.png", value: "ezugiX-timelesstech" },
  { name: "timeless/Ezugi_Logo_white.png", value: "ezugiZ-timelesstech" },

*/

/*
  { name: "timeless/PP_Logo_On.png", value: "pragmatic-live-timelesstech" },
  { name: "timeless/PP_Logo_On.png", value: "pragmatic-bj-timelesstech" },

*/
/**
 
VİRTUAL E GEÇTİ TAMAMEN

 {
    name: "virtual.png",
    value: "VIRTUAL",
  },
    {
    name: "racing.png",
    value: "RACING",
  },

 */

/*
KALDIRILAN SAĞLAYICILAR

{
    {
    name: "merkur.svg",
    value: "merkur",
  },
   {
    name: "booming_games.png",
    value: "BOOMING_GAMES",
  },

    {
    name: "big_time_gaming.png",
    value: "BIG_TIME_GAMING",
  },
    {
    name: "leap.png",
    value: "LEAP",
  },
    name: "gaminator.png",
    value: "GAMINATOR",
  },
   {
    name: "platipus.png",
    value: "PLATIPUS",
  },
   {
    name: "merkur.png",
    value: "MERKUR",
  },
  {
    name: "red_rake.png",
    value: "RED_RAKE",
  },
  {
    name: "apex.png",
    value: "APEX",
  }
   {
    name: "quickspin.png",
    value: "QUICKSPIN",
  }, 
 
  {
    name: "ainsworth.png",
    value: "AINSWORTH",
  },
  {
    name: "firekirin.png",
    value: "FIREKIRIN",
  },
  {
    name: "apollo_games.png",
    value: "APOLLO_GAMES",
  },
  {
    name: "kajot.png",
    value: "KAJOT",
  },
  {
    name: "gameart.png",
    value: "GAMEART",
  },{
    name: "habanero.png",
    value: "HABANERO",
  },  {
    name: "isoftbet.png",
    value: "ISOFTBET",
  }, 
   {
    name: "skywind.png",
    value: "SKYWIND",
  },
    
   {
    name: "spinomenal.png",
    value: "SPINOMENAL",
  },
 {
    name: "iron_dog.png",
    value: "IRON_DOG",
  },
 {
    name: "igrosoft.png",
    value: "IGROSOFT",
  },*/

/*  {
    name: "xplosive.png",
    value: "XPLOSIVE",
  },
{
    name: "zeus_play.png",
    value: "ZEUS_PLAY",
  }
  */

/*
GAMES TARAFINA TRANSFER EDİLDİ

 {
    name: "super_keno.png",
    value: "SUPER_KENO",
  },
  {
    name: "spribe.png",
    value: "SPRIBE",
  },

*/
