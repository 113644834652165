import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Notification, useToaster } from "rsuite";
import { Tag, IconButton } from "rsuite";
import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";
import Table from "rc-table";
import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi2";

import ChildUserTableX from "./ChildUserTable";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import UserDetail from "components/Modals/UserDetail";

const columns = [
  {
    title: "User",
    dataIndex: "Item", //["RelUser", "UserID"],
    className: "text-xs",
    key: "UserID",
    width: 160,
    render: (value) => <UserDetail data={value} />,
  },
  {
    title: "Balance",
    dataIndex: "Balance",
    className: "text-xs hidden sm:table-cell",
    key: "Balance",
    width: 120,
    render: (value) => <span>-</span>,
  },

  {
    title: "Count",
    dataIndex: "Count",
    className: "text-xs hidden sm:table-cell",
    key: "Count",
    width: 50,
  },
  {
    title: "",
    dataIndex: "Item",
    className: "text-right",
    key: "d",
  },
];

const ChildUserTable = ({ rowData, childs }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const onExpand = (expanded, record) => {
    // eslint-disable-next-line no-console
    //console.log("onExpand", expanded, record);
  };
  function CustomExpandIcon(props) {
    return props.record.ChildDetails.length > 0 ? (
      <div
      className={ (props.record.User.UserType.TypeID == 1
        ? "bg-master-500  text-master-50"
        : props.record.User.UserType.TypeID == 2
        ? "bg-superadmin-500 text-superadmin-50"
        : props.record.User.UserType.TypeID == 3
        ? "bg-admin-500 text-admin-900"
        : props.record.User.UserType.TypeID == 4
        ? "bg-partner-500 text-partner-900"
        : "bg-gamer-500  text-gamer-800")+ ` btn   ${
        props.expanded ? "" : "btn-outline"
      } btn-sm btn-square` }
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        // eslint-disable-next-line react/no-danger
      >
        {props.expanded ? <HiOutlineUserGroup /> : <HiUserGroup />}
      </div>
    ) : (
      <></>
    );
  }
  function countAllChildren(node) {
    let totalCount = 0;

    if (node.Childs && node.Childs.length > 0) {
      node.Childs.forEach((childNode) => {
        totalCount += 1 + countAllChildren(childNode); // Toplam, kendisi dahil alt düğümlerinin sayısını hesaplar
      });
    }

    return totalCount;
  }

  return childs ? (
    <div className="py-1 bg-secondary-500 rounded-sm">
      <div className="pb-2 px-2 border-b border-secondary-400/30">
        <label className="bold">{`${rowData.User.UserName} - (${rowData.User.UserType.Value})`}</label>
      </div>
      <Table
        className="table table-zebra table-sm "
        columns={columns}
        rowClassName={(record, i) =>
          `${record.ChildDetails.length > 0 ? "" : "RemoveSubRow"} row-${i}`
        }
        expandable={{
          // expandRowByClick: true,
          expandedRowRender: (record) => {
            if (record.ChildDetails.length > 0)
              return (
                <div className="p-2 bg-secondary-800 rounded-sm m-0">
                  <ChildUserTableX
                    childs={record.ChildDetails}
                    rowData={record}
                  />
                </div>
              );
            else {
              return undefined;
            }
          },
          onExpand,
          expandIcon: CustomExpandIcon,
        }}
        data={
          childs
            ? [
                ...childs.map((item) => ({
                  ...item,
                  key: item.User._id,
                  Name: `${item.User.UserName} (#${item.User.UserID})`,
                  Item: item,
                  Count: countAllChildren(item),
                })),
              ]
            : []
        }
      />
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChildUserTable);
