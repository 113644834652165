import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { BiTransferAlt } from "react-icons/bi";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const TransferCard = (props) => {
  const { item, index, datauser,user } = props;

  const getOperationStatus = () => {
    switch (item.RelFinancialType.TypeID) {
      case 10:
        //Transfer Balance
        //(item.OperationUser == user.RelUser.UserName ? )
        return {
          Sender: item.Amount > 0 ? item.TargetUser : user.RelUser.UserName,
          Receiver: item.Amount > 0 ? user.RelUser.UserName : item.TargetUser,
        };

        break;
      case 1:
        //ADD BALANCE
        return {
          Sender: item.OperationUser,
          Receiver: user.RelUser.UserName,
        };

        break;
      case 5:
        //Check out
        return {
          Sender: item.OperationUser,
          Receiver: user.RelUser.UserName,
        };
        break;
      default:
        break;
    }
  };
  //item.OperationUser == props.user.RelUser.UserName
  const oUsers = getOperationStatus();
  debugger;
  return (
    <Translation>
      {(t) => (
        <div
          key={index}
          className=" bg-light-500 rounded-md mb-1 pb-4 flex flex-col justify-between p-2"
        >
          <div className=" flex flex-row justify-between items-start">
            <div>
              <p className="m-0 text-lg">{item?.RelCategory?.Name}</p>
              <div className="flex flex-col">
                <p className="m-0 text-white/90  font-medium">
                  {item.RelFinancialType.Value}
                </p>
                <p className="m-0   font-medium text-[10px] text-white/30">
                  {"(" + item.OperationUser + ")"}
                </p>
              </div>
            </div>
            <p className="text-white/30 text-xs text-right m-0 font-light">
              {moment(new Date(item.RecordTime)).format("lll")}
            </p>
          </div>
          <div></div>
          {
            //item.RelFinancialType.TypeID == 10
          }
          <span
            className={
              " text-right " +
              (true
                ? item.Amount > 0
                  ? "text-success"
                  : item.Amount < 0
                  ? "text-error"
                  : ""
                : "")
            }
          >
            {`${
              item.Amount
                ? item.Amount.toLocaleString("tr-TR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                : "0,00"
            } ${item.RelCurrency ? item.RelCurrency.Currency : ""}`}
          </span>
          <span className={" text-right text-[12px]"}>
            {`${
              item.CurrentBalance
                ? item.CurrentBalance.toLocaleString("tr-TR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                : "0,00"
            } ${item.RelCurrency ? item.RelCurrency.Currency : ""}`}
          </span>

          {item.OperationUser && (
            <div className="flex flex-row justify-start items-center mt-[-25px] ml-[4px]-">
              <div className="flex flex-col">
                <span className="text-white text-xs">{t("TXT_SENDER")}</span>
                <span className="text-white/40 text-xs">{oUsers.Sender}</span>
              </div>
              <MdKeyboardDoubleArrowRight className="text-xl mx-2 text-teal-500" />
              <div className="flex flex-col">
                <span className="text-white text-xs">{t("TXT_RECEIVER")}</span>
                <span className="text-white/40 text-xs">{oUsers.Receiver}</span>
              </div>
            </div>
          )}

          {/*
     <p className="font-thin text-[9px] text-secondary-400">{index}</p>
    */}
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TransferCard);
