import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Notification, useToaster } from "rsuite";
import { Tag, IconButton } from "rsuite";
import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";
import Table from "rc-table";

import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi2";
import { HiDocumentReport, HiOutlineDocumentReport } from "react-icons/hi";
import { IoGameController, IoGameControllerOutline } from "react-icons/io5";

import ChildFinansTableX from "./ChildFinansTable";
import ChildFinansReportTable from "./ChildFinansReportTable";
import UserDetail from "components/Modals/UserDetail";

const columns = [
  {
    title: "User",
    dataIndex: "InOut", //["RelUser", "UserID"],
    className: "text-xs ",
    key: "UserID",
    width: 160,
    render: (value) => <UserDetail data={value} />,

  },

  {
    title: "In - Out",
    dataIndex: "InOut",
    className: "text-xs table-cell sm:hidden",
    key: "InOut",
    width: 200,
    render: (value) => (
      <div className={`p-2 rounded-md text-[10px]`}>
        <div>
          <span>
            {`In : ${value.In.toLocaleString("tr-TR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`}
          </span>
        </div>

        <div>
          <span>
            {`Out : ${value.Out.toLocaleString("tr-TR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`}
          </span>
        </div>
      </div>
    ),
  },

  {
    title: "In",
    dataIndex: "In",
    className: "text-xs  hidden sm:table-cell",
    key: "In",
    width: 80,
    render: (value) => (
      <div className={`p-2 rounded-md `}>
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
  {
    title: "Out",
    dataIndex: "Out",
    className: "text-xs hidden sm:table-cell",
    key: "Out",
    width: 80,
    render: (value) => (
      <div className={`p-2 rounded-md `}>
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
  
  {
    title: "GGR",
    dataIndex: "GGR",
    className: "text-xs text-right",
    key: "GGR",
    width: 50,
    render: (value) => (
      <div
        className={`p-2 rounded-md  text-[10px] sm:text-[12px] md:text-xs  ${
          value > 0
            ? "bg-green-700"
            : value == "0"
            ? "bg-transparent"
            : "bg-red-700"
        }`}
      >
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
  
];

const ChildFinansTable = ({ rowData, childs }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const onExpand = (expanded, record) => {
    // eslint-disable-next-line no-console
    //console.log("onExpand", expanded, record);
  };
  function CustomExpandIcon(props) {
    // debugger;
 
    return (
      <div
        className={`btn btn-primary text-white ${
          props.expanded ? "" : "btn-outline"
        } btn-sm btn-square`}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        // eslint-disable-next-line react/no-danger
      >
        {props.expanded ? (
          props.record?.Reports?.length > 0 ? (
            <HiOutlineDocumentReport />
          ) : props.record.hasOwnProperty("Detail") ? (
            <IoGameControllerOutline />
          ) : (
            <HiOutlineUserGroup />
          )
        ) : props.record?.Reports?.length > 0 ? (
          <HiDocumentReport />
        ) : props.record.hasOwnProperty("Detail") ? (
          <IoGameController />
        ) : (
          <HiUserGroup />
        )}
      </div>
    );
  }

  return childs ? (
    <div className="py-1 bg-secondary-500 rounded-sm">
      {/*
        <div className="pb-2 px-2 border-b border-secondary-400/30">
        <label className="bold">{`${rowData.User.UserName} - (${rowData.RelUserType.Value})`}</label>
      </div>
      */}

      <Table
        className="table table-zebra "
        columns={columns}
        rowClassName={(record, i) =>
          `${record.ChildDetails.length > 0 ? "" : "RemoveSubRow"} row-${i}`
        }
        expandable={{
          // expandRowByClick: true,
          expandedRowRender: (record) => {
            if (record.ChildDetails.length > 0) {
              return (
                <div className="py-1 bg-secondary-800 rounded-sm m-0">
                  <ChildFinansTableX
                    childs={record.ChildDetails}
                    rowData={record}
                  />
                </div>
              );
            } else if (record.Reports.length > 0) {
              return (
                <ChildFinansReportTable
                  childs={record.Reports}
                  rowData={record}
                />
              );
            } else {
              return undefined;
            }
          },
          onExpand,
          expandIcon: CustomExpandIcon,
        }}
        data={
          childs
            ? [
                ...childs.map((item) => ({
                  ...item,
                  key: item.User._id,
                  Name: item.User, //`${item.User.UserName} (#${item.User.UserID})`,
                  InOut: item,

                })),
              ]
            : []
        }
      />
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChildFinansTable);
