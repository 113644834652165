import {
  //ServiceURLs,
  OAuth2ServiceEndPoints,
  OAuth2_MakeRequestAndGetResponse,
  OAuth2_MakeRequestAndGetResponseForRefreshToken,
  ClientData,
} from "./index";
import { HTTPOperationType, OAuth2_MakeRequestAndGetResponseLogin } from "./httpOperations";
import { GetBaseURL, GetReportBaseURL } from "./_serviceSettings";
import Emitter from "_actions/emitter";

export const OAuth2Service = {
  TEST,
  LOGIN,
  REFRESH_TOKEN,
};

async function REFRESH_TOKEN(refresh_token) {
  let js = {
    grant_type: "refresh_token",
    client_id: ClientData.ClientId,
    client_secret: ClientData.ClientSecret,
    refresh_token: refresh_token,
  };
  return OAuth2_MakeRequestAndGetResponseForRefreshToken(
    (await GetReportBaseURL()) + OAuth2ServiceEndPoints.REFRESH_TOKEN,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      localStorage.setItem("access_token", res.access_token);
      localStorage.setItem("refresh_token", res.refresh_token);
      let currenttime = new Date();
      let expires_in = currenttime.setSeconds(
        currenttime.getSeconds() + res.expires_in
      );
      //token bitis zamani.
      localStorage.setItem("expires_in", expires_in);
      localStorage.setItem("expires_in_currenttime", currenttime);

      return Promise.resolve(res);
    })
    .catch((err) => {
      Emitter.emit("LOGOUT", false);

      return Promise.reject(err);
    });
}

async function LOGIN(_username, _password, _rememberMe) {
  let js = {
    grant_type: "password",
    client_id: ClientData.ClientId,
    client_secret: ClientData.ClientSecret,
    username: _username,
    password: _password,
    redirect_uri: ClientData.Redirect_uri,
  };

  if (_rememberMe) {
    localStorage.setItem(
      "RememberMe",
      JSON.stringify({ UserName: _username, Password: _password })
    );
  }
  const aa = (await GetReportBaseURL()) + OAuth2ServiceEndPoints.TOKEN;

  return OAuth2_MakeRequestAndGetResponseLogin(aa, HTTPOperationType.post, js)
    .then((res) => {
      localStorage.setItem("access_token", res.access_token);
      localStorage.setItem("refresh_token", res.refresh_token);
      let currenttime = new Date();
      let expires_in = currenttime.setSeconds(
        currenttime.getSeconds() + res.expires_in
      );
      //token bitis zamani.
      localStorage.setItem("expires_in", expires_in);
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function TEST() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetReportBaseURL()) + OAuth2ServiceEndPoints.TEST,
    HTTPOperationType.post,
    {}
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
