import React, { useState } from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import FilterSearch from "./FilterSearch";
import { isBrowser, isMobile } from "react-device-detect";

const FilterTypes = (props) => {
  const { FilterSearchValue, setFilterSearchValue } = props;

  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  return (
    <SwipeableViews enableMouseEvents className={" rounded-xl "}>
      <div className=" flex overflow-x-auto items-center">
        <FilterSearch
          FilterSearchValue={FilterSearchValue}
          setFilterSearchValue={setFilterSearchValue}
        />
        {/*
  <div className="btn btn-primary btn-sm ml-0  text-white rounded-3xl">Jackpot</div>
        <div className="btn btn-light btn-sm ml-2  text-white rounded-3xl shadow-xl bg-light-500/60">Free Games</div>
        <div className="btn btn-base btn-sm ml-2  text-white rounded-3xl shadow-xl bg-light-500/60">Favories</div>
*/}
      </div>
    </SwipeableViews>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FilterTypes);
