import { authenticationAction } from "_actions";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import NavUserBox from "./NavUserBox";

const UserBox = (props) => {
  return (
    <Translation>
      {(t) => (
        <div className="border rounded-3xl p-1 border-secondary-800 bg-secondary-800 flex items-center">
          <NavUserBox />
        </div>
      )}
    </Translation>
  );
};
const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
