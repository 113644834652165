import { gameAction } from "_actions";
import { GameService } from "_services";
import i18n from "i18n";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Translation } from "react-i18next";
import { MdOutlineCasino } from "react-icons/md";
import { PiCoinsBold } from "react-icons/pi";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { GiFootTrip, GiGoalKeeper } from "react-icons/gi";
import { isAndroid, isMobile, isTablet } from "react-device-detect";

const Altenar = (props) => {
  const { user, loggedIn, games, UpdateSport, currentLang } = props;
  const [loading, setLoading] = useState(true);
  const [isError, setErrorStatus] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [updatedLang, setUpdateLang] = useState(currentLang);

  const location = useLocation();

  useEffect(() => {
    getAltenarToken();
    return () => {
      debugger;
      if (window.ASB) {
        debugger;
        window.ASB.remove();
      }
      if (window.WBS) {
        window.WBS.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (window.altenarWSDK && updatedLang != currentLang) {
      debugger;
      setUpdateLang(currentLang);
      window.altenarWSDK.set({
        culture: i18n.t("altenarC"),
      });
    }
  }, [currentLang]);

  const getAltenarToken = () => {
    setLoading(true);
    GameService.GET_ALTENARSPORTS_TOKEN()
      .then((res) => {
        setErrorStatus(false);
        debugger;
        UpdateSport(res.token);
        setTimeout(() => {
          initAltenar(res.token);
        }, 300);
        handleLoad();
      })
      .catch((err) => {
        debugger;
        setErrorStatus(true);
        setLoading(false);
      });
  };
  const containerRef = useRef(null);

  const handleLoad = async () => {
    await setTimeout(() => {
      setLoading(false);
      const aa = location;
      const bb = props;
    }, 1000);
  };
  const initAltenar = (token) => {
    //debugger;
    // const _altenarWSDK = window.altenarWSDK;
    if (window.altenarWSDK) {
      if (token != props.sports) {
        window.altenarWSDK.set({
          token: token,
          // culture: i18n.t("altenarC"),
        });
      }

      window.ASB = window.altenarWSDK.addSportsBook({
        props: {
          page: "overview",
          onRouteChange: (page) => {
            const scl = containerRef.current;
            scl.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          },
        },
        container: document.getElementById("container"),
      });
      adjustHeight();
    } else {
    }
  };

  const prepareCoupons = () => {
    window.ASB = window.altenarWSDK.addSportsBook({
      props: {
        page: "overview",

        onBetPlacement: document.getElementById("body"),
      },
      container: document.getElementById("container"),
    });
    adjustHeight();
    setLoading(false);
  };

  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const pickElement = document.getElementById("altenar-container");
      const windowHeight = window.innerHeight;

      if (pickElement) {
        pickElement.style.paddingTop = `${
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1) + 4
        }px`;
        pickElement.style.height = `${windowHeight}px`;
        /*
        pickElement.style.height = `${
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1)
        }px`;
       */
      }
    } catch (error) {}
  };
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

    if (scrollTop > lastScrollTop) {
      setIsNavVisible(false);
    } else {
      setIsNavVisible(true);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div
            // key={"altenarsb" + currentLang}
            onScroll={handleScroll}
            ref={containerRef}
            className={"p-2 "}
            style={{
              position: "relative",
              height: "100%",
              paddingBottom: true
                ? isMobile || isTablet || isAndroid || isTablet
                  ? "50px"
                  : "0px"
                : "0px",
            }}
            id={"altenar-container"}
          >
            <Helmet>
              <title>{t("TITLE_SPORTS")}</title>

              <style type="text/css">
                {`
               
      .base-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
      
      }
    
      #altenar-container{
    /* position: absolute; */
    overflow: auto;
    width: 100%;
    
      }
      
    `}
              </style>
            </Helmet>

            <div
              style={{ overflow: "hidden" }}
              ref={containerRef}
              id={"container"}
            ></div>
            <div style={{ background: "red" }} id={"container2"}></div>
            {true && (
              <div
                style={{ zIndex: 1, height: "64px", width: "100%" }}
                className=" mobile-show  "
              >
                <div
                  style={{ visibility: isNavVisible ? "visible" : "hidden" }}
                  className="btm-nav  border-t border-primary-500 bg-gradient-to-r from-primary2-700 via-primary2-600 to-primary2-700 drop-shadow-2xl shadow-2xl"
                >
                  {props.permissions?.SPORTS_ACCESS?.Value && (
                    <Link
                      to={"/sports"}
                      className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                          />
                        </svg>

                        <span className="btm-nav-label text-xs">
                          {t("TITLE_SPORTS")}
                        </span>
                      </div>
                    </Link>
                  )}
                  <Link
                    to={"/casino"}
                    className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                  >
                    <div className="flex flex-col justify-center items-center">
                      <MdOutlineCasino className="w-5 h-5" />
                      <span className="btm-nav-label text-xs">
                        {t("TITLE_CASINO")}
                      </span>
                    </div>
                  </Link>
                  {props.permissions?.SPORTS_ACCESS?.Value && (
                    <Link
                      to={"/my-account/coupons"}
                      className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                          />
                        </svg>

                        <span className="btm-nav-label text-xs">
                          {t("TITLE_MYBETS")}
                        </span>
                      </div>
                    </Link>
                  )}

                  {props.permissions?.LIVECASINO_ACCESS?.Value && (
                    <Link
                      to={"/live-casino"}
                      className="text-white max-w-[5rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <PiCoinsBold className="w-5 h-5" />
                        <span className="btm-nav-label text-xs">
                          {t("TITLE_LIVECASINO")}
                        </span>
                      </div>
                    </Link>
                  )}

                  <Link
                    to={"/my-account/profile/"}
                    className="text-white max-w-[5rem] bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                  >
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        />
                      </svg>

                      <span className="btm-nav-label text-xs">
                        {t("TITLE_MYACCOUNT")}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
          {isError && (
            <div className="bg-secondary-800/80 h-screen w-screen  absolute top-0">
              <div className="h-screen w-screen flex flex-col justify-center items-center absolute top-0">
                <GiGoalKeeper className="text-white text-[10em]" />
                <img
                  width={200}
                  height={200}
                  src={require("../../../assets/img/textlogo2.png")}
                />
              </div>
            </div>
          )}
          {loading && (
            <div className="bg-secondary-800/80 h-screen w-screen  absolute top-0">
              <div className="flex flex-col mt-2 opacity-40 mobile-show">
                <div className="flex mb-2 justify-between">
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                </div>
                <div className="skeleton w-[100vw] h-[14rem] mb-2" />
                <div className="flex mb-2 justify-between">
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                  <div className="skeleton w-[4rem] h-[4rem]" />
                </div>
                <div className="skeleton w-[100vw] h-[14rem]" />
              </div>
              <div className="h-screen w-screen flex flex-col justify-center items-center absolute top-0">
                <img
                  width={200}
                  height={200}
                  src={require("../../../assets/img/textlogo2.png")}
                />
                <span className="mt-6 loading loading-spinner loading-md"></span>
              </div>
            </div>
          )}
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Altenar);

//SportsBookRouteContentstyled__RouteWrapper-sc-14bvh30-0 bhtiyb
