import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView from "../ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ServiceURLs } from "_services";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "_services";
import Emitter from "_actions/emitter";
import { useNavigate } from "react-router-dom";

const SmartSoft = (props) => {
  const { user, loggedIn, sports, games, UpdateGames, demo } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const [uKey, setUKey] = useState(uuidv4());
  const navigate = useNavigate();

  useEffect(() => {
    Emitter.on("ACTIVE_TAB", (newValue) => {
      const _uKey = uKey;
      if (
        uKey &&
        newValue &&
        newValue.uniqToken &&
        newValue.uniqToken != _uKey
      ) {
        navigate("/");
      }
      debugger;
    });
    return () => {
      Emitter.off("ACTIVE_TAB");
    };
  }, []);

  const setActiveGame = () => {
    const _k = uKey;
    debugger;
    UserService.UPDATE_ACTIVEGAME("Smartsoft-" + params.GameId, _k)
      .then((res) => {
        setLoading(false);
        debugger;
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function GetParams() {
    let params = useParams();
    return params;
  }
  let params = GetParams();

  useEffect(() => {
    if (params && params.GameId && props.games) {
      const isMobile = width <= 768;

      const g = props.games;
      const _data = g["smartsoft"].find((x) => x._id == params.GameId);
      if (!demo) {
        if (_data) {
          getGameDetail(
            _data.GameName,
            isMobile ? _data.MobileCategory : _data.DesktopCategory
          );
        } else {
          alert("Oyun bulunamadı !");
        }
      } else {
        if (_data) {
          const _url = `https://eu-staging.ssgportal.com/GameLauncher/Loader.aspx?GameCategory=${
            isMobile ? _data.MobileCategory : _data.DesktopCategory
          }&GameName=${_data.GameName}&Token=DEMO&PortalName=demo&ReturnUrl=${
            ServiceURLs.WEB_URL
          }`;
          debugger;
          setData(_url);
        } else {
          alert("Oyun bulunamadı !");
        }
      }
    }
    if (params && params.GameId) {
      setActiveGame();
    }
  }, []);
  useEffect(() => {
    if (params && params.GameId && props.games) {
      const isMobile = width <= 768;

      const g = props.games;
      const _data = g["smartsoft"].find((x) => x._id == params.GameId);
      if (!demo) {
        if (_data) {
          getGameDetail(
            _data.GameName,
            isMobile ? _data.MobileCategory : _data.DesktopCategory
          );
        } else {
          alert("Oyun bulunamadı !");
        }
      } else {
        if (_data) {
          const _url = `https://eu-staging.ssgportal.com/GameLauncher/Loader.aspx?GameCategory=${
            isMobile ? _data.MobileCategory : _data.DesktopCategory
          }&GameName=${_data.GameName}&Token=DEMO&PortalName=demo&ReturnUrl=${
            ServiceURLs.WEB_URL
          }`;
          debugger;
          setData(_url);
        } else {
          alert("Oyun bulunamadı !");
        }
      }
    }
  }, [games]);

  let startget = false;
  const getGameDetail = async (GameId, GameCategory) => {
    if (!startget) {
      startget = true;
      setLoading(true);

      await GameService.GET_SMARTSOFT_DETAIL(
        GameId,
        GameCategory,
        props.currentLang
      )
        .then((res) => {
          setLoading(false);
          setData(res.gameurl);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_CASINO")}</title>
            <style type="text/css">
              {`
      .base-nav {
        display: none !important;
        
      }
      .base-nav1 {
        display: none !important;
        
      }
      body, html , #root{
        height: 100%;
        margin: 0;
        overflow: hidden;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
      
    `}
            </style>
          </Helmet>
          {data && <iframe src={data} allowfullscreen></iframe>}
        </>
      )}
    </Translation>
  );
};
// height: "calc(100vh - 72px)",
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartSoft);
