import React from "react";
import SwiteMobileItem from "./SwiteMobileItem";
export default function SwiteGridItem({ data, provider, columns }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns ? columns : 4}, 1fr)`,
        // gridTemplateRows: "repeat(4, auto)"
      }}
      className="gap-1 h-full"
    >
      {data.map((item, key) => {
        const _r = require("../../../assets/img/catalog/" + item.name);
        return (
          <SwiteMobileItem
            className={"btn btn-neutral  ] "}
            provider={provider}
            key={key}
            item={_r}
            data={item}
          />
        );
      })}
    </div>
  );
}
