import { authenticationAction } from "_actions";
import React, { useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

const NavUserBox = (props) => {
  const [showFull, setShowFull] = useState(false);
  const getCurrency = (value) => {
    switch (value) {
      case "EUR":
        return "€";
      case "USD":
        return "$";
      default:
        return value;
    }
  };
  return (
    <Translation>
      {(t) => (
        <span onClick={()=>setShowFull(!showFull)} className="btn-xs	text-white btn btn-ghost">
          {`${
            showFull
              ? props?.wallet?.balance
              : props?.wallet?.balance.toLocaleString("tr-TR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
          } ${getCurrency(props?.user?.Currency)}`}
        </span>
      )}
    </Translation>
  );
};
const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn ,wallet } = authentication;

  return {
    user,
    loggedIn,
    wallet
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavUserBox);
