 import React from "react";

export default function ForikFieldCheckBox({ formikField, title }) {
  return (
    <label className="form-control w-full max-w-xs flex flex-row items-center">
      {formikField}
      <span className="label-text ml-2">{title}</span>
    </label>
  );
}
