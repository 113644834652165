import { UserService } from "_services";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

import { Loader } from "rsuite";
import { SelectPicker, DateRangePicker, Stack, Accordion } from "rsuite";

import HistoryCard from "./HistoryCard";

import { predefinedRanges } from "./Coupons";
import useInfiniteScroll from "react-infinite-scroll-hook";
import TransferCard from "./TransferCard";

const AccountHistories = (props) => {
  const [loading, setLoading] = useState(false);
  const [histories, setHistories] = useState([]);

  const [Begin, setBegin] = useState(
    new Date(moment().startOf("day").add(1, "seconds"))
  );
  const [End, setEnd] = useState(
    new Date(moment().endOf("day").subtract(1, "seconds"))
  );
  const [TypeValue, setTypeValue] = useState(null);

  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(true);

  const [FilterSearchValue, setFilterSearchValue] = useState("");
  const [DateValue, setDateValue] = useState([
    new Date(moment().startOf("day").add(1, "seconds")),
    new Date(moment().endOf("day").subtract(1, "seconds")),
  ]);

  const [updatedTime, setUpdatedTime] = useState(new Date().toJSON());
  const containerRef = useRef(null);

  useEffect(() => {
    // GetHistories();
  }, []);
  useEffect(() => {
    GetHistories();
  }, [props.user]);

  const GetHistories = (page = 1, begin, end) => {
    if (!loading) {
      setLoading(true);
      UserService.GET_MY_ACCOUNT_HISTORY(page, begin, end)
        .then((res) => {
          adjustHeight();

          setLoading(false);
          setHistories(res);
          setUpdatedTime(new Date().toJSON());
          const _v = res.slice(0, 36);
          setVisibleData(_v);
          setHasNextPage(true);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  const GetHistoriesMore = () => {
    if (!loading) {
      setLoading(true);
      UserService.GET_MY_ACCOUNT_HISTORY(page + 1, Begin.toJSON(), End.toJSON())
        .then((res) => {
          setLoading(false);
          setPage(page + 1);
          setHistories([...histories, ...res]);
          setHasNextPage(res.length > 0);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  const adjustHeight = () => {
    debugger;
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;
      const myaccountMenu =
        document.querySelector(".myaccountMenu")?.offsetHeight;
      debugger;
      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1) -
          myaccountMenu;

        debugger;
        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {
      debugger;
    }
  };

  const [visibleData, setVisibleData] = useState([]);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = () => {
    const data = [...histories].sort(
      (a, b) => new Date(b.__UpdateAt) - new Date(a.__UpdateAt)
    );
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight) {
      setVisibleData((prevData) => [
        ...prevData,
        ...data.slice(prevData.length, prevData.length + 20),
      ]);
      setUpdatedTime(new Date().toJSON());

      if (!loading && data.length > 0 && data.length == visibleData.length) {
        GetHistoriesMore();
      }
    }

    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: GetHistoriesMore,
    //disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Translation>
      {(t) => (
        <div className="h-full p-2">
          <div>
            <h1 className="text-lg px-2 py-2">{t("NAV_TITLE_HISTORY")}</h1>
          </div>

          <div
            ref={containerRef}
            // onScroll={handleScroll}
            style={{ overflowY: "scroll", paddingBottom: "180px" }}
          >
            <div className="pb-2">
              <Stack spacing={6} className="p-1">
                <Stack.Item grow={1}>
                  <DateRangePicker
                    className={"block"}
                    value={DateValue}
                    ranges={predefinedRanges}
                    showOneCalendar
                    placeholder={t("TITLE_BEGIN_END_DATES")}
                    //  defaultValue={[new Date(),new Date()]}
                    onShortcutClick={(shortcut, event) => {
                      // console.log(shortcut);
                    }}
                    onChange={(value) => {
                      if (value && value.length > 1) {
                        const begin = new Date(
                          moment(value[0]).startOf("day").add(1, "seconds")
                        );
                        const end = new Date(
                          moment(value[1]).endOf("day").subtract(1, "seconds")
                        );
                        setDateValue(value);
                        // setTypeValue(null);
                        setFilterSearchValue("");
                        setBegin(begin);
                        setEnd(end);
                        GetHistories(1, begin.toJSON(), end.toJSON());
                        //getListData(begin.toJSON(), end.toJSON());
                      } else {
                        setBegin(null);
                        setEnd(null);
                        setDateValue(null);
                      }
                    }}
                  />
                </Stack.Item>
              </Stack>
            </div>

            {!(
              [...histories].sort(
                (a, b) => new Date(b.__UpdateAt) - new Date(a.__UpdateAt)
              ).length > 0
            ) && (
              <div className="flex flex-col justify-center items-center p-2">
                <span className="italic text-secondary-400">
                  {t("PLACEHOLDER_NO_DATA_HISTORIES")}
                </span>
              </div>
            )}
            {histories.length > 0 && (
              <div
                className="px-1 grid grid-cols-1
               gap-2 md:gap-4"
              >
                {histories.map((item, index) => {
                  return <TransferCard item={item} index={index} key={index} />;
                })}
                {(loading || hasNextPage) && (
                  <div className="w-full flex justify-center" ref={sentryRef}>
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </div>

          {loading && <Loader backdrop center />}
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHistories);
