import React from "react";

export default function PageBanner({ title, subTitle, bg, show }) {
  return (
    <div className={" p-0 pb-0 " + (show ? "" : "mobile-hide")}>
      <div
        className=" absolute h-full z-0  w-full  bg-cover bg-top bg-no-repeat  items-end md:items-center flex py-1 px-4"
        style={{
          backgroundImage: `url(${bg})`,
          opacity:.1
        }}
        alt="daisyUI store"
      >
        <span
          style={{
            textShadow: "rgb(55 93 43 / 80%) 2px 2px",
          }}
          className="  text-[2.7em] "
        >
          {" "}
         
        </span>
      </div>
    </div>
  );
}
