import i18n from "i18n";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Notification, useToaster } from "rsuite";
import { Formik, Form, Field } from "formik";
import ForikField from "components/ForikField";
import ForikFieldCheckBox from "components/ForikFieldCheckBox";
import { PartnerService } from "_services";

const CreateGamer = (props) => {
  const [loading, setLoading] = React.useState(false);
  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };
  const SignupSchema = Yup.object().shape({
    User: Yup.array(
      Yup.object({
        UserName: Yup.string().required(),
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        Email: Yup.string().email(i18n.t("ERR_INVALID_EMAIL")).required(),
      })
    ).required("Required"),
  });

  const handleSubmit = async (formValue, actions) => {
    setLoading(true);
    actions.setSubmitting(true);
    await PartnerService.CREATE_GAMER(formValue)
      .then((res) => {
        actions.resetForm();
        toaster.push(
          message(i18n.t("MESSAGE_CREATE_GAMER_SUCCESS"), "success"),
          {
            duration: 2000,
          }
        );
        actions.setSubmitting(false);
      })
      .catch((err) => {
        debugger;
        if (err) {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        } else {
          toaster.push(message(i18n.t("MESSAGE_CREATE_GAMER_FAILD"), "error"), {
            duration: 2000,
          });
        }
        actions.setSubmitting(false);
      });
  };

  return (
    <Translation>
      {(t) => (
        <div>
          <div className="border-b py-3 border-primary-500 mb-5 shadow-xl">
            <label className="text-lg text-white">
              {t("TITLE_CREATE_GAMER")}
            </label>
          </div>
          <Formik
            initialValues={{
              Settings: [
                {
                  Cashout: false,
                  IsCasinoVisible: false,
                  IsLiveActive: true,
                  IsLiveCasinoVisible: true,
                },
              ],
              User: [
                {
                  UserName: "",
                  FirstName: "",
                  LastName: "",
                  Email: "",
                  Password: "",
                  RePassword: "",
                },
              ],
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, actions) => {
              console.log(values);
              debugger;
              if (values["User"][0]["UserName"].length < 2) {
                toaster.push(message(i18n.t("ERR_REQUIRED_FORM"), "error"), {
                  duration: 2000,
                });
              } else {
                handleSubmit(values, actions);
              }
            }}
          >
            {({ errors, touched, validateField, validateForm }) => (
              <Form className="flex flex-col ">
                <div className="grid  grid-cols-1 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-2 2xl:grid-cols-1 grid-flow-row gap-4">
                  <ForikField
                    title={t("LABEL_USERNAME")}
                    required={true}
                    formikField={
                      <Field
                        className={"input hover:outline-primary-500"}
                        name="User[0].UserName"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_FIRSTNAME")}
                    required={true}
                    formikField={
                      <Field
                        className={"input hover:outline-primary-500"}
                        name="User[0].FirstName"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_LASTNAME")}
                    required={true}
                    formikField={
                      <Field
                        className={"input hover:outline-primary-500"}
                        name="User[0].LastName"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_EMAIL")}
                    required={true}
                    formikField={
                      <Field
                        type="email"
                        className={"input hover:outline-primary-500"}
                        name="User[0].Email"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_PASSWORD")}
                    formikField={
                      <Field
                        type="password"
                        className={"input hover:outline-primary-500"}
                        name="User[0].Password"
                      />
                    }
                  />
                  <ForikField
                    title={t("LABEL_REPASSWORD")}
                    formikField={
                      <Field
                        type="password"
                        className={"input hover:outline-primary-500"}
                        name="User[0].RePassword"
                      />
                    }
                  />
                </div>
                <div className="grid   lg:grid-rows-1 md:grid-rows-2 sm:grid-rows-3 grid-rows-4   grid-flow-col gap-4 mt-6">
                  <ForikFieldCheckBox
                    title={t("LABEL_ISLIVEACTIVE")}
                    formikField={
                      <Field
                        type="checkbox"
                        className="checkbox"
                        name="Settings[0].IsLiveActive"
                      />
                    }
                  />

                  <ForikFieldCheckBox
                    title={t("LABEL_ISLCASINOVISIBLE")}
                    formikField={
                      <Field
                        type="checkbox"
                        className="checkbox"
                        name="Settings[0].IsCasinoVisible"
                      />
                    }
                  />

                  <ForikFieldCheckBox
                    title={t("LABEL_CASHOUT")}
                    formikField={
                      <Field
                        type="checkbox"
                        className="checkbox"
                        name="Settings[0].Cashout"
                      />
                    }
                  />
                  <ForikFieldCheckBox
                    title={t("LABEL_ISLIVECASINOVISIBLE")}
                    formikField={
                      <Field
                        type="checkbox"
                        className="checkbox"
                        name="Settings[0].IsLiveCasinoVisible"
                      />
                    }
                  />
                </div>
                <button
                  className="btn btn-primary text-white mt-10"
                  type="submit"
                  //</Form>onClick={() => {
                  /* const result = await validateForm();
                    const errors = Object.values(result);
                    if (errors.length > 0) {
                      console.log(result);
                    } else {
                      console.log("başarılı");
                    }
                    */
                  //  }}
                >
                  {t("BUTTON_CREATE")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGamer);
