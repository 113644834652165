import { UserEndPoints, OAuth2_MakeRequestAndGetResponse } from "./index";
import { HTTPOperationType, MakeReqestAndGetResponse } from "./httpOperations";
import { FinancialEndPoints, GetBaseURL, ROOTEndPoints } from "./_serviceSettings";
import Emitter from "_actions/emitter";

export const RootService = {
  CREATE_SUPERADMIN,
  READ_FINANCIAL,
  GET_FINANCIAL_CASH,
  GET_USER_HISTORY,
};

async function CREATE_SUPERADMIN(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + ROOTEndPoints.CREATE_SUPERADMIN,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function READ_FINANCIAL(begin, end) {
  let js = {
    Begin: "2023-01-28T12:38:39.114Z",
    End: new Date().toJSON(),
  };

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + FinancialEndPoints.GET_FINANCIAL,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_FINANCIAL_CASH(begin, end) {
  let js = {
    Begin: "2023-01-28T12:38:39.114Z",
    End: new Date().toJSON(),
  };
  return MakeReqestAndGetResponse(
    (await GetBaseURL()) + FinancialEndPoints.GET_FINANCIAL_CASH,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_USER_HISTORY(id, page = 1) {
  let path = `{"RelUser":"${id}"}?CountPerPage=100&CurrentPage=${page}`;

  return MakeReqestAndGetResponse(
    (await GetBaseURL()) + UserEndPoints.READ_CUSTOM_HISTORY + path,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
