import React, { useState } from "react";
import { GiPayMoney } from "react-icons/gi";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { Modal, Button, useToaster, Notification } from "rsuite";
import { InputNumber, InputGroup } from "rsuite";

import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";

export const AddBalance = (props) => {
  const toaster = useToaster();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleMinus = () => {
    setValue(parseInt(value, 10) - 1);
  };
  const handlePlus = () => {
    setValue(parseInt(value, 10) + 1);
  };

  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const handleSubmit = async () => {
    let formValue = {
      Currency: props.data.RelCurrency,
      Balance: value,
      RelUser: props.data.User,
    };

    setLoading(true);

    await PartnerService.AddBalanceAccount(formValue)
      .then((res) => {
        toaster.push(message(i18n.t("success"), "success"), {
          duration: 2000,
        });
        setLoading(false);
        setValue(0);
        setOpen(false);
      })
      .catch((err) => {
        debugger;
        if (err) {
          toaster.push(message(i18n.t("MESSAGE_LOGIN_FAILD"), "error"), {
            duration: 2000,
          });
        } else {
          toaster.push(
            message(i18n.t("ERROR_RESPONSE_ERROR_UNDEFINED"), "error"),
            { duration: 2000 }
          );
        }
        setLoading(false);
      });
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div
            className={`btn btn-success text-white btn-sm btn-square`}
            onClick={(e) => {
              setOpen(true);
            }}
            // eslint-disable-next-line react/no-danger
          >
            <FaPlus />
          </div>
          {open && (
            <Modal
              backdropClassName={"bg-secondary-500"}
              className="tableModal"
              backdrop={false}
              size={"xs"}
              open={open}
              onClose={() => setOpen(false)}
            >
              <Modal.Header>
                <Modal.Title>{t("BUTTON_ADD_BALANCE")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup>
                  <InputGroup.Button disabled={value < 1} onClick={handleMinus}>
                    -
                  </InputGroup.Button>
                  <InputNumber
                    min={0}
                    className={"custom-input-number"}
                    value={value}
                    onChange={setValue}
                  />
                  <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
                </InputGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setOpen(false)} appearance="subtle">
                  {t("BUTTON_CANCEL")}
                </Button>
                <Button
                  disabled={value < 1}
                  onClick={() => handleSubmit()}
                  appearance="primary"
                >
                  {t("BUTTON_SAVE")}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddBalance);
