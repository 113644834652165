import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView, { GameProviders } from "../ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { de } from "date-fns/locale";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import { detroidVirtualGames } from "BaseServiceURLs";
import { detroidGames, fundistGames } from "BaseServiceURLs";

/*

['TABLE_GAMES', 'SLOT', 'SCRATCH', 'VIRTUAL', 'INSTANT', 'CRASH', 'LOTTERY']
['CSGI', 'CSIM', 'ZEUS', 'CSGG', 'KNTC', 'VGRG', undefined] 
['MOBILE','DESKTOP']

*/

const Casino = (props) => {
  let params = GetParams();
  function GetParams() {
    let params = useParams();
    return params;
  }
  const { user, loggedIn, sports, games, allGames, UpdateGames } = props;
  const [loading, setLoading] = useState(false);
  const [currentProvider, setCurrentProvider] = useState("");
  const [FilterSearchValue, setFilterSearchValue] = useState("");

  const [GProviders, setGameProviders] = useState([]);

  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    if (props.permissionsAll.length > 0) {
      const gpList = [
        ...GameProviders.filter((x) => {
          if (x.value == "WorldMatch" && !props?.permissions?.WM?.Value) {
            return false;
          } else if (
            x.value == "pragmatic-casino" &&
            !props?.permissions?.PRAGMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "amatic" &&
            !props?.permissions?.AMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "novomatic" &&
            !props?.permissions?.NOVOMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "bomba" &&
            !props?.permissions?.BOMBA_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "egt" &&
            !props?.permissions?.EGT_CASINO_ACCESS?.Value
          ) {
            return false;
          }else if (
            x.value == "egtdgtl" &&
            !props?.permissions?.EGTDGTL_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "egtdgtl" &&
            !props?.permissions?.EGTDGTL_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "netent" &&
            !props?.permissions?.NETENT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "yggdrasil" &&
            !props?.permissions?.YGGDRASIL_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "playngo" &&
            !props?.permissions?.PLAYNGO_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "aristocrat" &&
            !props?.permissions?.ARISTOCRAT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "redtiger" &&
            !props?.permissions?.REDTIGER_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "playtech" &&
            !props?.permissions?.PLAYTECH_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "1X2_GAMING" &&
            !props?.permissions?.X12_GAMING_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "EVOPLAY" &&
            !props?.permissions?.EVOPLAY_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "IGT" &&
            !props?.permissions?.IGT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "PLAYSON" &&
            !props?.permissions?.PLAYSON_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "BOOONGO" &&
            !props?.permissions?.BOOONGO_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "WAZDAN" &&
            !props?.permissions?.WAZDAN_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "RUBYPLAY" &&
            !props?.permissions?.RUBYPLAY_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "MICROGAMING" &&
            !props?.permissions?.MICROGAMING_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "BETSOFT" &&
            !props?.permissions?.BETSOFT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "OCTAVIAN" &&
            !props?.permissions?.OCTAVIAN_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "SCIENTIFIC_GAMES" &&
            !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Evolution-fundist" &&
            !props?.permissions?.WM_EVOLUTION_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Evoplay-fundist" &&
            !props?.permissions?.WM_EVOPLAY_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "SmartSoft-fundist" &&
            !props?.permissions?.WM_SMARTSOFT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Tom Horn-fundist" &&
            !props?.permissions?.WM_TOMHORN_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "GameArt-fundist" &&
            !props?.permissions?.WM_GAMEART_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "AsiaGamingSlots-fundist" &&
            !props?.permissions?.WM_ASIAGAMINGSLOTS_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "BetSoft-fundist" &&
            !props?.permissions?.WM_BETSOFT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "BGaming-fundist" &&
            !props?.permissions?.WM_BGAMING_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Novomatic-fundist" &&
            !props?.permissions?.WM_NOVOMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Onlyplay-fundist" &&
            !props?.permissions?.WM_ONLYPLAY_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "3 Oaks Gaming-fundist" &&
            !props?.permissions?.WM_3OAKS_CASINO_ACCESS?.Value
          ) {
            return false;
          }
          return true;
        }),
      ];
      setCurrentProvider(gpList[0].value);
      setGameProviders(gpList);
    }
  }, [props.permissions]);

  useEffect(() => {
    setFilterSearchValue("");
    if (params.ProviderId) setCurrentProvider(params.ProviderId);
  }, [params.ProviderId]);

  useEffect(() => {
    if (games && params && params.ProviderId) {
      if (games.hasOwnProperty(params.ProviderId)) {
        setCurrentProvider(params.ProviderId);
      }
    }
    debugger;
    try {
      if (games) {
        const _allGames = allGames;
        const aa = getFilterTimelessTech("live-casino");
        debugger;
      }
    } catch (error) {}
  }, [games, params.ProviderId]);

  const getFilterTimelessTech = (subtype, type) => {
    return Object.keys(games).filter((x) => {
      if (x.includes("timelesstech")) {
        return games[x].length > 0
          ? subtype
            ? games[x][0].subtype == subtype
            : true && subtype
            ? games[x][0].type == type
            : true
          : false;
      }
      return x.includes("timelesstech");
    });
  };

  const getGames = async () => {
    setLoading(true);
    await GameService.GET_GAMES()
      .then((res) => {
        setLoading(false);

        const newData = res.map((x) => {
          let y = x;
          if (x.middleware == "blas975") {
            y.CacheData = x.CacheData.reverse();
            return y;
          } else {
            return x;
          }
        });

        UpdateGames(newData);
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };
  const pb = require("../../../assets/img/banners/b2.png");
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_CASINO")}</title>
          </Helmet>

          {games && (
            <div className={currentProvider}>
              <ScrollableListView
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                pb={pb}
                title={t("TITLE_CASINO")}
                provider={currentProvider}
                setProvider={setCurrentProvider}
                data={
                  games && games.hasOwnProperty(currentProvider)
                    ? games[currentProvider]
                        .filter((x) => {
                          //   debugger;
                          if (
                            (params.ProviderId
                              ? params.ProviderId
                              : currentProvider
                            ).includes("-fundist")
                          ) {
                            if (x.Trans) {
                              const _n = x.Trans.hasOwnProperty(t("LANG"))
                                ? x.Trans[t("LANG")]
                                : x.Trans.en;
                              debugger;
                              return _n
                                .toLocaleLowerCase()
                                .includes(
                                  FilterSearchValue?.toLocaleLowerCase()
                                );
                            } else {
                              debugger;
                            }
                          } else {
                            debugger;
                            return x[
                              (params.ProviderId
                                ? params.ProviderId
                                : currentProvider) == "pragmatic-casino"
                                ? "gameName"
                                : currentProvider == "smartsoft"
                                ? "Name"
                                : currentProvider.includes("timeless")
                                ? "title"
                                : x.hasOwnProperty("display_name")
                                ? "display_name"
                                : "name"
                            ]
                              ?.toLocaleLowerCase()
                              .includes(FilterSearchValue?.toLocaleLowerCase());
                          }
                        })
                        .filter((x) => {
                          if (
                            (params.ProviderId
                              ? params.ProviderId
                              : currentProvider) == "pragmatic-casino"
                          ) {
                            return (
                              x.gameTypeID != "lg" && x.gameTypeID != "rgs-vsb"
                            );
                          } else if (
                            (params.ProviderId
                              ? params.ProviderId
                              : currentProvider) == "iconix"
                          ) {
                            return x.type == "slots";
                          } else if (
                            (params.ProviderId
                              ? params.ProviderId
                              : currentProvider
                            ).includes("-fundist")
                          ) {
                            if (isMobile && x.MobilePageCode) {
                              return true;
                            }
                            if (!isMobile && x.PageCode) {
                              return true;
                            }
                            return false;
                          } else if (
                            isMobile &&
                            (x.provider == "CSGI" ||
                              x.provider == "CSIM" ||
                              x.provider == "ZEUS" ||
                              x.provider == "CSGG" ||
                              x.provider == "KNTC" ||
                              x.provider == "VGRG")
                          ) {
                            if (x.platforms?.find((x) => x == "MOBILE")) {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            return true;
                          }
                        })
                        .filter((x) => {
                          let _virtuals = [];
                          [...detroidVirtualGames, ...detroidGames].forEach(
                            (i) => {
                              _virtuals = [..._virtuals, ...i.data];
                            }
                          );
                          if (x.gamecode) {
                            if (_virtuals.find((v) => v == x.gamecode)) {
                              return false;
                            }
                          }
                          return true;
                        })
                        .filter((x) => {
                          let _games = [];
                          fundistGames.forEach((i) => {
                            _games = [..._games, ...i.data];
                          });
                          if (x.ID) {
                            if (_games.find((v) => v == x.ID)) {
                              return false;
                            }
                          }
                          return true;
                        })
                    : []
                }
              />
            </div>
          )}
        </>
      )}
    </Translation>
  );
};
/*

                            .reverse()
                            .sort((a, b) =>
                              a.gameIdNumeric > b.gameIdNumeric ? -1 : 1
                            )
*/
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions, permissionsAll } = authentication;
  const { sports, games, allGames } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    allGames,
    permissions,
    permissionsAll,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Casino);

/*


*/
