import Emitter from "_actions/emitter";
import { gameConstants } from "_constants";

const initialState = {
  sports: null,
  games: null,
  allGames: [],
  loading: false,
  liveGames: [],
  liveGameProviders: [],
  activeGame: null,
};

const updateProviderGamesSort = (array1, array2) => {
  let gameIDOrder = {};
  array1.forEach((item, index) => {
    gameIDOrder[item.gameID] = index;
  });

  // array2'yi array1'deki gameID sıralamasına göre yeniden düzenle
  let sortedArray2 = array2.sort((a, b) => {
    // Eğer iki name de array1'de varsa sıralama farkına göre düzenle
    if (
      gameIDOrder.hasOwnProperty(a.name) &&
      gameIDOrder.hasOwnProperty(b.name)
    ) {
      return gameIDOrder[a.name] - gameIDOrder[b.name];
    }
    // Eğer a array1'de var ve b yoksa a öne gelsin
    if (gameIDOrder.hasOwnProperty(a.name)) {
      return -1;
    }
    // Eğer b array1'de var ve a yoksa b öne gelsin
    if (gameIDOrder.hasOwnProperty(b.name)) {
      return 1;
    }
    // Her ikisi de array1'de yoksa orijinal sıralamayı koru
    return 0;
  });

  return sortedArray2;
};

export function game(state = initialState, action) {
  switch (action.type) {
    case gameConstants.UPDATE_SPORTS_DATA:
      return {
        ...state,
        sports: action.data,
        loading: false,
      };
    case gameConstants.UPDATE_ACTIVE_GAME:
      return {
        ...state,
        activeGame: action.data,
      };
    case gameConstants.UPDATE_GAMES_DATA:
      let _value = {};

      for (let index = 0; index < action.data.length; index++) {
        _value[action.data[index].Name] = action.data[index].CacheData;
      }

      if (_value["pragmatic-casino"] && _value["pragmatic"]) {
        const _prag1 = updateProviderGamesSort(
          _value["pragmatic-casino"],
          _value["pragmatic"]
        );
        debugger;
        _value["pragmatic"] = _prag1;
      }

      return {
        ...state,
        games: _value,
        allGames: action.data,
        loading: false,
      };
    case gameConstants.UPDATE_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case gameConstants.UPDATE_LIVE_GAME:
      return {
        ...state,
        liveGames: action.data,
        loading: false,
      };
    case gameConstants.UPDATE_LIVE_GAME_PROVIDERS:
      return {
        ...state,
        liveGameProviders: action.data,
        loading: false,
      };
    default:
      return state;
  }
}
