import { authenticationAction } from "_actions";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

const UserBox = (props) => {
 
  return (
    <Translation>
      {(t) => (
        <li className="dropdown dropdown-bottom dropdown-end ">
          <div tabIndex={0} role={"button"} className="btn flex items-center">
            <div className="p-2 md:block text-left">
              <h2 className="text-sm font-semibold text-secondary-200">
                {props?.user?.RelUser?.UserName}
              </h2>
              <p
                className={
                  `text-xs ` +
                  (props?.user?.RelUserType.TypeID == 1
                    ? "  text-master-500"
                    : props?.user?.RelUserType.TypeID == 2
                    ? "  text-superadmin-500"
                    : props?.user?.RelUserType.TypeID == 3
                    ? "  text-admin-500"
                    : props?.user?.RelUserType.TypeID == 4
                    ? " text-partner-500"
                    : "  text-gamer-500")
                }
              >
                {props?.user?.RelUserType?.Value}
              </p>
            </div>
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] mt-4 menu p-2 shadow bg-secondary-400 rounded-box "
          >
            <li>
              <div
                onClick={() => {
                  props.logout();
                }}
                className="btn btn-sm btn-ghost"
              >
                {t("LOGOUT")}
              </div>
            </li>
          </ul>
        </li>
      )}
    </Translation>
  );
};
const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
