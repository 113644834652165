import { OAuth2Service } from "_services";
import i18n from "i18n";
import React from "react";
import { connect } from "react-redux";
import { Form, ButtonToolbar, Button, Schema } from "rsuite";
import { Translation } from "react-i18next";
import { authenticationAction } from "_actions";
import { UserService } from "_services";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
export const LoginLayout = (props) => {
  const model = Schema.Model({
    username: Schema.Types.StringType().isRequired(i18n.t("MSG_NULL_USERNAME")),
    password: Schema.Types.StringType().isRequired(i18n.t("MSG_NULL_PASSWORD")),
  });
  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const checkToken = async () => {
    const { LoginSuccess, LoginFailure } = props;
    await UserService.USER_DETAIL()
      .then((res) => {
        if (res.RelUserType.TypeID == 5) {
          toast.success(
            `${i18n.t("MESSAGE_LOGIN_SUCCUESS")} ${res.RelUser.UserName}`
          );

          LoginSuccess(res);

          setTimeout(() => {
            setLoading(false);
          }, 500);
        } else {
          localStorage.removeItem("access_token");
          localStorage.removeItem("expires_in");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user");
          toast.error(i18n.t("NO_PERMISSION_WITHOUT_GAMER"));
        }
      })
      .catch((err) => {
        debugger;
        LoginFailure();
      });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!formRef.current.check()) {
      console.error("Form Error");
      setLoading(false);
      return;
    } else {
      /*
      try {
        localStorage.clear();
        window.sessionStorage.clear();
       } catch (error) {}
*/

      await OAuth2Service.LOGIN(formValue.username, formValue.password, false)
        .then((res) => {
          checkToken();
        })
        .catch((err) => {
          debugger;

          if (err && err?.response?.status == 400) {
            toast.error(i18n.t("MESSAGE_LOGIN_FAILD"));
          } else if (err && err.response) {
            toast.error(err?.response?.data?.failText[0]);
          } else {
            toast.error(i18n.t("ERROR_RESPONSE_ERROR_UNDEFINED"));
          }
          setLoading(false);
        });
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_WELCOME_BACK")}</title>
          </Helmet>
          <div className="p-2 sm:p-0 min-h-screen flex flex-col justify-center items-center pt-6 sm:pt-0 bg-secondary-500 bgpattern">
            <span
              //style={{ fontSize: "xx-large" }}
              className=" mb-8 hellotext"
            >
              {t("TITLE_WELCOME_BACK")}
            </span>
            <div className="  sm:m-0 sm:mb-2 w-full sm:max-w-md mt-2 px-6 py-4 glass bg-gradient-to-r from-secondary-600 via-primary2-950/90 to-light-primary2/80 drop-shadow-2xl  overflow-hidden  rounded-lg">
              <Form
                disabled={loading}
                ref={formRef}
                fluid
                model={model}
                onChange={setFormValue}
                onCheck={setFormError}
                formValue={formValue}
              >
                <Form.Group>
                  <Form.ControlLabel className="label-text">
                    {t("TXT_USERNAME")}
                  </Form.ControlLabel>
                  <Form.Control
                    onKeyDown={_handleKeyDown}
                    className="input input-bordered w-full max-w outline-secondary-500 bg-secondary-700"
                    name="username"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="label-text">
                    {t("TXT_PASSWORD")}
                  </Form.ControlLabel>
                  <Form.Control
                    onKeyDown={_handleKeyDown}
                    className="input input-bordered w-full max-w outline-secondary-500 bg-secondary-700"
                    name="password"
                    type="password"
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group>
                  <ButtonToolbar>
                    <Button
                      loading={loading}
                      className="btn btn-primary btn-md btn-block text-white"
                      onClick={handleSubmit}
                    >
                      {t("BUTTON_SIGNIN")}
                    </Button>
                    {/*+
                  
                     <Button appearance="link">Forgot password?</Button>
                    */}
                  </ButtonToolbar>
                </Form.Group>
              </Form>
              <div className="w-full flex justify-center">
                <span className="mt-2 font-light text-primary2-900">
                  {t("VERSION")}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
