import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView from "../ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ServiceURLs } from "_services";
import i18n from "i18n";
import toast from "react-hot-toast";
import { isBrowser, isMobile } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "_services";
import Emitter from "_actions/emitter";
import { useNavigate } from "react-router-dom";
import { fundistGames } from "BaseServiceURLs";

const WorldMatch = (props) => {
  const { user, loggedIn, sports, games, UpdateGames, demo, PlayCategory } =
    props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [uKey, setUKey] = useState(uuidv4());
  const navigate = useNavigate();

  useEffect(() => {
    Emitter.on("ACTIVE_TAB", (newValue) => {
      const _uKey = uKey;
      if (
        uKey &&
        newValue &&
        newValue.uniqToken &&
        newValue.uniqToken != _uKey
      ) {
        navigate("/");
      }
      debugger;
    });
    return () => {
      Emitter.off("ACTIVE_TAB");
    };
  }, []);

  const setActiveGame = () => {
    const _k = uKey;
    debugger;
    UserService.UPDATE_ACTIVEGAME(params.ProviderId + "-" + params.GameId, _k)
      .then((res) => {
        setLoading(false);
        debugger;
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    debugger;
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function GetParams() {
    let params = useParams();
    return params;
  }
  let params = GetParams();

  const runGame = () => {
    if (
      !loading &&
      params &&
      params.ProviderId &&
      params.GameId &&
      props.games
    ) {
      const g = props.games;
      debugger;
      if (g && g.hasOwnProperty(params.ProviderId)) {
        const _data = g[params.ProviderId].find((x) => x.ID == params.GameId);
        if (!demo) {
          if (_data) {
            debugger;
            if (isMobile) {
              getGameDetail(
                _data.MobilePageCode,
                i18n.t("LANG"),
                isMobile ? 1 : 0,
                _data.System
              );
            } else {
              getGameDetail(
                _data.PageCode,
                i18n.t("LANG"),
                isMobile ? 1 : 0,
                _data.System
              );
            }
          } else {
            toast.error(i18n.t("ERROR_GAME_NOT_FOUNT"));
          }
        } else {
          if (_data) {
            if (isMobile) {
              getGameDemoDetail(
                _data.MobilePageCode,
                i18n.t("LANG"),
                isMobile ? 1 : 0,
                _data.System
              );
            } else {
              getGameDemoDetail(
                _data.PageCode,
                i18n.t("LANG"),
                isMobile ? 1 : 0,
                _data.System
              );
            }
          } else {
            toast.error(i18n.t("ERROR_GAME_NOT_FOUNT"));
          }
        }
      }
    }
  };

  useEffect(() => {
    runGame();
    if (params && params.ProviderId && params.GameId) {
      setActiveGame();
    }
  }, []);

  useEffect(() => {
    runGame();
  }, [games]);

  let startget = false;

  const getGameDetail = (id_game, lang, mobile, System) => {
    if (!startget) {
      startget = true;
      setLoading(true);
      let PlayCategory = "SLOT";

      let pgData = [
        "Redgenn-fundist",
        "Upgaming-fundist",
        "TurboGames-fundist",
        "Spribe-fundist",
      ].find((x) => x == params.ProviderId);
      const pvData = [].find((x) => x == params.ProviderId);

      const ptvData = ["TVBet-fundist"].find((x) => x == params.ProviderId);
      const plcData = [
        "Atmosfera-fundist",
        "VivoGaming-fundist",
        "WMCasino-fundist",
        "Evolution-fundist",
        "XProGaming-fundist",
        "LuckyStreak-fundist",
        "AESexyBaccarat-fundist",
        "LiveGames-fundist",
      ].find((x) => x == params.ProviderId);

      if (!pgData) {
        const hasWMG = fundistGames.find(
          (x) => x.provider == params.ProviderId
        );
        if (hasWMG && params.GameId) {
          pgData = hasWMG.data.find((x) => x == params.GameId);
        }
      }

      if (pvData) {
        PlayCategory = "VIRTUAL";
      } else if (pgData) {
        PlayCategory = "GAMES";
      } else if (plcData) {
        PlayCategory = "LIVE_CASINO";
      } else if (ptvData) {
        PlayCategory = "TV_GAMES";
      } else {
        PlayCategory = "SLOT";
      }
      debugger;
      GameService.GET_WORLDMATCH_DETAIL(
        System,
        id_game,
        props.currentLang,
        mobile,
        PlayCategory
      )
        .then((res) => {
          setLoading(false);
          setData(res.dynamicValue.url);
          debugger;
          //  openInNewTab(res.url);
          startget = false;
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  const getGameDemoDetail = (id_game, lang, mobile, System) => {
    if (!startget) {
      startget = true;
      setLoading(true);
      GameService.GET_WORLDMATCH_DEMO(
        System,
        id_game,
        props.currentLang,
        mobile
      )
        .then((res) => {
          setLoading(false);
          setData(res.dynamicValue.url);
          debugger;
          //  openInNewTab(res.url);
          startget = false;
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };

  /*
  const adjustHeight = () => {
    const windowHeight = window.innerHeight;
     try {
      document.querySelector(".gameiframe").style.height = `${windowHeight}px`;
    } catch (error) {
      
    }
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);
*/

  function openInNewTab(url) {
    var newWindow = window.open(url);
    newWindow.focus();
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_CASINO")}</title>
            <style type="text/css">
              {`
      .base-nav {
        display: none !important;
        
      }
      .base-nav1 {
        display: none !important;
        
      }
      body, html , #root{
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
    .appdiv{
      height: 100% !important;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
      
      
    `}
            </style>
          </Helmet>

          {data && (
            <iframe
              srcDoc={` <!DOCTYPE html>
              <html lang="en" >
              <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
                <style>
                  html,body{
                    height: 100%;
                    padding:0px;
                    margin:0px;
                    color:#fff
                  }
                  #egamings_container{
                    height: 100%;
                    background-image:url('${require("../../../assets/img/textlogo2.png")}');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                  }
                </style>
              </head>
              <body>
              
                ${data.replace(`\"`, `"`)}
              </body>
              </html>`}
              style={{ width: "100%", height: "100%", border: "none" }}
            />
          )}
        </>
      )}
    </Translation>
  );
};
// height: "calc(100vh - 72px)",
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorldMatch);
