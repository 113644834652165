import { UserEndPoints, OAuth2_MakeRequestAndGetResponse } from "./index";
import { HTTPOperationType, MakeReqestAndGetResponse } from "./httpOperations";
import { GameEndPoints, GetBaseURL, PartnerEndPoints } from "./_serviceSettings";
import Emitter from "_actions/emitter";

export const PartnerService = {
  CREATE_GAMER,
  READ_GAMERS,
  AddBalanceAccount,
  CheckOutAccount
};

async function CREATE_GAMER(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + PartnerEndPoints.CREATE_GAMER,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
async function READ_GAMERS(formValue) {
  let js = formValue;

  return MakeReqestAndGetResponse(
    (await GetBaseURL()) + PartnerEndPoints.READ_GAMERS + GameEndPoints.READ_DEFAULT_FILTER,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}


async function AddBalanceAccount(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + PartnerEndPoints.ADD_BALANCE,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}


async function CheckOutAccount(formValue) {
  let js = formValue;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + PartnerEndPoints.CHECK_OUT,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}