import Emitter from "_actions/emitter";
import { userConstants } from "_constants";
let user = null;
let token = null;
try {
  token = localStorage.getItem("access_token");
  user = JSON.parse(localStorage.getItem("user"));
} catch (error) {
  token = null;
  user = null;
}

const _initialState = token
  ? {
      loggedIn: true,
      user,
      currentLang: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
      LoginFailed: false,
    }
  : {
      loggedIn: false,
      LoginFailed: false,
      currentLang: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
    };

const initialState = {
  ..._initialState,
  authLoading: true,
  wallet: {
    balance: 0,
    bonus: 0,
  },
  permissions: {},
  permissionsAll: [],
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.user));

      try {
        const _mUsr = localStorage.getItem("myMenuClickHistoriesUser");
        if (action.user._id !== _mUsr) {
          localStorage.removeItem("myMenuClickHistories");
          Emitter.emit("UPDATE_FAV_LIST", null);
        }
      } catch (error) {}

      return {
        ...state,
        loggedIn: true,
        user: action.user,
        wallet: {
          ...state.wallet,
          balance: action.user?.Balance,
        },
        authLoading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        LoginFailed: true,
        loggedIn: false,
        authLoading: false,
      };
    case userConstants.LOGOUT:
      try {
       // localStorage.clear();
       window.location.reload();

        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
      //  window.sessionStorage.clear();

      } catch (error) {}
      return {
        ...state,
        loggedIn: false,
        user: null,
      };
    case userConstants.UPDATE_USER:
      return {
        ...state,
        loggedIn: true,
        user: {
          ...user,
          ...action.user,
        },
        wallet: {
          ...state.wallet,
          balance: action.user?.Balance,
        },
      };

    case userConstants.UPDATE_LANG:
      localStorage.setItem("lang", action.data);
      return {
        ...state,
        currentLang: action.data,
      };
    case userConstants.UPDATE_WALLET:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          ...action.data,
        },
      };
    case userConstants.UPDATE_PERMISSIONS:
      let _value = {};
      for (let index = 0; index < action.data.length; index++) {
        _value[action.data[index].SettingName] = action.data[index];
      }
      return {
        ...state,
        permissions: _value,
        permissionsAll: action.data,
      };
    default:
      return state;
  }
}
