import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetGamePoster } from "../ScrollableListView";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { useParams } from "react-router-dom";
import i18n from "i18n";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Translation } from "react-i18next";
import { oranHesapla } from "./BLAS975GameBox";
import toast from "react-hot-toast";
import { isBrowser, isMobile } from "react-device-detect";

const WMGameBox = (props) => {
  const containerRef = useRef(null);
  const [H, setH] = useState(null);
  const { item, index, isLive, provider } = props;

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const resizeBox = () => {
    if (containerRef && containerRef.current) {
      const w = containerRef.current?.clientWidth;
      const prgmaticName = document.querySelector(".wmName")?.offsetHeight;

      const nW = oranHesapla(640, 480, w);
      containerRef.current.style.height = `${nW + prgmaticName}px`;

      setH(`${w + prgmaticName}px`);
    }
  };
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", resizeBox);
    window.addEventListener("resize", resizeBox);
  }, []);
  
  if (!item.Trans) {
    debugger;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div
            ref={containerRef}
            key={item.ID + item.System}
            className="GameBox  bg-primary-500 md:bg-primary-500 group/item cursor-pointer md:border md:border-primary-500"
            onLoad={() => resizeBox()}
            style={{
              height: H ? H : undefined,
            }}
          >
            <div className="games-img">
              <AsyncImage
                className="h-full w-full rounded-t-[2.5vw] md:rounded-t-md"
                id={"wm-poster" + item.ID}
             src={item.ImageFullPath}
                loader={
                  <div className="skeleton w-full h-full bg-primary-700">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                error={
                  <div className="bg-light-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
              />
            </div>
            <div className="game-hover group-hover/item:before:opacity-80"></div>
            {isLive && (
              <div
                style={{ position: "absolute" }}
                id={"wm" + item.ID}
                className="games-img "
              >
                <span
                  id={"wm-account" + item.ID}
                  className="game-live-limitbet-account opacity-[0] bg-primary-500/80"
                ></span>
                <span
                  id={"wm-limitbet" + item.ID}
                  className="game-live-limitbet opacity-[0]  bg-primary-500/80"
                ></span>
              </div>
            )}

            {isLive == true ? (
              <Link
                to={`/WorldMatch/${provider}/${item.ID}`}
                className="game-btns   flex-col px-6"
              >
                <IoPlayCircleOutline className="text-primary-500 w-14 h-14" />
              </Link>
            ) : (
              <div className="game-btns   flex-col  ">
                {isMobile ? (
                  item.MobilePageCode ? (
                    <>
                      <Link
                        to={`/WorldMatch/${provider}/${item.ID}`}
                        className="flex flex-row m-1  justify-start md:justify-center items-center"
                        
                      >
                        <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                        {t("BUTTON_PLAY")}
                      </Link>
                      {item.HasDemo == "1" && (
                        <Link
                          to={`/WorldMatch-demo/${provider}/${item.ID}`}
                          className="flex flex-row m-1  justify-start md:justify-center items-center"
                        >
                          <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                          {t("BUTTON_DEMOPLAY")}
                        </Link>
                      )}
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        toast(t("ERROR_CAN_NOT_PLAY_MOBILE"));
                      }}
                      className="w-full h-full"
                    ></div>
                  )
                ) : item.PageCode ? (
                  <>
                    <Link
                      to={`/WorldMatch/${provider}/${item.ID}`}
                      className="flex flex-row m-1  justify-start md:justify-center items-center"
                    >
                      <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                      {t("BUTTON_PLAY")}
                    </Link>
                    {item.HasDemo == "1" && (
                      <Link
                        to={`/WorldMatch-demo/${provider}/${item.ID}`}
                        className="flex flex-row m-1  justify-start md:justify-center items-center"
                      >
                        <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                        {t("BUTTON_DEMOPLAY")}
                      </Link>
                    )}
                  </>
                ) : (
                  <div
                    onClick={() => {
                      toast(t("ERROR_CAN_NOT_PLAY_DESKTOP"));
                    }}
                    className="w-full h-full"
                  ></div>
                )}
              </div>
            )}

            <span className="wmName name text-white after:bg-primary-500 group-hover/item:after:bg-primary-500 group-hover/item:after:opacity-[0.9] group-hover/item:text-white">
              {item.Trans.hasOwnProperty(t("LANG"))
                ? item.Trans[t("LANG")]
                : item.Trans.en}
            </span>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WMGameBox);
