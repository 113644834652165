import axios from "axios";

import { UserEndPoints, GetBaseURL } from "./_serviceSettings";
import { OAuth2ServiceEndPoints, OAuth2Service } from "./index";
import Emitter from "_actions/emitter";

function GetDiffTimes(times) {
  let CurrentTime = new Date();
  let endTime = new Date(parseFloat(times));
  let difference = endTime.getTime() - CurrentTime.getTime(); // This will give difference in milliseconds
  let resultInMinutes = Math.round(difference / 60000);
  return resultInMinutes;
}

export async function checkTokenExpires(isTest) {
  try {
    let refresh_token = localStorage.getItem("refresh_token");
    let expires_in = localStorage.getItem("expires_in");
    let diff = GetDiffTimes(expires_in);
    if (diff > -1 && diff < 15) {
      await OAuth2Service.REFRESH_TOKEN(refresh_token)
        .then((data) => {
          if (!isTest) return Promise.reject(data);
        })
        .catch((err) => {
          if (!isTest) return Promise.reject(err);
        });
    }
    if (!isTest) return Promise.reject(false);
  } catch (error) {
    if (!isTest) return Promise.reject(false);
  }
}

export async function OAuth2_MakeRequestAndGetResponse(
  OperationUrl,
  _HTTPOperationType,
  JsonParams = null,
  isBlob
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */

    let TokenRefresh = false;

    await checkTokenExpires
      .apply()
      .then((data) => {
        TokenRefresh = true;
      })
      .catch((err) => {
        TokenRefresh = false;
      });

    let access_token = localStorage.getItem("access_token");

    request = client(access_token, isBlob);

    var js = JSON.stringify(JsonParams);
    //console.log(js);
    switch (_HTTPOperationType) {
      case HTTPOperationType.get:
        ress = await request.get(OperationUrl);
        break;
      case HTTPOperationType.post:
        ress = await request.post(OperationUrl, js);
        break;
      case HTTPOperationType.put:
        ress = await request.put(OperationUrl, js);
        break;
      case HTTPOperationType.delete:
        ress = await request.delete(OperationUrl);
        break;
      default:
        break;
    }

    return ress.data;
  } catch (error) {
    debugger;
    console.log(error);

    // request = client(token);
    //eger token dusmus ise

    await request
      .post(GetBaseURL() + UserEndPoints.GET_USERDETAIL, {})
      .then((xx) => {})
      .catch(async (xx) => {
        let access_token = localStorage.getItem("access_token");
        if (!access_token) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("expires_in");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user");

          Emitter.emit("LOGOUT");
        }
        debugger;
        if (xx.response.status === 401) {
          let refresh_token = localStorage.getItem("refresh_token");
          await OAuth2Service.REFRESH_TOKEN(refresh_token)
            .then((xx) => {
              window.location.reload(true);
            })
            .catch((xx) => {
              debugger;

              localStorage.removeItem("access_token");
              localStorage.removeItem("expires_in");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("user");
              Emitter.emit("LOGOUT");
            });
        }
      });

    return ress.data;
  }
}
export async function OAuth2_MakeRequestAndGetResponseLogin(
  OperationUrl,
  _HTTPOperationType,
  JsonParams = null,
  isBlob
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */

    request = client(null, false);

    var js = JSON.stringify(JsonParams);
    //console.log(js);
    switch (_HTTPOperationType) {
      case HTTPOperationType.get:
        ress = await request.get(OperationUrl);
        break;
      case HTTPOperationType.post:
        ress = await request.post(OperationUrl, js);
        break;
      case HTTPOperationType.put:
        ress = await request.put(OperationUrl, js);
        break;
      case HTTPOperationType.delete:
        ress = await request.delete(OperationUrl);
        break;
      default:
        break;
    }
    debugger;
    return ress.data;
  } catch (error) {
    debugger;

    return Promise.reject(error);
  }
}
export async function MakeReqestBlob(
  OperationUrl,
  _HTTPOperationType,
  JsonParams = null,
  isBlob
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */

    let TokenRefresh = false;

    await checkTokenExpires
      .apply()
      .then((data) => {
        TokenRefresh = true;
      })
      .catch((err) => {
        TokenRefresh = false;
      });

    let access_token = localStorage.getItem("access_token");

    request = client(access_token, isBlob);
    var js = JSON.stringify(JsonParams);
    try {
      switch (_HTTPOperationType) {
        case HTTPOperationType.get:
          ress = await request.get(OperationUrl);
          break;
        case HTTPOperationType.post:
          ress = await request.post(OperationUrl, js);
          break;
        case HTTPOperationType.put:
          ress = await request.put(OperationUrl, js);
          break;
        case HTTPOperationType.delete:
          ress = await request.delete(OperationUrl);
          break;
        default:
          break;
      }
    } catch (error) {
      debugger;
    }

    if (ress.data) {
      return { data: ress.data, res: ress };
      //  return ress.data;
    }
  } catch (error) {
    console.log(error);

    // request = client(token);
    //eger token dusmus ise

    return ress.data;
  }
}

export const clientBlob = (token = null) => {
  const defaultOptions = {
    responseType: "blob",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  };
  return {
    get: (FullURL, options = {}) =>
      axios.get(FullURL, {
        ...defaultOptions,
        ...options,
      }),
    post: (FullURL, data, options = {}) =>
      axios.post(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    put: (FullURL, data, options = {}) =>
      axios.put(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    delete: (FullURL, options = {}) =>
      axios.delete(FullURL, {
        ...defaultOptions,
        ...options,
      }),
  };
};

export async function OAuth2_MakeRequestAndGetResponseForRefreshToken(
  OperationUrl,
  _HTTPOperationType,
  JsonParams = null
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */
    let access_token = localStorage.getItem("access_token");

    request = client(access_token);

    var js = JSON.stringify(JsonParams);
    //console.log(js);
    switch (_HTTPOperationType) {
      case HTTPOperationType.get:
        ress = await request.get(OperationUrl);
        break;
      case HTTPOperationType.post:
        ress = await request.post(OperationUrl, js);
        break;
      case HTTPOperationType.put:
        ress = await request.put(OperationUrl, js);
        break;
      case HTTPOperationType.delete:
        ress = await request.delete(OperationUrl);
        break;
      default:
        break;
    }

    //eger token alma islemi ise..
    if (
      access_token == null &&
      OperationUrl.endsWith(OAuth2ServiceEndPoints.TOKEN)
    ) {
      if (ress.status === 200) {
        return ress.data;
        //todo ok.
      }
    }
    return ress.data;
  } catch (error) {
    throw error.response.data; //new Error(error.response.data);
  }
}

export async function MakeReqestAndGetResponse(
  OperationUrl,
  _HTTPOperationType,
  JsonParams = null
) {
  let request;
  let ress;
  try {
    /*
    let res=await getSession();
    var token = (res!=="" && res!==null && res!=="null" && res!==undefined)?
    JSON.parse(getSession).token:"";
   */

    /*  var token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).token;
*/
    var token = localStorage.getItem("access_token");
    request = client(token);

    var js = JSON.stringify(JsonParams);
    //console.log(js);
    switch (_HTTPOperationType) {
      case HTTPOperationType.get:
        ress = await request.get(OperationUrl);
        break;
      case HTTPOperationType.post:
        ress = await request.post(OperationUrl, js);
        break;
      case HTTPOperationType.put:
        ress = await request.put(OperationUrl, js);
        break;
      case HTTPOperationType.delete:
        ress = await request.delete(OperationUrl);
        break;
      default:
        break;
    }

    if (ress.data.isLoginRequired === false) return ress.data;
    else {
      localStorage.removeItem("user");
      window.location.reload(true);
    }
  } catch (error) {
    debugger;
    console.log(error);

    // request = client(token);
    //eger token dusmus ise

    await request
      .post(GetBaseURL() + UserEndPoints.GET_USERDETAIL, {})
      .then((xx) => {
        if (xx.data.isLoginRequired === true) {
          localStorage.removeItem("user");
          window.location.reload(true);
        }
      })
      .catch((xx) => {
        debugger;
        if (xx.response.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("expires_in");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user");
          Emitter.emit("LOGOUT");
        }
      });

    return ress.data;
  }
}

export const HTTPOperationType = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const client = (token = null, isBlob = false) => {
  let defaultOptions = "";

  if (isBlob) {
    defaultOptions = {
      responseType: "blob",
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json",
      },
    };
  } else {
    defaultOptions = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json",
      },
      crossdomain: true,
    };
  }

  return {
    get: (FullURL, options = {}) =>
      axios.get(FullURL, {
        ...defaultOptions,
        ...options,
      }),
    post: (FullURL, data, options = {}) =>
      axios.post(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    put: (FullURL, data, options = {}) =>
      axios.put(FullURL, data, {
        ...defaultOptions,
        ...options,
      }),
    delete: (FullURL, options = {}) =>
      axios.delete(FullURL, {
        ...defaultOptions,
        ...options,
      }),
  };
};
