import { UpdateLang } from "_actions";
import { logout } from "_actions";
import { getCurrency } from "components/Navbar/NavUserBox";
import i18n from "i18n";
import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

const WalletBox = (props) => {
  const { currentLang } = props;

  return (
    <Translation>
      {(t) => (
        <div className="border  p-1 border-secondary-800 bg-secondary-800 rounded-3xl mt-1">
          <div className="dropdown dropdown-bottom text-white w-full">
            <div
              tabIndex={0}
              role="button"
              style={{
                background: "transparent",
              }}
              className="btn btn-ghost  btn-block btn-md flex flex-col items-start "
            >
              <div className="fade-in flex items-center">
                <div className="rounded-xl relative w-[26px] h-[26px] overflow-hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
                    />
                  </svg>
                </div>
                <span className="ml-2 text-xl">
                  {`${props?.wallet?.balance.toLocaleString("tr-TR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })} ${getCurrency(props?.user?.Currency)}`}
                </span>
              </div>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] p-2 shadow-2xl bg-base-300 rounded-box w-[90vw]"
            >
              <li className="mb-1">
                <div
                  className={
                    "fade-in theme-controller btn btn-md btn-block btn-ghost justify-start " +
                    "btn-active"
                  }
                  onClick={() => {
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {`${t(
                    "TXT_BALANCE"
                  )} : ${(props?.wallet?.balance).toLocaleString("tr-TR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })} ${getCurrency(props?.user?.Currency)}`}
                </div>
              </li>
              <li className="mb-1">
                <div
                  className={
                    "fade-in theme-controller btn btn-md btn-block btn-ghost justify-start "
                  }
                  onClick={() => {
                    const elem = document.activeElement;
                    if (elem) {
                      elem?.blur();
                    }
                  }}
                >
                  {`${t("TXT_BONUS")} : ${(0).toLocaleString("tr-TR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                  })} ${getCurrency(props?.user?.Currency)}`}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang,wallet } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
    wallet
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    UpdateLang: (data) => dispatch(UpdateLang(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletBox);
