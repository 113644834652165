import React, { useState } from "react";
import UserBox from "./UserBox";
import LangBox from "./LangBox";
import LogoBox from "./LogoBox";
import MobileDrawer from "./MobileDrawer";
import NavUserBox from "./NavUserBox";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { gameAction } from "_actions";

function MobileNavbar(props) {
  const [showSub, setShowSub] = useState(true);
  const location = useLocation();
  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight; // .base-nav'ın yüksekliğini al
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight; // .base-nav'ın yüksekliğini al
      const windowHeight = window.innerHeight;
      const pickElement = document.getElementById("pick777"); // #pick777 id'li elementi seç

      if (pickElement) {
        // #pick777 id'li elementin top değerine navbar'ın yüksekliğini ekle
        pickElement.style.top = `${
          navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1
        }px`;
        pickElement.style.height = `${
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1)
        }px`;
      }
    } catch (error) {}
  };
  return (
    <Translation>
      {(t) => (
        <div
          className={
            "base-nav1  mobile-show bg-secondary-500  flex-col drop-shadow-2xl  text-base-content  top-0 z-30 flex  w-full justify-center bg-opacity-90 backdrop-blur transition-shadow duration-100 [transform:translate3d(0,0,0)] shadow-xl bg-contain bgpattern " +
            (location.pathname == "/sports" ? "fixed" : "sticky")
          }
        >
          <nav className="navbar w-full min-h-[40px] h-[40px]">
            <div className="flex flex-1 md:gap-1 lg:gap-2">
              <LogoBox />
            </div>
            <div className="flex-0">
              <NavUserBox />
              <div
                style={{
                  background: "transparent",
                  border: "none",
                }}
                className="btn btn-neutral btn-sm btn-square"
                onClick={() => {
                  setShowSub(!showSub);
                  setTimeout(() => {
                    adjustHeight();
                  }, 200);
                }}
              >
                {showSub ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                )}
              </div>
              <MobileDrawer />
            </div>
          </nav>
          {showSub && (
            <div className="flex justify-between items-center bg-gradient-to-r from-primary-600 via-primary-500 to-primary-600 h-8 w-full">
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  className=" btn btn-xs btn-primary btn-outline  border-0"
                  to={"/sports"}
                >
                  {t("TITLE_SPORTS")}
                </Link>
              )}

              {props.permissions?.CASINO_ACCESS?.Value && (
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  className=" btn btn-xs btn-primary btn-outline  border-0"
                  to={"/casino"}
                >
                  {t("TITLE_CASINO")}
                </Link>
              )}

              {props.permissions?.LIVECASINO_ACCESS?.Value && (
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  className=" btn btn-xs btn-primary btn-outline  border-0"
                  to={"/live-casino"}
                >
                  {t("TITLE_LIVECASINO")}
                </Link>
              )}
              {props.permissions?.GAMES_ACCESS?.Value && (
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  className=" btn btn-xs btn-primary btn-outline  border-0"
                  to={"/games"}
                >
                  {t("TITLE_GAMES")}
                </Link>
              )}
              {props.permissions?.VIRTUAL_ACCESS?.Value && (
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  className=" btn btn-xs btn-primary btn-outline  border-0"
                  to={"/virtual"}
                >
                  {t("TITLE_VIRTUAL")}
                </Link>
              )}
              {props.permissions?.TVGAMES_ACCESS?.Value && (
                <Link
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                  className=" btn btn-xs btn-primary btn-outline  border-0"
                  to={"/tv-games"}
                >
                  {t("TITLE_TVGAMES")}
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </Translation>
  );
}
const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn, permissions } = authentication;

  return {
    user,
    loggedIn,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileNavbar);

/*
bg-gradient-to-r from-primary-600 via-primary-500 to-primary-600

*/
