import { UserEndPoints, OAuth2_MakeRequestAndGetResponse } from "./index";
import { HTTPOperationType, MakeReqestAndGetResponse, OAuth2_MakeRequestAndGetResponseForRefreshToken } from "./httpOperations";
import { GameEndPoints, GetBaseURL, ServiceURLs } from "./_serviceSettings";
import Emitter from "_actions/emitter";
import { isBrowser, isMobile, isTablet } from "react-device-detect";

import { Notification } from "rsuite";
export const GameService = {
  GET_SPORTS_TOKEN,
  GET_ALTENARSPORTS_TOKEN,
  GET_GAMES,
  GET_GAME_DETAIL,
  GET_SMARTSOFT_DETAIL,
  GET_BLAS975_DETAIL,
  GET_BLAS975_DEMO_DETAIL,
  GET_LIVE_GAMES,
  GET_LIVE_GAME_PROVIDERS,
  GET_DETROIT_DETAIL,
  GET_WORLDMATCH_DETAIL,
  GET_WORLDMATCH_DEMO,
  GET_DETROIT_SLOT_GAME,
  GET_TIMELESSTECH_GAME,
};

const message = (msg, type) => {
  return <Notification type={type} header={msg} closable></Notification>;
};
async function GET_ALTENARSPORTS_TOKEN() {
  return OAuth2_MakeRequestAndGetResponseForRefreshToken(
    (await GetBaseURL()) + GameEndPoints.ALTENAR_TOKEN,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
async function GET_SPORTS_TOKEN() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.SPORTS_TOKEN,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_GAMES() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) +
      GameEndPoints.READ_GAME +
      UserEndPoints.READ_DEFAULT_FILTER_GAME,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_LIVE_GAMES(ProviderId, search = "", page = 1) {
  const filter = `{${
    (ProviderId ? '"providerID":' + '"' + ProviderId + '" ' : "") 
  }}?CountPerPage=100&CurrentPage=${page}`;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_LIVE_GAMES + filter,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_LIVE_GAMES2(ProviderId, search = "lobby", page = 1) {
  const filter = `{${
    (ProviderId ? '"providerID":' + '"' + ProviderId + '" ' : "") +
    (search
      ? ProviderId && search
        ? "," + ('"name": { "$regex": "' + search + '","$options":"i" }')
        : ""
      : "")
  }}?CountPerPage=100&CurrentPage=${page}`;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_LIVE_GAMES + filter,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_LIVE_GAME_PROVIDERS() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) +
      GameEndPoints.GET_LIVE_GAMES_PROVIDERS +
      GameEndPoints.READ_DEFAULT_FILTER,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
async function GET_GAME_DETAIL(
  symbol,
  platform = "WEB",
  lang = "en",
  PlayCategory = "SLOT"
) {
  const js = {
    symbol: symbol,
    platform: platform,
    language: lang,
    lobbyUrl: ServiceURLs.WEB_URL + "casino/pragmatic-casino/",
    PlayCategory: PlayCategory,
  };
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_PRAGMATIC_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_SMARTSOFT_DETAIL(
  GameName,
  GameCategory,
  Lang = "en",
  PlayCategory = "SLOT"
) {
  const js = {
    ReturnURL: ServiceURLs.WEB_URL + "casino/smartsoft/",
    GameCategory: GameCategory,
    GameName: GameName,
    Lang: Lang,
    PlayCategory: PlayCategory,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_SMARTSOFT_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_BLAS975_DETAIL(
  id_game,
  Lang = "en",
  ProviderId,
  PlayCategory = "SLOT"
) {
  const js = {
    exit_url: ServiceURLs.WEB_URL + "casino/" + ProviderId,
    id_game: id_game,
    language: Lang,
    PlayCategory: PlayCategory,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_BLAS975_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_BLAS975_DEMO_DETAIL(
  id_game,
  Lang = "en",
  PlayCategory = "SLOT"
) {
  const js = {
    exit_url: ServiceURLs.WEB_URL + "casino/",
    id_game: id_game,
    language: Lang,
    PlayCategory: PlayCategory,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_BLAS975_DEMO_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_DETROIT_DETAIL(
  id_game,
  Lang = "en_us",
  device = "D",
  PlayCategory = "SLOT"
) {
  const js = {
    id_game: id_game,
    exit_url: ServiceURLs.WEB_URL + "live-casino/",
    language: Lang,
    Device: device,
    PlayCategory: PlayCategory,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_DETROIT_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_WORLDMATCH_DETAIL(
  System,
  PageCode,
  Lang = "en",
  isMobile = 0,
  PlayCategory
) {
  const js = {
    Merchant: System,
    Page: PageCode,
    Lang: Lang,
    Demo: 0, // demo
    IsMobile: isMobile,
    PlayCategory: PlayCategory,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_WORLDMATCH,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_WORLDMATCH_DEMO(System,PageCode, Lang = "en", isMobile = 0) {
  const js = {
    Merchant: System,
    Page: PageCode,
    Lang: Lang,
    IsMobile: isMobile,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_WORLDMATCH_DEMO,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_DETROIT_SLOT_GAME(
  id_game,
  Lang = "EN",
  platform = "DESKTOP",
  Mode = "REAL",
  provider,
  PlayCategory = "SLOT"
) {
  const js = {
    id_game: id_game,
    exit_url: ServiceURLs.WEB_URL + "casino/" + (provider ? provider : ""),
    language: Lang,
    platform: platform,
    Mode: Mode,
    PlayCategory: PlayCategory,
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_DETROIT_SLOT_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_TIMELESSTECH_GAME(
  game_id = "42",
  language = "en",
  Provider = "iconix",
  device,
  mode = "real"
) {
  const js = {
    mode: mode,
    game_id: game_id,
    language: language,
    Provider: Provider.includes("-timelesstech")
      ? Provider.split("-timelesstech")[0]
      : Provider,
    device: device ? "mobile" : "device",
    returnURL: ServiceURLs.WEB_URL + "casino/" + (Provider ? Provider : ""),
  };
  debugger;
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + GameEndPoints.GET_TIMELESSTECH_GAME,
    HTTPOperationType.post,
    js
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
