import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView from "../ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ServiceURLs } from "_services";
import i18n from "i18n";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "_services";
import Emitter from "_actions/emitter";
import { useNavigate } from "react-router-dom";

const Live = (props) => {
  const { user, loggedIn, sports, games, UpdateGames, demo } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const [uKey, setUKey] = useState(uuidv4());
  const navigate = useNavigate();

  useEffect(() => {
    Emitter.on("ACTIVE_TAB", (newValue) => {
      const _uKey = uKey;
      if (
        uKey &&
        newValue &&
        newValue.uniqToken &&
        newValue.uniqToken != _uKey
      ) {
        navigate("/");
      }
      debugger;
    });
    return () => {
      Emitter.off("ACTIVE_TAB");
    };
  }, []);

  const setActiveGame = () => {
    const _k = uKey;
    debugger;
    UserService.UPDATE_ACTIVEGAME("detroit live" + "-" + params.GameId, _k)
      .then((res) => {
        setLoading(false);
        debugger;
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function GetParams() {
    let params = useParams();
    return params;
  }
  let params = GetParams();

  useEffect(() => {
    debugger;
    if (params && params.GameId) {
      setActiveGame();
    }
  }, []);

  useEffect(() => {
    debugger;
    if (params && params.GameId) {
      getGameDetail();
    }
  }, [loggedIn]);
  let startget = false;

  const getGameDetail = () => {
    if (!startget) {
      startget = true;
      setLoading(true);
      const isMobile = width <= 768;
      debugger;
      GameService.GET_DETROIT_DETAIL(
        params.GameId,
        props.currentLang,
        isMobile ? "M" : "D",
        "LIVE_CASINO"
      )
        .then((res) => {
          setLoading(false);
          debugger;
          setData(res.data);
        })
        .catch((err) => {
          debugger;

          setLoading(false);
        });
    }
  };
  /*
  const adjustHeight = () => {
    const windowHeight = window.innerHeight;
     try {
      document.querySelector(".gameiframe").style.height = `${windowHeight}px`;
    } catch (error) {
      
    }
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);
*/
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_CASINO")}</title>
            <style type="text/css">
              {`
      .base-nav {
        display: none !important;
        
      }
      .base-nav1 {
        display: none !important;
        
      }
      .appdiv{
        height: 100% !important;
      }
      body, html , #root{
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
  }
      
    `}
            </style>
          </Helmet>
          {data && (
            <div
              style={{
                height: "100%",
                margin: 0,
                overflow: "hidden",
              }}
              dangerouslySetInnerHTML={{ __html: data }}
            />
          )}
        </>
      )}
    </Translation>
  );
};
// height: "calc(100vh - 72px)",
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Live);
