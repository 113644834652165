import "./App.css";
import { connect } from "react-redux";

import { authenticationAction } from "_actions";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Dashboard from "layouts/Dashboard";
import Auth from "layouts/Auth";
import { useEffect } from "react";
import SplashLayout from "layouts/SplashLayout";
import toast, { Toaster } from "react-hot-toast";

import { AwsRum, AwsRumConfig } from "aws-rum-web";

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: "eu-central-1:8fa51312-41b0-4e71-8b3b-4cf08207d314",
    endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = "1ded93ab-990e-44a7-ac16-cf7784fce78e";
  const APPLICATION_VERSION = "1.0.0";
  const APPLICATION_REGION = "eu-central-1";

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

function App(props) {
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "en");
    }
  }, []);
  //
  return (
    <div className="h-screen max-h-screen overflow-hidden relative appdiv">
      {props.authLoading ? (
        <SplashLayout />
      ) : (
        <>{props.loggedIn ? <Dashboard /> : <Auth />}</>
      )}
      <Toaster />
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn, authLoading } = authentication;

  return {
    user,
    loggedIn,
    authLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
