import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

export default function MenuItem({ icon, title, path }) {
  let location = useLocation();

  return (
    <Translation>
      {(t) => (
        <Link
          to={path}
          className={`btn ${
            location.pathname == path ? "btn-primary" : "btn-neutral"
          } w-36 md:w-32 h-10 md:h-20 MenuItem text-white hover:border-1 hover:border-primary-500 hover:text-primary-600`}
        >
          <div className="flex flex-row  md:flex-col justify-around md:justify-center items-center">
            {icon && icon}
            <label className="mt-0 md:mt-2 text-[12px]">{title}</label>
          </div>
        </Link>
      )}
    </Translation>
  );
}
