import axios from "axios";
import { BaseServiceURLs } from "../BaseServiceURLs";
let ForceProductionMode = true;

export function GetUserLang() {
  return "en";
}

export function GetBaseURL() {
  if (ForceProductionMode) return ServiceURLs.BASE_URL;
  return ServiceURLs.BASE_URL;
}
export function GetReportBaseURL() {
  if (ForceProductionMode) return ServiceURLs.REPORTS_BASE_URL;
  return ServiceURLs.REPORTS_BASE_URL;
}

export function GetBaseSocketURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Socket_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Socket_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Socket_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseVideoURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Video_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Video_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Video_Production_URL;
  }
  return CurrentURL;
}

export function GetAuthURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  // if (ForceProductionMode) return ServiceURLs.Auth_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Auth_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Auth_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseCreatorURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Creator_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Creator_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Creator_Production_URL;
  }
  return CurrentURL;
}

export function Get_FRONTEND_CMS_URL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.CMS_Production_FrontEnd_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.CMS_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.CMS_Production_FrontEnd_URL;
  }
  return CurrentURL;
}

export function GetBaseCMSURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.CMS_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.CMS_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.CMS_Production_URL;
  }
  return CurrentURL;
}
/*
const _env = await axios.get(
  `${
    window.location.hostname == "localhost"
      ? "https://xapp101.thexapp.com"
      : window.location.origin
  }/env-config.json`
)?.data;
*/
export let ServiceURLs = {
  WEB_URL: BaseServiceURLs.WEB_URL, //"https://dev.maxbetone.com/",
  BASE_URL: BaseServiceURLs.BASE_URL, //"https://api-dev.maxbetone.com/",
  WS_BASE_URL: BaseServiceURLs.WS_BASE_URL, //"api-dev.maxbetone.com",
  SOCKET_URL: BaseServiceURLs.SOCKET_URL, //"wss://socket.maxbetone.com",
  REPORTS_BASE_URL: BaseServiceURLs.REPORTS_BASE_URL,
};

export const ClientData = {
  ClientId: "62a8eddd4683d15874d98901",
  ClientSecret: "c52d0a55-fce3-48e7-828e-87151e69e65b",
  Redirect_uri: "https://erp.thexapp.com",
};

export const OAuth2ServiceEndPoints = {
  TEST: "oauth/TEST",
  TOKEN: "oauth/token",
  AUTHORIZE: "oauth/authorize",
  REFRESH_TOKEN: "oauth/refresh-token",
};

export const UserEndPoints = {
  GET_USERDETAIL: "sj1n5/user/getuserdetail",
  READ_GAME_HISTORIES: "me6cb/accounthistory/read/",
  READ_CUSTOM_HISTORY: "me6cb/accounthistory/accounthistorycustom/",
  READ_CURRENCY: "me6cb/currency/read/",
  READ_DEFAULT_FILTER: "{}?CountPerPage=100&CurrentPage=1",
  READ_DEFAULT_FILTER_GAME: "{}?CountPerPage=1000&CurrentPage=1",

  READ_TICKETS: "tmo67/ticket/read/",
  UPDATE_PASSWORD: "useroperations/updatepassword/",
  RESET_PASSWORD: "useroperations/updatepasswordchild",

  GET_AVAIABLE_SETTING: "useroperations/getavailablesettings",

  
  GET_ACTIVEGAME: "useroperations/getactive-game",
  UPDATE_ACTIVEGAME: "useroperations/update-activegames",
};
export const GameEndPoints = {
  READ_DEFAULT_FILTER: "{}?CountPerPage=100&CurrentPage=1",
  READ_DEFAULT_FILTER_GAME: "{}?CountPerPage=1000&CurrentPage=1",

  READ_GAME: "f73tu/game/read/", // CASINO SLOTS ETC.
  SPORTS_TOKEN: "sports/gettoken/",
  ALTENAR_TOKEN: "integrations/altenar/gettoken/",

  GET_PRAGMATIC_GAME: "pragmatic/getgameurl/",
  GET_SMARTSOFT_GAME: "integrations/smartsoft/getgameurl/",
  GET_BLAS975_GAME: "integrations/blas975/getgameurl/",
  GET_BLAS975_DEMO_GAME: "integrations/blas975/getgameurl-test/",
  GET_LIVE_GAMES: "ndssk/livegames/read/",
  GET_LIVE_GAMES_PROVIDERS: "ndssk/providers/read/",
  GET_DETROIT_GAME: "integration/detroit/getgameurl",
  GET_WORLDMATCH: "integration/wordmatch/getgameurl",
  GET_WORLDMATCH_DEMO: "integration/wordmatch/getgameurl-test",
  GET_DETROIT_SLOT_GAME: "integration/detroit-slot/getgameurl",
  GET_TIMELESSTECH_GAME: "integration/timelesstech/getgameurl",
};

export const GamerEndPoints = {
  ACCOUNT_HISTORY: "me6cb/accounthistory/read/",
};

export const PartnerEndPoints = {
  CREATE_GAMER: "sj1n5/gamermodel/create/",
  READ_GAMERS: "sj1n5/gamermodel/read/",
  UPDATE_GAMER: "sj1n5/gamermodel/update/",
  ADD_BALANCE: "me6cb/addbalancemodal/create",
  CHECK_OUT: "me6cb/checkoutmodal/create",
};
export const AdminEndPoints = {
  CREATE_PARTNER: "sj1n5/partnermodel/create/",
  READ_PARTNERS: "sj1n5/partnermodel/read/",
  UPDATE_PARTNER: "sj1n5/partnermodel/update/",
};

export const SuperAdminEndPoints = {
  CREATE_ADMIN: "sj1n5/adminmodel/create/",
  READ_ADMINS: "sj1n5/adminmodel/read/",
  UPDATE_ADMIN: "sj1n5/adminmodel/update/",
};

export const ROOTEndPoints = {
  CREATE_SUPERADMIN: "sj1n5/superadminmodel/create/",
  READ_SUPERADMIN: "sj1n5/superadminmodel/read/",
  UPDATE_SUPERADMIN: "sj1n5/superadminmodel/update/",
};

export const FinancialEndPoints = {
  GET_FINANCIAL: "f3vro/financialreportsmodel/getfinancialreports",
  GET_FINANCIAL_CASH: "f3vro/financialreportsmodel/getfinancialreportscash",
};
