import React from "react";
import { Link } from "react-router-dom";
export default function LogoBox() {
  return (
    <div className="flex items-center gap-2">
      <Link
        data-sveltekit-preload-data=""
        to="/"
        aria-current="page"
        aria-label="daisyUI"
        className="flex-0 btn btn-ghost gap-1 px-2 md:gap-2"
        data-svelte-h="svelte-dlyygu"
      >
        <img
          style={{ objectFit: "contain", objectPosition: "center" }}
          className="h-10"
          src={require("../../assets/img/textlogo2.png")}
        />
      </Link>
    </div>
  );
}
