import { userConstants } from "../_constants";

export const authenticationAction = {
  UpdateUser,
  LoginSuccess,
  LoginFailure,
  UpdateLang,
  logout,
  UpdateWallet,
  UpdatePermissions
};

export function UpdateUser(_user) {
  return {
    type: userConstants.UPDATE_USER,
    user: _user,
  };
}

export function LoginSuccess(_user) {
  return {
    type: userConstants.LOGIN_SUCCESS,
    user: _user,
  };
}
export function LoginFailure() {
  return {
    type: userConstants.LOGIN_FAILURE,
  };
}

export function logout() {
  return {
    type: userConstants.LOGOUT,
  };
}

export function UpdateLang(data) {
  return {
    type: userConstants.UPDATE_LANG,
    data: data,
  };
}

export function UpdateWallet(data) {
  return {
    type: userConstants.UPDATE_WALLET,
    data: data,
  };
}
export function UpdatePermissions(data) {
  return {
    type: userConstants.UPDATE_PERMISSIONS,
    data: data,
  };
}

