import React from "react";
import { connect } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode } from "swiper/modules";
import i18n from "i18n";

const CategoryType = (props) => {
  const { acitveMenu, setActiveMenu } = props;
  const handleItemClick = (itemId) => () => {
    setActiveMenu(acitveMenu !== itemId ? itemId : "");
  };
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={10}
      freeMode={true}
      modules={[FreeMode]}
      className="mySwiper"
      breakpoints={{
        640: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
      }}
    >
      {[
        ...new Array(
          { id: "hot" },
          { id: "roulette" },
          { id: "blackjack" },
          { id: "poker" },
          { id: "baccarat" },
          { id: "new" }
        ),
      ].map(({ id }) => (
        <SwiperSlide style={{ minWidth: "140px" }}>
          <Card
            title={id}
            itemId={id} // NOTE: itemId is required for track items
            key={id}
            onClick={handleItemClick(id)}
            selected={id === acitveMenu}
          />
        </SwiperSlide>
      ))}
      <SwiperSlide style={{ minWidth: "140px" }}></SwiperSlide>
      <SwiperSlide style={{ minWidth: "140px" }}></SwiperSlide>
    </Swiper>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryType);

const Card = ({ itemId, selected, onClick, title }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        userSelect: "none",
      }}
      tabIndex={0}
      className={
        "relative btn border-0 h-[48px] w-full text-sm p-0  flex flex-col justify-center text-white  items-start mx-2 " +
        (selected ? "btn-info" : "btn-secondary bg-light-500")
      }
    >
      <div
        className={
          "cat_card  border-2  border-secondary-500  rounded-lg relative flex justify-center items-center bg-info-500 p-1 glass" +
          (selected ? "w-[48px] h-[48px] border-4" : "w-[46px] h-[46px]")
        }
      >
        {itemId == "hot" ? (
          <img
            width={32}
            src={require("../../../assets/img/icons/hot.svg").default}
          />
        ) : itemId == "roulette" ? (
          <img
            width={32}
            src={require("../../../assets/img/icons/roulette.svg").default}
          />
        ) : itemId == "blackjack" ? (
          <img
            width={32}
            src={require("../../../assets/img/icons/blackjack.svg").default}
          />
        ) : itemId == "poker" ? (
          <img
            width={32}
            src={require("../../../assets/img/icons/poker.svg").default}
          />
        ) : itemId == "baccarat" ? (
          <img
            width={32}
            src={require("../../../assets/img/icons/baccarat.svg").default}
          />
        ) : itemId == "new" ? (
          <img
            width={32}
            src={require("../../../assets/img/icons/new.svg").default}
          />
        ) : (
          ""
        )}
      </div>
      <div className="w-full text-start">
        <span>
          {itemId == "hot"
            ? i18n.t("LIVE_CAT_HOT")
            : itemId == "roulette"
            ? i18n.t("LIVE_CAT_ROULETTE")
            : itemId == "blackjack"
            ? i18n.t("LIVE_CAT_BLACK")
            : itemId == "poker"
            ? i18n.t("LIVE_CAT_POKER")
            : itemId == "baccarat"
            ? i18n.t("LIVE_CAT_BACCARAT")
            : itemId == "new"
            ? i18n.t("LIVE_CAT_NEW")
            : ""}
        </span>
      </div>
    </div>
  );
};
