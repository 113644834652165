import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView from "./ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { de } from "date-fns/locale";
import PragmaticGameBox from "../GamerBoxes/PragmaticGameBox";
import DetroitBox from "../GamerBoxes/DetroitBox";

import { detroidGames } from "BaseServiceURLs";
import TimelessTechGameBox from "../GamerBoxes/TimelessTechGameBox";
import WMGameBox from "../GamerBoxes/WMGameBox";
import { fundistGames } from "BaseServiceURLs";

const Casino = (props) => {
  const { user, loggedIn, sports, games, UpdateGames } = props;
  const [loading, setLoading] = useState(false);
  const [currentProvider, setCurrentProvider] = useState("pragmatic-casino");

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }
  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    if (games && params && params.ProviderId) {
      if (games.hasOwnProperty(params.ProviderId)) {
        setCurrentProvider(params.ProviderId);
      }
    } else {
      setCurrentProvider("pragmatic-casino");
    }
  }, [games, params.ProviderId]);

  const getGames = async () => {
    setLoading(true);
    await GameService.GET_GAMES()
      .then((res) => {
        setLoading(false);

        const newData = res.map((x) => {
          let y = x;
          if (x.middleware == "blas975") {
            y.CacheData = x.CacheData.reverse();
            return y;
          } else {
            return x;
          }
        });
        debugger;
        UpdateGames(newData);
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };
  const pb = require("../../../assets/img/banners/livecasino.png");
  const pragmaticNotCasino = ["1301", "1320"]; //"1302",
  const iconixGames = ["crashgame"];

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_GAMES")}</title>
          </Helmet>
          <div>
            <ScrollableListView
              pb={pb}
              title={t("TITLE_GAMES")}
              provider={currentProvider}
              setProvider={setCurrentProvider}
              data={[]}
            >
              <>
                {(games && games.hasOwnProperty("pragmatic-casino")
                  ? games["pragmatic-casino"].filter((x) => {
                      if (!props?.permissions?.PRAGMATIC_GAMES_ACCESS?.Value)
                        return false;
                      else return pragmaticNotCasino.find((y) => y == x.gameID);
                    })
                  : []
                ).map((item, index) => {
                  return (
                    <PragmaticGameBox
                      key={index + "pragmatic"}
                      isLive={false}
                      index={index}
                      item={item}
                    />
                  );
                })}
              </>
            </ScrollableListView>
          </div>
        </>
      )}
    </Translation>
  );
};
/*

                            .reverse()
                            .sort((a, b) =>
                              a.gameIdNumeric > b.gameIdNumeric ? -1 : 1
                            )
*/
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Casino);

/*
 {games &&
                  [
                    "Redgenn-fundist",
                    "Upgaming-fundist",
                    "TurboGames-fundist",
                    "Spribe-fundist",
                  ].filter(x=> {
                    if (
                      x.value == "Redgenn-fundist" &&
                      !props?.permissions?.WM_REDGENN_GAMES_ACCESS?.Value
                    ) {
                      return false;
                    }else if (
                      x.value == "Upgaming-fundist" &&
                      !props?.permissions?.WM_UPGAMING_GAMES_ACCESS?.Value
                    ) {
                      return false;
                    }else if (
                      x.value == "TurboGames-fundist" &&
                      !props?.permissions?.WM_TURBOGAMES_GAMES_ACCESS?.Value
                    ) {
                      return false;
                    }else if (
                      x.value == "Spribe-fundist" &&
                      !props?.permissions?.WM_SPRIBE_GAMES_ACCESS?.Value
                    ) {
                      return false;
                    }else return true;
                  }).map((prv) =>
                    games.hasOwnProperty(prv)
                      ? games[prv].map((item, k) => {
                          
                          if (item)
                            return (
                              <WMGameBox
                                key={k + "wm" + prv.provider}
                                index={k + "wm" + prv.provider}
                                item={item}
                                provider={prv}
                                mainCat={"games"}
                              />
                            );
                        })
                      : ""
                  )}

                {(games && games.hasOwnProperty("iconix")
                  ? games["iconix"].filter((x) => {
             
             //        if (!props?.permissions?.ICONIX_GAMES_ACCESS?.Value)
              //          return false;
             //         else
                      return iconixGames.find((y) => y == x.type);
                    })
                  : []
                ).map((item, index) => {
                  return (
                    <TimelessTechGameBox
                      key={index + "timelesstech"}
                      isLive={false}
                      index={index}
                      item={item}
                      provider={"iconix"}
                    />
                  );
                })}
                {games &&
                  detroidGames.map((prv) =>
                    games.hasOwnProperty(prv.provider)
                      ? prv.data.map((i, k) => {
                          const item = games[prv.provider].find(
                            (x) => x.gamecode == i
                          );
                          if (item)
                            return (
                              <DetroitBox
                                key={k + "detroit" + prv.provider}
                                index={k + "detroit" + prv.provider}
                                item={item}
                                provider={prv.provider}
                                mainCat={"games"}
                              />
                            );
                        })
                      : ""
                  )}
                  {games &&
                  fundistGames.map((prv) =>
                    games.hasOwnProperty(prv.provider)
                      ? prv.data.map((i, k) => {
                          const item = games[prv.provider].find(
                            (x) => x.ID == i
                          );
                          if (item)
                            return (
                              <WMGameBox
                                key={k + "wm" + prv.provider}
                                index={k + "wm" + prv.provider}
                                item={item}
                                provider={prv.provider}
                                mainCat={"games"}
                              />
                            );
                        })
                      : ""
                  )}










*/
