import { UserEndPoints, OAuth2_MakeRequestAndGetResponse } from "./index";
import { HTTPOperationType } from "./httpOperations";
import { GetBaseURL, GetReportBaseURL } from "./_serviceSettings";
import Emitter from "_actions/emitter";
import moment from "moment";
import toast from "react-hot-toast";
export const UserService = {
  USER_DETAIL,
  GET_CURRENCY,
  GET_MY_HISTORY,
  READ_TICKETS,
  GET_MY_ACCOUNT_HISTORY,
  UPDATE_PASSWORD,

  GET_AVAIABLE_SETTING,
  UPDATE_ACTIVEGAME,
};

async function USER_DETAIL() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetReportBaseURL()) + UserEndPoints.GET_USERDETAIL,
    HTTPOperationType.post,
    {}
  )
    .then((res) => {
      try {
        if (res.operationResult && res?.dynamicValue?.SetPasswordNeed) {
          //Kaldıralım dediler yapı hazır
          //Emitter.emit("UPDATE_PASS", true);
        }
      } catch (error) {}

      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      /* if (toast) {
        toast.error("You have not permission");
      }
      */
      return Promise.reject(err);
    });
}

async function UPDATE_PASSWORD(password) {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + UserEndPoints.UPDATE_PASSWORD,
    HTTPOperationType.post,
    {
      Password: password,
      RePassword: password,
    }
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      /* if (toast) {
        toast.error("You have not permission");
      }
      */
      return Promise.reject(err);
    });
}

async function READ_TICKETS(
  page = 1,
  search,
  type,
  begin = new Date(moment().startOf("day").add(1, "seconds")).toJSON(),
  end = new Date(moment().endOf("day").subtract(1, "seconds")).toJSON()
) {
  const _type = `"TicketStatus": { "$regex": "${type}","$options":"i" }`;
  const _search = `"TicketID": { "$regex": "${search}","$options":"i" }`;
  const _date = `"CreateTime": { "$gte": "${begin}", "$lt": "${end}" }`;

  let _filter = "";

  if (search) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _search;
  }
  if (type) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _type;
  }
  if (begin && end) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _date;
  }

  const path = `{${_filter}}?CountPerPage=100&CurrentPage=${page}&Sorts=_id=-1`;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + UserEndPoints.READ_TICKETS + path,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_CURRENCY() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) +
      UserEndPoints.READ_CURRENCY +
      UserEndPoints.READ_DEFAULT_FILTER,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_MY_HISTORY(
  page = 1,
  search,
  type,
  begin = new Date(moment().startOf("day").add(1, "seconds")).toJSON(),
  end = new Date(moment().endOf("day").subtract(1, "seconds")).toJSON()
) {
  const _type = `"RelFinancialType": "${type}" `;
  const _search = `"TicketID": { "$regex": "${search}","$options":"i" }`;
  const _date = `"OperationTime": { "$gte": "${begin}", "$lt": "${end}" }`;

  let _filter = "";

  if (search) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _search;
  }
  if (type) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _type;
  }
  if (begin && end) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _date;
  }
  const path = `{${_filter}}?CountPerPage=25&CurrentPage=${page}`;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetReportBaseURL()) + UserEndPoints.READ_GAME_HISTORIES + path,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_MY_ACCOUNT_HISTORY(
  page = 1,
  begin = new Date(moment().startOf("day").add(1, "seconds")).toJSON(),
  end = new Date(moment().endOf("day").subtract(1, "seconds")).toJSON()
) {
  const _type = `"RelFinancialType": {"$in": ["65ce8136bda5999c06dc4880", "65d279773029d7bfab4930a4"]} `;
  const _date = `"OperationTime": { "$gte": "${begin}", "$lt": "${end}" }`;

  let _filter = "";

  _filter = _filter + (_filter.length > 0 ? "," : "") + _type;

  if (begin && end) {
    _filter = _filter + (_filter.length > 0 ? "," : "") + _date;
  }
  const path = `{${_filter}}?CountPerPage=25&CurrentPage=${page}`;

  return OAuth2_MakeRequestAndGetResponse(
    (await GetReportBaseURL()) + UserEndPoints.READ_GAME_HISTORIES + path,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function GET_AVAIABLE_SETTING() {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetReportBaseURL()) + UserEndPoints.GET_AVAIABLE_SETTING,
    HTTPOperationType.get
  )
    .then((res) => {
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

async function UPDATE_ACTIVEGAME(game, token) {
  return OAuth2_MakeRequestAndGetResponse(
    (await GetBaseURL()) + UserEndPoints.UPDATE_ACTIVEGAME,
    HTTPOperationType.post,
    {
      Game: game,
      uniqToken: token,
    }
  )
    .then((res) => {
      debugger;
      return Promise.resolve(res.dynamicValue);
    })
    .catch((err) => {
      /* if (toast) {
        toast.error("You have not permission");
      }
      */
      return Promise.reject(err);
    });
}
