import { authenticationAction } from "_actions";
import React from "react";
import { connect } from "react-redux";
import MasterAdmin from "./MasterAdmin";
import SuperAdmin from "./SuperAdmin";
import Admin from "./Admin";
import Partner from "./Partner";
import Gamer from "./Gamer";

const Container = (props) => {
  if (props.user) {
    if (props.user.RelUserType) {
      switch (props.user.RelUserType.TypeID) {
        case 1:
          return  <p>No Permission</p>;
        case 2:
          return  <p>No Permission</p>;
        case 3:
          return <p>No Permission</p>;
        case 4:
          return  <p>No Permission</p>;
        case 5:
          return <Gamer/>;
        default:
          break;
      }
    } else {
      <p>No Permission</p>;
    }
  } else {
    <p>No Permission</p>;
  }
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
