import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListView from "./ScrollableListView";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { de } from "date-fns/locale";
import PragmaticGameBox from "../GamerBoxes/PragmaticGameBox";
import DetroitBox from "../GamerBoxes/DetroitBox";

import { detroidGames } from "BaseServiceURLs";
import TimelessTechGameBox from "../GamerBoxes/TimelessTechGameBox";
import WMGameBox from "../GamerBoxes/WMGameBox";

const Casino = (props) => {
  const { user, loggedIn, sports, games, UpdateGames } = props;
  const [loading, setLoading] = useState(false);
  const [currentProvider, setCurrentProvider] = useState("pragmatic-casino");

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }
  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    if (games && params && params.ProviderId) {
      if (games.hasOwnProperty(params.ProviderId)) {
        setCurrentProvider(params.ProviderId);
      }
    } else {
      setCurrentProvider("pragmatic-casino");
    }
  }, [games, params.ProviderId]);

  const getGames = async () => {
    setLoading(true);
    await GameService.GET_GAMES()
      .then((res) => {
        setLoading(false);

        const newData = res.map((x) => {
          let y = x;
          if (x.middleware == "blas975") {
            y.CacheData = x.CacheData.reverse();
            return y;
          } else {
            return x;
          }
        });
        debugger;
        UpdateGames(newData);
      })
      .catch((err) => {
        debugger;

        setLoading(false);
      });
  };
  const pb = require("../../../assets/img/banners/tvgames.png");
  const pragmaticNotCasino = ["1301", "1320"]; //"1302",
  const iconixGames = ["crashgame"];
  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_TVGAMES")}</title>
          </Helmet>
          <div>
            <ScrollableListView
              pb={pb}
              title={t("TITLE_TVGAMES")}
              provider={currentProvider}
              setProvider={setCurrentProvider}
              data={[]}
            >
              <>
         
                {games &&
                  [
                    "TVBet-fundist",
                  ].map((prv) =>
                    games.hasOwnProperty(prv)
                      ? games[prv].map((item, k) => {
                          
                          if (item)
                            return (
                              <WMGameBox
                                key={k + "wm" + prv.provider}
                                index={k + "wm" + prv.provider}
                                item={item}
                                provider={prv}
                                mainCat={"games"}
                              />
                            );
                        })
                      : ""
                  )}

              
                
              </>
            </ScrollableListView>
          </div>
        </>
      )}
    </Translation>
  );
};
/*

                            .reverse()
                            .sort((a, b) =>
                              a.gameIdNumeric > b.gameIdNumeric ? -1 : 1
                            )
*/
const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateGames: (data) => dispatch(gameAction.UpdateGamesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Casino);
