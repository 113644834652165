import io from "socket.io-client";
import { ServiceURLs } from "./_serviceSettings";
import Emitter from "_actions/emitter";
import { checkCustomTokenExpires } from "components/FocusBlurHandler";
import toast from "react-hot-toast";
import i18n from "i18n";

const SocketKeyCode = "maxbetobserve";
const SocketSecret = "2c1455a1-378b-4f79-9baa-8ea2e617c043";
const ClientType = 0;
const keepAliveInterval = 30000; // 30 saniye

class WSService {
  initializeSocket = async () => {
    try {
      const _URL =
        ServiceURLs.SOCKET_URL + `/dynamic-${SocketKeyCode}${SocketSecret}]`;
      this.socket = io(_URL, {
        query: { token: "WEVSUF9BTElDQU5fS1VLTEFDSV9BQkRVTExBSF9LVVJOQVo" },
        transports: ["websocket"],
        autoConnect: true,
      });

      this.socket.on("connect", (data) => {
        setInterval(() => {
          checkCustomTokenExpires();
          this.socket.emit("keep-alive");
        }, keepAliveInterval);

        this.PubListen();
        this.socket.on("EmitMessage", (EmitKey, EmitValue) => {
          this.ReceiveMessageFromProcessor(
            EmitKey,
            undefined,
            EmitValue,
            this.socket
          );
        });
        if (localStorage.user) {
          setTimeout(() => {
            const _user = JSON.parse(localStorage.user);
            if (_user) {
              this.Sub(
                `${ServiceURLs.WS_BASE_URL}_${_user.RelUser._id}`,
                this.AllListener
              );
            }
          }, 2000);
        }

        this.socket.on("subEmit", (data2, data3) => {
          //  Alert.alert(data3.EmitKey);
          if (data3.EmitKey == "EncryptedMessage") {
            let Parsed = JSON.parse(data3.EmitValue);
            this.ReceiveMessage(
              Parsed.Sender,
              Parsed.EncryptChatMessage,
              1,
              false,
              Parsed
            );
            //this.SetReadMessage(Parsed.ID);
          } else if (data3.EmitKey == "new_balance") {
            Emitter.emit("new_balance", JSON.parse(data3.EmitValue));
          } else if (data3.EmitKey == "LOGIN_SOMEBODY_OBSERVE") {
            // console.info("birisi daha girdi");
            Emitter.emit("CHECK_TOKEN", true);
          } else if (data3.EmitKey == "ACTIVE_TAB_CHANGED_OBSERVE") {
            debugger;
            Emitter.emit("ACTIVE_TAB", JSON.parse(data3.EmitValue));
          } else if (data3.EmitKey == "KICK_SOMEBODY_OBSERVE") {
            Emitter.emit("CHECK_TOKEN");
          } else {
            debugger;
          }
        });

        this.socket.on("WhoAreU", (d, callback) => {
          callback(
            "SecretChat",
            SocketKeyCode,
            SocketSecret,
            ClientType,
            "",
            ""
          );
        });

        this.socket.onAny((data, data2, data3) => {
          console.log("socket", { data, data2, data3 });
        });

        this.socket.on("withAck", (op, cb) => {
          if (op === 10002) {
            this.sendInfoViaCallback(cb);
            console.log("here");
          }
        });

        this.socket.io.on("reconnect_attempt", () => {
          console.log("reconnect_attempt reconnect_attempt");
        });

        this.socket.io.on("reconnect", () => {
          // ...
        });
      });

      this.socket.on("disconnect", (data) => {
        this.socket.offAny();
        this.socket.removeAllListeners();
      });

      this.socket.on("error", (data) => {
        console.log("socekt error", data);
      });
    } catch (error) {
      console.log("scoket is not inialized", error);
    }
  };

  emit(event, data = {}) {
    this.socket.emit(event, data);
  }

  on(event, cb) {
    this.socket.on(event, cb);
  }

  removeListener(listenerName) {
    this.socket.removeListener(listenerName);
  }

  sendMessage(message) {
    if (this.socket) {
      this.socket.emit("message", message);
      //EncryptedMessage
    }
  }

  receiveMessage(callback) {
    if (this.socket) {
      this.socket.on("message", (incomingMessage) => {
        callback(incomingMessage);
      });
    } else {
      debugger;
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  NeedNewID = async () => {
    this.GetNewIDFromSystem();
  };

  Pub(EmitKey, EmitValue) {
    this.socket.emit("Pub", EmitKey, EmitValue);
  }
  PubToRoom(EmitKey, EmitValue, RoomName) {
    this.socket.emit("PubToRoom", EmitKey, EmitValue, RoomName);
  }

  PubListen = () => {
    this.socket.on("Pub", (EmitKey, EmitValue, Socket) => {});
  };
  Sub(SubName, cb) {
    this.socket.emit("Sub", SubName);
    this.socket.on("subEmit", (RoomName, Data) => {
      if (RoomName == SubName) {
        cb(Data.EmitKey, Data.EmitValue, undefined);
      }
    });
  }

  async OnEncryptedMessageReadResult(Emitkey, EmitValue) {
    console.log(`Receive Encrypted Message: ${Emitkey} ${EmitValue}`);
  }

  AllListener = async (Emitkey, EmitValue) => {
    //console.log(`SubTest Called ${Emitkey} ${EmitValue}`);
    Emitter.emit(Emitkey, JSON.parse(EmitValue));
  };
}

const socketServcies = new WSService();

export default socketServcies;
