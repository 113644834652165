import { Field } from "formik";
import React from "react";

export default function ForikField({ formikField, title, required }) {
  return (
    <label className="form-control w-full ">
      <div className="label">
        <span className="label-text">
          {title}
          {required ? <span className="text-error text-lg ml-1">*</span> :  <span className="text-error text-lg ml-1 opacity-0">*</span>}
        </span>
      </div>
      {formikField}
    </label>
  );
}
