import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { connect } from "react-redux";
import "./index.css";

const GridContainer = ({ items }) => {
    const [index, setIndex] = useState(0);
    const itemsPerRow = 4; // Yatayda gösterilecek eleman sayısı
  
    const handleChangeIndex = (index) => {
      setIndex(index);
    };
  
    const renderGridItems = () => {
      return items.map((item, i) => (
        <div key={i} className="grid-item">
          {item}
        </div>
      ));
    };
  
    const renderGridRows = () => {
      const numRows = Math.ceil(items.length / itemsPerRow);
      const rows = [];
  
      for (let i = 0; i < numRows; i++) {
        const startIndex = i * itemsPerRow;
        const endIndex = Math.min(startIndex + itemsPerRow, items.length); // Her satırda maksimum 4 eleman göstermek için
        rows.push(
          <div key={i} className="grid-row">
            {renderGridItems().slice(startIndex, endIndex)}
          </div>
        );
      }
  
      return rows;
    };
  
    return (
      <div className="grid-container">
        <SwipeableViews index={index} onChangeIndex={handleChangeIndex} enableMouseEvents axis="y">
          {renderGridRows()}
        </SwipeableViews>
      </div>
    );
  };

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GridContainer);
