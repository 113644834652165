import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Notification, useToaster } from "rsuite";
import { Tag, IconButton } from "rsuite";
import { Translation } from "react-i18next";
import { PartnerService } from "_services";
import i18n from "i18n";
import Table from "rc-table";
import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi2";
import { IoGameController,IoGameControllerOutline } from "react-icons/io5";

const columns = [
  {
    title: "Ventor",
    dataIndex: "Ventor", //["RelUser", "UserID"],
    className: "text-xs ",

    key: "Ventor",
    width: 100,
  },
  {
    title: "SumBet",
    dataIndex: "SumBet",
    className: "text-xs flex",
    key: "SumBet",
    width: 50,
    render: (value) => (
      <div className={`p-2 rounded-md `}>
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
  {
    title: "SumWin",
    dataIndex: "SumWin",
    className: "text-xs ",
    key: "SumWin",
    width: 50,
    render: (value) => (
      <div className={`p-2 rounded-md`}>
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
  {
    title: "GGR",
    dataIndex: "GGR",
    className: "text-xs ",
    key: "GGR",
    width: 50,
    render: (value) => (
      <div
        className={`p-2 rounded-md  text-[10px] sm:text-[12px] md:text-xs  ${
          value > 0
            ? "bg-green-700"
            : value == "0"
            ? "bg-transparent"
            : "bg-red-700"
        }`}
      >
        <span>
          {value.toLocaleString("tr-TR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    ),
  },
 
];
/*
{
    title: "Operations",
    dataIndex: "",
    className: "text-right",
    key: "d",
    render() {
      return <a href="#">Operations</a>;
    },
  },
*/
const ChildFinansReportDetailTable = ({ rowData, childs }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const toaster = useToaster();
  const message = (msg, type) => {
    return <Notification type={type} header={msg} closable></Notification>;
  };

  const onExpand = (expanded, record) => {
    // eslint-disable-next-line no-console
    //console.log("onExpand", expanded, record);
  };
  function CustomExpandIcon(props) {
    return (
      <div
      className={ (props.record.User.UserType.TypeID == 1
        ? "bg-master-500  text-master-50"
        : props.record.User.UserType.TypeID == 2
        ? "bg-superadmin-500 text-superadmin-50"
        : props.record.User.UserType.TypeID == 3
        ? "bg-admin-500 text-admin-900"
        : props.record.User.UserType.TypeID == 4
        ? "bg-partner-500 text-partner-900"
        : "bg-gamer-500  text-gamer-800")+ ` btn   ${
        props.expanded ? "" : "btn-outline"
      } btn-sm btn-square` }
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        // eslint-disable-next-line react/no-danger
      >
        {props.expanded ? <IoGameControllerOutline /> : <IoGameController />}
      </div>
    );
  }

  return childs ? (
    <div className="py-1 bg-secondary-700 rounded-sm border border-secondary-400/20">
      {/*
        <div className="pb-2 px-2 border-b border-secondary-400/30">
        <label className="bold">{`${rowData.User.UserName} - (${rowData.RelUserType.Value})`}</label>
      </div>
      */}

      <Table
        className="table table-zebra "
        columns={columns}
        rowClassName={(record, i) => ` row-${i}`}
        data={
          childs
            ? [
                ...childs.map((item, key) => ({
                  ...item,
                  key: item.Ventor + key,
                })),
              ]
            : []
        }
      />
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildFinansReportDetailTable);
