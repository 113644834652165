import { gameConstants } from "../_constants";

export const gameAction = {
  UpdateSportsData,
  UpdateGamesData,
  UpdateLoadingStatus,
  UpdateLiveGame,
  UpdateLiveGameProviders,
  UpdateActiveGame,
};

export function UpdateActiveGame(data) {
  return {
    type: gameConstants.UPDATE_ACTIVE_GAME,
    data: data,
  };
}
export function UpdateSportsData(data) {
  return {
    type: gameConstants.UPDATE_SPORTS_DATA,
    data: data,
  };
}

export function UpdateGamesData(data) {
  return {
    type: gameConstants.UPDATE_GAMES_DATA,
    data: data,
  };
}

export function UpdateLoadingStatus(data) {
  return {
    type: gameConstants.UPDATE_LOADING,
    data: data,
  };
}
export function UpdateLiveGame(data) {
  return {
    type: gameConstants.UPDATE_LIVE_GAME,
    data: data,
  };
}
export function UpdateLiveGameProviders(data) {
  return {
    type: gameConstants.UPDATE_LIVE_GAME_PROVIDERS,
    data: data,
  };
}
