import { authenticationAction } from "_actions";
import { UserService } from "_services";
import React, { useEffect } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";

const SplashLayout = (props) => {
  const checkToken = async () => {
    const { LoginSuccess, LoginFailure } = props;
    await UserService.USER_DETAIL()
      .then((res) => {
        LoginSuccess(res);
      })
      .catch((err) => {
        LoginFailure();
      });
  };

  useEffect(() => {
    setTimeout(() => {
      checkToken();
    }, 1000);
  }, []);

  return (
    <Translation>
      {(t) => (
        <div className="splash-loader flex justify-center items-center">
          <span></span>
          <p className="text-white text-lg">{t("LOADING")}</p>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const { user, loggedIn } = authentication;

  return {
    user,
    loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateUser: (user) => dispatch(authenticationAction.UpdateUser(user)),
    LoginSuccess: (user) => dispatch(authenticationAction.LoginSuccess(user)),
    LoginFailure: () => dispatch(authenticationAction.LoginFailure()),
    logout: () => dispatch(authenticationAction.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashLayout);
