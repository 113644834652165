import React from "react";
import { connect } from "react-redux";
import Table from "rc-table";
import { HiUserGroup, HiOutlineUserGroup } from "react-icons/hi2";
import ChildUserTable from "./ChildUserTable";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import AddBalance from "components/Partner/AddBalance";
import CheckOut from "components/Partner/CheckOut";
import UserDetail from "components/Modals/UserDetail";

const columns = [
  {
    title: "User",
    dataIndex: "Item", //["RelUser", "UserID"],
    className: "text-xs",
    key: "UserID",
    width: 160,
    render: (value) => <UserDetail data={value} />,
  },
  {
    title: "Count",
    dataIndex: "Count",
    className: "text-xs hidden sm:table-cell",
    key: "Count",
    width: 50,
  },
  {
    title: "",
    dataIndex: "Item",
    className: "text-right",
    key: "d",
    render(value) {
      return value.RelUserType.TypeID == 5 ? (
        <div className="w-[74px] flex flex-row">
          <AddBalance data={value} />
          <div className="m-1"></div>
          <CheckOut data={value} />
        </div>
      ) : (
        <></>
      );
    },
  },
];

export const UserTable = ({ data }) => {
  const onExpand = (expanded, record) => {
    // eslint-disable-next-line no-console
    //console.log("onExpand", expanded, record);
  };

  function CustomExpandIcon(props) {
    return props.record.ChildDetails.length > 0 ? (
      <div
        className={ (props.record.User.UserType.TypeID == 1
          ? "bg-master-500  text-master-50"
          : props.record.User.UserType.TypeID == 2
          ? "bg-superadmin-500 text-superadmin-50"
          : props.record.User.UserType.TypeID == 3
          ? "bg-admin-500 text-admin-900"
          : props.record.User.UserType.TypeID == 4
          ? "bg-partner-500 text-partner-900"
          : "bg-gamer-500  text-gamer-800")+ ` btn   ${
          props.expanded ? "" : "btn-outline"
        } btn-sm btn-square` }
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        // eslint-disable-next-line react/no-danger
      >
        {props.expanded ? <HiOutlineUserGroup /> : <HiUserGroup />}
      </div>
    ) : (
      <></>
    );
  }
  function countAllChildren(node) {
    let totalCount = 0;

    if (node.ChildDetails && node.ChildDetails.length > 0) {
      node.ChildDetails.forEach((childNode) => {
        totalCount += 1 + countAllChildren(childNode); // Toplam, kendisi dahil alt düğümlerinin sayısını hesaplar
      });
    }

    return totalCount;
  }
  return (
    <Table
      columns={columns}
      rowClassName={(record, i) =>
        `${
          record.ChildDetails.length > 0 ? "" : "RemoveSubRow"
        } border-b border-secondary-400/20 row-${i}`
      }
      expandable={{
        //expandRowByClick: true,
        expandedRowRender: (record) => {
          if (record.ChildDetails.length > 0) {
            return (
              <div className="p-2 bg-secondary-800 rounded-sm m-0">
                <ChildUserTable childs={record.ChildDetails} rowData={record} />
              </div>
            );
          }
        },
        onExpand,
        expandIcon: CustomExpandIcon,
      }}
      data={
        data
          ? [
              ...data.map((item) => ({
                ...item,
                key: item.User._id,
                Name: item.User, //`${item.RelUser.UserName} (#${item.RelUser.UserID})`,
                Count: countAllChildren(item),
                Item: item,
              })),
            ]
          : []
      }
      className="table table-sm w-full bg-secondary-500"
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
