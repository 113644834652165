import React from "react";
import { connect } from "react-redux";
 import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { IoPlayCircleOutline } from "react-icons/io5";

const LiveGameBox = (props) => {
  const { item, index, provider } = props;
  return (
    <div
      key={item.id_game + item.name + item.vendor}
      className="GameBox h-[114px] md:h-[220px]  bg-primary-500 md:bg-primary-500 group/item cursor-pointer"
    >
      <div className="games-img">
        <AsyncImage
          className="h-full w-full rounded-t-[2.5vw] md:rounded-t-[2.5vw] bg-light-500"
          src={item.thumbnail2 ? item.thumbnail2:item.thumbnail}
          loader={
            <div className="skeleton w-full flex justify-center bg-secondary-500 items-center">
              <img
                className="h-[90px] md:h-full"
                src={require("../../../assets/img/logotexttans.png")}
              ></img>
            </div>
          }
          error={
            <div className="bg-light-500 relative overflow-hidden h-full w-full flex justify-center items-center">
              <img
                className="h-[90px] md:h-full"
                src={require("../../../assets/img/logotexttans.png")}
              ></img>
            </div>
          }
          style={{
            objectFit: "fill",
          }}
          alt={`
        ${item.name}
        
        [${item.ID}]`}
        />
      </div>

      <div className="game-hover group-hover/item:before:opacity-80 before:cursor-pointer cursor-pointer"></div>
      <Link  to={`/live/${item.ID}`} className="game-btns   flex-col px-6 cursor-pointer ">
      <IoPlayCircleOutline className="text-primary-500 w-14 h-14" />
      </Link>
      <span className="name text-white after:bg-primary-500 group-hover/item:after:bg-primary-500 group-hover/item:after:opacity-[0.9] group-hover/item:text-white">
        {item.name}
      </span>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LiveGameBox);
