import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/langs/en.json"; //tr dil dosyasi
import translationDE from "./assets/langs/de.json"; //de dil dosyasi

import translationIT from "./assets/langs/it.json"; //de dil dosyasi
import translationFR from "./assets/langs/fr.json"; //de dil dosyasi
import translationAR from "./assets/langs/ar.json"; //de dil dosyasi
import translationKH from "./assets/langs/kh.json"; //de dil dosyasi
import translationNL from "./assets/langs/nl.json"; //de dil dosyasi

import translationTR from "./assets/langs/tr.json"; //de dil dosyasi

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
  kh: {
    translation: translationKH,
  },
  nl: {
    translation: translationNL,
  },
  tr: {
    translation: translationTR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
